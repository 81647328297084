import { useQuery } from "@tanstack/vue-query"

import { QueryKeys } from "../query-keys"
import { ApiResponse, isSuccessResponse } from "../services/base-client"
import ServiceProvider from "../services/service-provider"
import { Integration } from "../types/integration-service"

export const fetchIntegrations = async () => {
  const response = await ServiceProvider.integrations.getIntegrations()

  if (isSuccessResponse(response as ApiResponse<Integration[]>)) {
    // store the integrations in a map for easier access
    const integrationsList = response.data?.integrations as Integration[]
    return integrationsList.reduce((acc, integration) => {
      acc[integration.id] = integration
      return acc
    }, {} as Record<string, Integration>)
  }

  return null
}

export const useIntegrationsQuery = () =>
  useQuery({
    queryKey: QueryKeys.integrations.all(),
    queryFn: fetchIntegrations,
    // refetchOnMount: false,
  })
