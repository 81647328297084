<template>
  <form-dialog
    :title="$t('export_setup_form.title')"
    :visible="visible"
    :submitButtonTitle="$t('export_setup_form.submit')"
    :saving="progress"
    @update:visible="$emit('update:visible', $event)"
    @submit="submit"
  >
    <form-section>
      <div>
        <form-row v-if="exportImagesToggle" class="export-setup-dialog__row">
          <span>{{ $t("export_setup_form.images_toggle") }}</span>
          <nice-switch v-model="images" />
        </form-row>

        <form-row class="export-setup-dialog__row">
          <span>{{ $t("export_setup_form.propstack_link_toggle") }}</span>
          <nice-switch v-model="propstackLink" />
        </form-row>

        <form-row v-if="addSubContactsToggle" class="export-setup-dialog__row">
          <span>{{ $t("export_setup_form.sub_contacts_toggle") }}</span>
          <nice-switch v-model="subContacts" />
        </form-row>
      </div>
    </form-section>
  </form-dialog>
</template>

<script>
import * as _ from "lodash"

export default {
  name: "export-setup-dialog",
  props: {
    visible: {
      type: Boolean,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    exportModel: {
      type: String,
    },
    exportImagesToggle: {
      type: Boolean,
      default: false,
    },
    addSubContactsToggle: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
    },
    customQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: false,
      images: false,
      propstackLink: false,
      subContacts: false,
    }
  },
  methods: {
    async submit() {
      this.progress = true
      if (this.customQuery) {
        this.$emit("handleCustomQuery", this.propstackLink)
      } else {
        await this.requestExport()
        this.$emit("update:visible", false)
      }
    },
    async requestExport() {
      await this.$axios.post(`/${this.exportModel}/export.xlsx`, this.queryBody).then(() => {
        App.flashy(this.successMessage)
      })
    },
  },
  watch: {
    visible(value) {
      if (!value)
        setTimeout(() => {
          this.progress = false
        }, 500)
    },
  },
  computed: {
    brokerId() {
      return this.$db.broker.id
    },
    queryBody() {
      const body = _.clone(this.params)
      body["resource_url"] = this.propstackLink
      if (this.addSubContactsToggle) body["sub_contacts"] = this.subContacts
      if (this.exportImagesToggle) body["images"] = this.images
      return body
    },
  },
}
</script>

<style>
.export-setup-dialog__row > .formRow-body {
  justify-content: space-between;
}
</style>
