<template>
  <div class="customField-section">
    <p class="customField-headline">
      <strong>{{ group.name }}</strong>
      <small class="ml-2">
        <a href="#" class="light" @click.prevent="$emit('open', group)">
          <fa-icon name="pencil" />
        </a>
      </small>
    </p>
    <draggable class="listItems" :list="group.customFields" item-key="id" handle=".handle" @change="onSortUpdate">
      <template #item="{ element: field }">
        <settings-list-item sortable @edit="openFieldDialog(field)" @delete="deleteField(field)" class="mb-2">
          <template v-slot:icon>
            <fa-icon :name="fieldTypeIconMapping[field.fieldType]" class="text-yellow-500" />
          </template>
          <template v-slot:title>
            <item :field="field" :group="group" />
          </template>
          <template v-slot:actions>
            <tooltip :content="$t('customFields.group.copyField')">
              <hover-action @click="copy(field)" icon="clipboard" />
            </tooltip>
          </template>
        </settings-list-item>
      </template>
    </draggable>
    <ps-button
      class="mt-3 max-w-none"
      active
      @click="openFieldDialog()"
      icon="plus"
      :title="$t('customFields.group.addField')"
    />
    <field-form
      :visible="fieldDialogVisible"
      @update:visible="fieldDialogVisible = $event"
      :group="group"
      :activeGroups="activeGroups"
      :field="editableField"
      @success="fieldSaved"
    />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import FieldForm from "./FieldForm.vue"
import Item from "./Item.vue"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"

const fieldTypeIconMapping = {
  String: "text",
  Text: "text-height",
  Number: "list-ol",
  Dropdown: "list-ul",
  Multiselect: "tasks",
  Boolean: "toggle-on",
  CountrySelect: "globe",
  Date: "calendar-alt",
  DateTime: "clock",
  Broker: "user",
  Tags: "tags",
  TagsByCategory: "tags",
}
export default defineComponent({
  components: { FieldForm, Item, SettingsListItem },
  props: {
    group: Object as PropType<any>,
    activeGroups: Array as PropType<any>,
  },
  data() {
    return {
      fieldDialogVisible: false,
      editableField: null,
      deleting: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) return
      if (this.$route.query.cf_id) {
        const field = this.group.customFields.find(n => n.id == this.$route.query.cf_id)
        if (!!field) this.openFieldDialog(field)
      } else {
        this.fieldDialogVisible = false
      }
    },
  },
  methods: {
    openFieldDialog(field) {
      this.editableField = field
      this.fieldDialogVisible = true
    },
    onSortUpdate(event) {
      this.$api.mutation("sortCustomFields", {
        order: this.group.customFields.map(g => g.id),
      })
    },
    fieldSaved() {
      this.$emit("dirty")
      this.fieldDialogVisible = false
      ;(this.$parent as any).fetchData()
    },
    deleteField(field) {
      this.$warn(
        {
          title: this.$t("customFields.group.deleteFieldTitle"),
          desc: this.$t("customFields.group.deleteFieldBody"),
          confirmButtonText: this.$t("customFields.group.confirmDelete"),
          redButton: true,
        },
        async () => {
          this.deleting = true

          this.$api
            .destroy("CustomField", field.id)
            .then(_ => {
              this.group.customFields = this.group.customFields.filter(o => o.id !== field.id)
              App.flashy(this.$t("customFields.group.fieldDeleted"))
              this.$emit("dirty")
            })
            .catch(this.$axios.handleError)
            .finally(() => (this.deleting = false))
        }
      )
    },
    copy(field) {
      this.$util.copy(field.name)
      App.flashy(this.$t("customFields.group.fieldCopied"))
    },
  },
  computed: {
    fieldTypeIconMapping() {
      return fieldTypeIconMapping
    },
  },
  mounted() {
    if (this.$route.query.cf_id && this.group.customFields.some(n => n.id == this.$route.query.cf_id)) {
      const field = this.group.customFields.find(n => n.id == this.$route.query.cf_id)
      this.openFieldDialog(field)
    }
  },
})
</script>

<style scoped>
.customField-section {
  padding: 0 30px 30px;
  max-width: 720px;
}
.customField-headline {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
</style>
