<template>
  <nice-select-virtualized
    filterable
    v-cancel-read-only
    clearable
    multiple
    :options="filteredOptions"
    v-model="record[field.name]"
    :placeholder="placeholder"
    size="small"
    :props="{
      value: 'id',
      label: 'name',
    }"
  />
</template>

<script>
import { debounce } from "@/utils"
import evaluator from "@/config/script-evaluator"

export default {
  props: ["record", "field", "placeholder"],
  data() {
    return { filter: null }
  },
  computed: {
    filteredOptions() {
      return !this.filter
        ? this.options
        : this.options
            .map((o, i) => (this.filter[i] === undefined || this.filter[i] === null || this.filter[i] ? o : undefined))
            .filter(Boolean)
    },
    options() {
      return this.field.customOptions || this.field.custom_options
    },
  },
  methods: {
    watchRecord() {
      this.$watch("record", debounce(this.evaluate), { deep: true })
    },
    evaluate() {
      const mappedContitions = this.options.map(o => o.condition?.replace(/\s?={2,3}\s?(.*)/, "?.includes($1)"))
      const conditions = [...new Set(mappedContitions)]
      evaluator
        .evalAsync(`[${conditions.join(",")}]`, this.record)
        .then(result => {
          if (Array.isArray(result)) {
            this.filter = mappedContitions.map(o => result[conditions.indexOf(o)])
          }
        })
        .catch(reason => console.log(reason, this.field.name))
    },
  },
  mounted() {
    if (this.options.some(f => !!f.condition)) {
      this.evaluate()
      setTimeout(_ => {
        // delay watching so it doesn't fire immediately
        this.watchRecord()
      }, 100)
    }
  },
}
</script>
