<template>
  <tooltip :content="$t('detailView.openClientInWhatsApp')" placement="right" v-if="integrationEnabled">
    <span :class="buttonMargin">
      <a @click.prevent="() => whatsAppWeb(phone, text || '')" class="light">
        <i class="fab fa-whatsapp" :style="'font-size: ' + (size || 16) + 'px;'" />
      </a>
    </span>
  </tooltip>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue"
import { detectAndOpenWhatsAppWeb } from "@/utils/check-whatsapp-installed"
import { useIntegration } from "@/integrations/use-integration"
import { useIntegrationVisibility } from "@/integrations/use-integration-visibility"

const props = defineProps({
  phone: String,
  text: String,
  size: Number,
  leftMargin: Boolean,
  rightMargin: Boolean,
  clientAddresses: {
    type: Array,
    default: () => [],
  },
})

const integrationEnabled = ref(false)

const buttonMargin = computed(() => {
  const margins = []
  if (props.leftMargin) margins.push("ml-1")
  if (props.rightMargin) margins.push("mr-1")
  return margins.join(" ")
})

const whatsAppWeb = (num, text) => {
  detectAndOpenWhatsAppWeb(num, text)
}

onMounted(() => {
  const integrationId = "integration-whatsapp-ps"
  const { integration } = useIntegration(integrationId)
  const { isEnabled } = useIntegrationVisibility(integration)
  watch(
    () => isEnabled.value,
    newStatus => {
      integrationEnabled.value = newStatus
    },
    { immediate: true }
  )
})
</script>
