<template>
  <div v-if="avatarUrl" :title="user.name" class="avatarItem" :style="avatarStyle"></div>
  <div
    v-else-if="user"
    :title="user.name"
    class="avatarItem has-placeholder"
    :style="colorStyle"
    :data-before="user.initials || $customFilters.initials(user.name)"
  ></div>
</template>

<script>
export default {
  props: {
    broker: {
      type: Object,
    },
    brokerId: {
      type: Number,
    },
    size: {
      type: String,
      default: "26px",
    },
    forcePhoto: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarUrl() {
      return (
        (this.$db.shopData.showBrokerAvatarsInCrm || this.forcePhoto) &&
        this.user &&
        (this.user.avatar || this.user.avatarUrl)
      ) // coming from graphql, it's avatarUrl, legacy is avatar
    },
    avatarStyle() {
      return {
        width: this.size,
        height: this.size,
        "background-image": `url('${this.avatarUrl}')`,
      }
    },
    colorStyle() {
      return {
        width: this.size,
        height: this.size,
        background: `${this.user.color}55`,
        "font-size": this.size.replace("px", "") / (26 / 10) + "px",
      }
    },
    user() {
      if (this.brokerId) return this.$db.shopData.brokers.find(b => b.id == this.brokerId)
      return this.broker
    },
  },
}
</script>

<style>
.avatarItem {
  border-radius: 50%;
  background-size: 100%;
  position: relative;
  background-color: #99999955;
  width: 26px;
  height: 26px;
  font-size: 10px;
  overflow: hidden;
}

.avatarItem.has-placeholder::before {
  content: attr(data-before);
  font-size: inherit;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: auto;
  height: 26px;
  line-height: 26px;
}
</style>