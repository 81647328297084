import { SmartFolder } from "@/interfaces"
import { FolderID, InboxNavigationItem } from "./inbox.types"

type MessageCounter = {
  favoritesCount: number
  spamCount: number
  draftsCount: number
  assignedCount: number
  unreadInboxCount: number
  smartFolderCounts: number
}

export const mapItemsToBrokerPath = (items: InboxNavigationItem[], inboxBrokerPath?: string) => {
  if (!inboxBrokerPath) return items

  return items.map(item => ({
    ...item,
    href: item.href?.split("?")[0] + inboxBrokerPath,
    child: item.child ? mapItemsToBrokerPath(item.child || [], inboxBrokerPath) : undefined,
  }))
}

interface CreateMailboxItemsParams {
  folderMap: Record<FolderID, InboxNavigationItem>
  inboxCounters: Record<string, MessageCounter>
  customFolderRoots: InboxNavigationItem[]
  smartFolders: SmartFolder[]
  numBrokers: number
  inboxBrokerPath: string
  t: (key: string) => string
}

export const getInboxNavigationItems = ({
  folderMap,
  inboxCounters,
  customFolderRoots,
  smartFolders,
  numBrokers,
  inboxBrokerPath,
  t,
}: CreateMailboxItemsParams) => {
  const addTitle = (i: InboxNavigationItem, attrs?: Partial<InboxNavigationItem>) => {
    return { ...i, title: i.label || t(`sidebar.${i.path}`), ...attrs }
  }
  const hasInboxChildren = !!folderMap["%inbox%"]?.child?.length

  const navItems = [
    hasInboxChildren ? addTitle(folderMap["%inbox%"]) : undefined,
    addTitle(folderMap["%sent%"]),
    {
      href: `/mailbox/messages/favorites` + inboxBrokerPath,
      title: t("sidebar.favorites"),
      icon: "fal fa-star",
      badge: {
        attributes: { "data-count": inboxCounters.favoritesCount },
        class: "details star",
      },
    },
    addTitle(folderMap["%spam%"], {
      badge: {
        attributes: { "data-count": inboxCounters.spamCount },
        class: "details text-light",
      },
    }),
    addTitle(folderMap["%archive%"]),
    addTitle(folderMap["%trash%"]),
    addTitle(folderMap["%draft%"], {
      badge: {
        attributes: { "data-count": inboxCounters.draftsCount },
        class: "details text-light",
      },
    }),
    ...customFolderRoots,
    ...(numBrokers > 1
      ? [
          {
            href: `/mailbox/messages/me`,
            title: t("sidebar.me"),
            icon: "fal fa-user",
            badge: {
              attributes: { "data-count": inboxCounters.assignedCount },
              class: "details text-light",
            },
          },
        ]
      : []),
    ...smartFolders.map(({ id, name, color }) => ({
      href: `/mailbox/smart_folders/${id}`,
      title: name,
      icon: { class: "fal fa-folder-download", attributes: { style: `color: ${color}` } },
      badge: {
        attributes: {
          "data-count": inboxCounters.smartFolderCounts && inboxCounters.smartFolderCounts[id.toString()],
        },
        class: "details",
      },
    })),
  ].filter(Boolean) as InboxNavigationItem[]

  return inboxBrokerPath ? mapItemsToBrokerPath(navItems, inboxBrokerPath) : navItems
}
