<template>
  <router-link :to="link" :title="count" class="percentage-number">
    <span class="count-percentage">{{ Math.round((count / total) * 100) }}%</span>
    <span class="count-absolute">{{ count }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.count-absolute {
  display: none;
}

.percentage-number {
  width: 100%;
}
.psTable-row:hover .count-absolute {
  display: initial;
}
.psTable-row:hover .count-percentage {
  display: none;
}
</style>
