<script setup lang="ts">
import { useMembership } from "@/integrations/use-membership"
import { toRef } from "@vueuse/core"

const props = defineProps<{
  membershipId: string
}>()

const membershipId = toRef(() => props.membershipId)
const { translatedMembershipName, badgeColor, isPending } = useMembership(membershipId)
</script>
<template>
  <nice-badge
    v-if="!isPending && translatedMembershipName"
    badge-class="text-black"
    :value="translatedMembershipName"
    :custom-color="badgeColor"
  />
</template>
