<template>
  <div
    v-if="imageUrl"
    class="bg-cover bg-no-repeat bg-center rounded"
    :style="{ width: size, height: size, 'background-image': `url('${imageUrl}')` }"
  ></div>
  <div
    v-else
    class="bg-cover bg-no-repeat bg-center rounded"
    :style="{ width: size, height: size, backgroundImage: `url(${placeholderImage})` }"
  ></div>
</template>

<script>
import PlaceholderImage from "@/assets/images/placeholders/image.png"

export default {
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "32px",
    },
  },
  computed: {
    placeholderImage() {
      return PlaceholderImage
    },
  },
}
</script>