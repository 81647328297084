<template>
  <span class="task-check" :class="{ 'task-check--checked': resource.done, 'task-check--pending': isPending }">
    <label
      :class="{
        'text-yellow-600': isPending,
        'bg-red-100': resource.priority > 0 && !resource.done,
        'bg-blue-100': resource.priority < 0 && !resource.done,
        '!border-primary': resource.priority < 0,
        '!border-danger': resource.priority > 0,
      }"
    >
      <input type="checkbox" :value="done" @input="checked" v-bind="checkboxAttr" />
    </label>
  </span>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isPending() {
      return this.resource.reviewState == "pending"
    },
    done() {
      return this.resource.done || this.resource.reviewState == "pending"
    },
    checkboxAttr() {
      return this.done ? { checked: "checked" } : {}
    },
  },
  methods: {
    checked($event) {
      if (this.resource.reviewerIds.length) {
        this.resource.reviewState = $event.target.checked ? "pending" : null
      } else {
        this.resource.done = $event.target.checked
      }

      const id = this.id || this.resource.id

      setTimeout(() => {
        this.$api
          .mutation(
            "toggleTaskCompletion",
            {
              taskId: id,
              done: this.resource.reviewerIds.length ? this.resource.reviewState == "pending" : this.resource.done,
            },
            "followupResourceType showTodoActivitiesScreen"
          )
          .then(({ followupResourceType, showTodoActivitiesScreen }) => {
            if (followupResourceType) {
              eventBus.$emit("quick-view", {
                type: followupResourceType,
                mode: "edit",
                params: followupResourceType == "message" ? { source: { taskId: id } } : { followupTaskId: id },
              })
            } else if (showTodoActivitiesScreen) {
              TaskApp.openTodoActivitesModal(id)
            }
          })
          .catch(e => {
            this.resource.done = false
            this.$axios.handleError(e)
          })
      }, 50)
    },
  },
}
</script>

<style scoped>
.task-check--checked label,
.task-check--pending label {
  border: none;
}
.task-check--checked label::before,
.task-check--pending label::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
}
</style>
