<template>
  <dropdown-section v-if="letterTemplates.length">
    <!-- <p class="text-sm text-gray-600 pl-2 -mt-4">
      <span class="bg-white px-2">Reports</span>
    </p> -->
    <dropdown-item
      v-for="item in letterTemplates"
      :key="item.id"
      :title="$t('reportActions.title', { name: item.name })"
      :disabled="disabled"
      @click="generate(item)"
    />
    <nice-dialog v-model="generating" append-to-body width="450px" custom-class="hide-header">
      <div class="flex justify-center text-center">
        <div>
          <spinner />
          <p>{{ $t("reportActions.processing") }}</p>
        </div>
      </div>
    </nice-dialog>
  </dropdown-section>
</template>

<script>
import { camelCase } from "@/utils/with-case"

export default {
  props: ["selected", "sortBy", "order", "type", "disabled"],
  data() {
    return {
      generating: false,
    }
  },
  computed: {
    fullType() {
      return `${this.type}_report`
    },
    letterTemplates() {
      return this.$db.shopData.letterTemplates.filter(lt => lt.category == this.fullType)
    },
    reportMutation() {
      return camelCase(`generate_${this.fullType}`)
    },
  },

  methods: {
    generate(item) {
      if (this.generating) return null

      this.generating = true
      this.$api
        .mutation(this.reportMutation, {
          letterTemplateId: item.id,
          [`${this.type}Ids`]: this.selected.map(Number),
          sortBy: this.sortBy,
          order: this.order,
        })
        .then(() => {
          App.flashy(this.$t("reportActions.success"))
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.generating = false
        })
    },
  },
}
</script>
