import i18n from "../i18n"

const { t } = i18n.global

export const detectAndOpenWhatsAppWeb = async (clientPhone, text?) => {
  const uri = `whatsapp://send/?phone=${encodeURIComponent(clientPhone)}&text=${encodeURIComponent(text)}`
  const webUri = `https://web.whatsapp.com/send?phone=${encodeURIComponent(clientPhone)}?text=${encodeURIComponent(
    text
  )}`

  const onEdge = () => {
    return new Promise(resolve => {
      window.navigator.msLaunchUri(
        uri,
        () => resolve(true),
        () => resolve(false)
      )
    })
  }

  const notOnEdge = () => {
    return new Promise(resolve => {
      const whatsappElement = document.getElementById("wapp-launcher") || document.createElement("a")
      whatsappElement.id = "wapp-launcher"
      whatsappElement.href = uri
      whatsappElement.style.display = "none"
      document.body.appendChild(whatsappElement)

      const start = Date.now()
      const timeoutToken = setTimeout(() => {
        if (Date.now() - start > 1250) {
          resolve(true)
        } else {
          resolve(false)
        }
      }, 1000)

      const handleBlur = () => {
        clearTimeout(timeoutToken)
        resolve(true)
      }
      window.addEventListener("blur", handleBlur)

      whatsappElement.click()
    })
  }

  const promise = window.navigator.msLaunchUri ? onEdge() : notOnEdge()
  if (!(await promise)) {
    const newWindow = window.open(webUri, "_blank")
    if (!newWindow || newWindow.closed || typeof newWindow.closed == "undefined") {
      alert(t("general.popupBlocked"))
    }
  }
}
