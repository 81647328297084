import moment from "moment"
import { RRule } from "rrule"

export const WEEKDAYS = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

export const weekOfMonth = date => {
  const week = Math.ceil(date.date() / 7)
  return week >= 5 ? -1 : week
}

export const getCRRule = (startsAt, crrule) => {
  try {
    let weekdays
    if (crrule.freq === 2) {
      weekdays = crrule.byweekday
    } else if (crrule.freq === 1) {
      const eventStartWeekDay = moment(startsAt).locale("en").format("dd").toUpperCase()
      weekdays =
        crrule.bymonth === "monthweek" ? [RRule[eventStartWeekDay].nth(weekOfMonth(moment(startsAt)))] : undefined
    }
    const until = crrule.endtype === "date" && crrule.until ? moment(crrule.until).toDate() : undefined
    const count = crrule.endtype === "days" ? crrule.count : undefined
    return new RRule({
      freq: crrule.freq,
      until,
      interval: Math.max(crrule.interval, 1),
      count,
      byweekday: weekdays || undefined,
    }).toString()
  } catch {
    return undefined
  }
}

export const parseCRRule = str => {
  if (!str) {
    return {
      count: 12,
      until: undefined,
      interval: 1,
      freq: 2,
      byweekday: [0],
      bymonth: "date",
      endtype: "never",
    }
  }
  try {
    const rule = RRule.fromString(str)
    const { options } = rule
    return {
      count: options.count || 12,
      until: options.until,
      interval: options.interval || 1,
      freq: options.freq ?? 2,
      byweekday: options.byweekday || [0],
      bymonth: options.freq === 1 && options.bynweekday ? "monthweek" : "date",
      endtype: !!options.until ? "date" : !!options.count ? "days" : "never",
    }
  } catch {
    return undefined
  }
}
