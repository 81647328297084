<template>
  <form-dialog
    :title="$t('admin.connectInboxModal.connectEmail', { brokerName: brokerName })"
    :visible="Boolean(resourceId)"
    @close="close"
    width="660px"
    :footerType="(['google', 'microsoft'] as Array<string | null>).includes(provider) ? 'custom' : 'bigButton'"
    :submitButtonTitle="$t('admin.connectInboxModal.submit')"
    :disabled="disabled"
    :submitFunc="submit"
    :autofocus="false"
  >
    <form-section>
      <form-row :title="$t('admin.connectInboxModal.provider')">
        <nice-select fixed-position v-model="provider" :options="providers" />
      </form-row>
      <template v-if="provider">
        <form-row :title="$t('admin.connectInboxModal.emailAddress')">
          <nice-input size="small" v-model="email" :placeholder="brokerEmail" @change="autodiscover" />
        </form-row>
        <form-row
          :title="$t('admin.connectInboxModal.isAlias.title')"
          :hint="$t('admin.connectInboxModal.isAlias.hint')"
          v-if="provider == 'microsoft'"
        >
          <nice-checkbox v-model="isAlias" @change="loginEmailForAlias = ''" />
        </form-row>
        <form-row
          :title="$t('admin.connectInboxModal.aliasAddress.title')"
          :hint="$t('admin.connectInboxModal.aliasAddress.hint')"
          v-if="isAlias"
        >
          <nice-input size="small" v-model="loginEmailForAlias" :placeholder="brokerEmail" />
        </form-row>
        <template v-if="provider == 'exchange'">
          <form-row :title="$t('admin.connectInboxModal.username')" :hint="$t('admin.connectInboxModal.usernameHint')">
            <nice-input size="small" v-model="username" />
          </form-row>
          <form-row :title="$t('admin.connectInboxModal.password')">
            <nice-input size="small" v-model="password" type="password" placeholder="********" />
          </form-row>
          <form-row :title="$t('admin.connectInboxModal.exchangeServer')">
            <nice-input size="small" v-model="exchangeServer" class="mail.mein-server.de" />
          </form-row>
        </template>
        <template v-if="provider == 'yahoo' && !isNylasV3">
          <form-row :title="$t('admin.connectInboxModal.password')">
            <nice-input size="small" v-model="password" type="password" placeholder="********" />
          </form-row>
        </template>
        <template v-if="provider == 'imap' && !isNylasV3">
          <form-row :title="$t('admin.connectInboxModal.username')" :hint="$t('admin.connectInboxModal.imapUserHint')">
            <nice-input size="small" v-model="username" />
          </form-row>
          <form-row :title="$t('admin.connectInboxModal.password')">
            <nice-input size="small" v-model="password" type="password" placeholder="********" />
          </form-row>
          <form-row title="IMAP Host + Port">
            <nice-input
              size="small"
              v-model="imapHost"
              placeholder="imap.mein-server.de"
              class="mr-2"
              @change="imapSmtpSettingsDirty = true"
            />
            <nice-input
              size="small"
              v-model="imapPort"
              type="number"
              placeholder="993"
              style="width: 100px"
              @change="imapSmtpSettingsDirty = true"
              :disabled="!isBackdoor"
            />
          </form-row>
          <form-row title="SMTP Host + Port">
            <nice-input
              size="small"
              v-model="smtpHost"
              placeholder="smtp.mein-server.de"
              class="mr-2"
              @change="imapSmtpSettingsDirty = true"
            />
            <nice-radio-group
              v-model="smtpPort"
              @change="imapSmtpSettingsDirty = true"
              class="flex-nowrap"
              :options="[
                { id: 465, name: '465' },
                { id: 587, name: '587' },
              ]"
            />
          </form-row>
          <form-row title="Force" v-if="isBackdoor">
            <nice-checkbox v-model="force" @change="imapSmtpSettingsDirty = true" class="mr-2" />
          </form-row>
        </template>
      </template>
    </form-section>
    <template v-slot:custom-footer="{ submit, loading, disabled }">
      <component :is="componentName" :disabled="disabled || loading" @click="submit" />
    </template>
  </form-dialog>
</template>

<script lang="ts">
import GoogleSignInButton from "@/components/buttons/GoogleSignInButton.vue"
import MicrosoftSignInButton from "@/components/buttons/MicrosoftSignInButton.vue"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"
import serviceProvider from "@/integrations/services/service-provider"
import config from "@/config/config"

const emailRegEx = new RegExp(
  /^((?!\.)[a-z0-9.!#$%&'*+-\/=?^_`{|}~]{1,})@((?!\.)[a-z0-9-\.]+)\.([a-z.]{1,20}(?!\.))\w$/i
)

export default {
  emits: ["save", "update:resourceId"],
  props: {
    resourceId: {
      type: Number,
      required: false,
    },
    returnUrl: {
      type: String,
    },
  },
  components: {
    GoogleSignInButton,
    MicrosoftSignInButton,
  },
  data() {
    return {
      provider: null as string | null,
      email: "",
      password: "",
      imapHost: "",
      imapPort: 993,
      smtpHost: "",
      smtpPort: 587,
      username: "",
      exchangeServer: "",
      isAlias: false,
      loginEmailForAlias: "",
      imapSmtpSettingsDirty: false,
      force: false,
    }
  },
  watch: {
    resourceId() {
      if (Boolean(this.resourceId)) {
        const queryId = this.resourceId!.toString()
        if (!this.$route.query.mail_id) this.$router.push({ query: { mail_id: queryId } })

        this.email = this.brokerEmail
        if (this.email) this.autodiscover()
      } else {
        if (this.$route.query.mail_id) this.$router.push({ query: { mail_id: undefined } })
      }
    },
  },
  methods: {
    autodiscover() {
      if (this.provider != "imap" || this.imapSmtpSettingsDirty) return

      this.$api
        .mutation(
          "autodiscoverMxSettings",
          {
            email: this.email,
          },
          "mxSettings { imap { host port } smtp { host port } }"
        )
        .then(({ mxSettings }) => {
          if (!mxSettings) return
          this.imapHost = mxSettings.imap.host
          this.imapPort = mxSettings.imap.port
          this.smtpHost = mxSettings.smtp.host
          this.smtpPort = mxSettings.smtp.port
        })
    },
    async submitNylasV3() {
      const result = await serviceProvider.nylasService.connectAccount(this.nylasServiceV3Params)
      if (result.isSuccessful2xx) {
        const { url } = result.data
        window.location.href = url
        return
      }
      this.$axios.handleError(result.statusText)
    },
    submit() {
      if (this.isNylasV3 && ["imap", "icloud", "yahoo"].includes(this.provider as string)) return this.submitNylasV3()
      return this.$api
        .mutation(
          "connectEmail",
          {
            id: this.resourceId,
            provider: this.provider,
            email: this.email,
            password: this.password,
            imapHost: this.imapHost,
            imapPort: this.imapPort,
            smtpHost: this.smtpHost,
            smtpPort: this.smtpPort,
            username: this.username,
            exchangeServer: this.exchangeServer,
            returnUrl: this.returnUrl,
            force: this.force,
            loginEmailForAlias: this.loginEmailForAlias,
          },
          "url connected"
        )
        .then(({ url, connected }) => {
          if (connected) {
            this.$emit("save", this.email)
            this.reset()
            App.flashy(this.$t("admin.connectInboxModal.connectionSuccessful"))
          } else if (url) {
            location.href = url
          }
        })
    },
    close() {
      this.$emit("update:resourceId", null)
      this.reset()
    },
    reset() {
      this.email = ""
      this.password = ""
      this.username = ""
    },
  },
  computed: {
    componentName(): string | undefined {
      switch (this.provider) {
        case "microsoft":
          return "MicrosoftSignInButton"
        case "google":
          return "GoogleSignInButton"
        default:
          return undefined
      }
    },
    isBackdoor(): boolean {
      return (this.$root as any).dbackdoor
    },
    disabled() {
      if (this.isNylasV3) return coerceBooleanAttribute(!this.provider || !emailRegEx.test(this.email))
      return coerceBooleanAttribute(
        !this.provider ||
          !emailRegEx.test(this.email) ||
          (this.provider == "exchange" && (!this.password || !this.exchangeServer)) ||
          (this.provider == "imap" &&
            (!this.password || !this.imapHost || !this.imapPort || !this.smtpHost || !this.smtpPort))
      )
    },
    providers(): any {
      const providers = {
        microsoft: "Microsoft",
        google: "Google",
        exchange: "Exchange",
        yahoo: "Yahoo",
        imap: "IMAP",
      }
      if (this.isNylasV3) providers["icloud"] = "iCloud"
      return providers
    },
    brokerName(): any {
      return this.$db.shopData.brokers.find(b => b.id == this.resourceId)?.name
    },
    brokerEmail(): any {
      return this.$db.shopData.brokers.find(b => b.id == this.resourceId)?.email
    },
    nylasServiceV3Params(): Record<string, string> {
      return {
        callbackUrl: this.nylasV3CallbackUrl,
        provider: this.provider === "exchange" ? "ews" : this.provider!,
        ownerId: this.resourceId!.toString(),
        emailAddress: this.email,
        emailSyncEnabled: "true",
        shouldRedirect: "false",
        // calendarSyncDirection: "BIDIRECTIONAL",
        // contactSyncDirection: "BIDIRECTIONAL",
        // tagNewContactsForSync: "true"
      }
    },
    nylasV3CallbackUrl(): string {
      const baseUrl = config.BASE_URL || `${window.location.protocol}//${window.location.host}/`
      return [baseUrl, `admin/nylas_v3/callback?brokerId=${this.resourceId}`].filter(Boolean).join("")
    },
    isNylasV3(): boolean {
      return this.$db.featureActive("nylas_v3") && !this.isEmailEngine // remove later when extending to email engine
    },
    isEmailEngine(): boolean {
      return this.$db.featureActive("imap_engine")
    },
  },
}
</script>
