import { NylasConnectResponse } from "../types/nylas"
import APIMapping from "./api-mapping"
import { APIClient, ApiResponse } from "./base-client"

export class NylasService extends APIClient {
  constructor() {
    super(APIMapping.nylasService)
  }

  async connectAccount(queryParams: Record<string, string>): Promise<ApiResponse<NylasConnectResponse>> {
    return this.invokeApiWithErrorHandling(`/auth`, "GET", undefined, { queryParams })
  }
}

export default new NylasService()
