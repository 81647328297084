<template>
  <nice-select-virtualized
    v-bind="$attrs"
    filterable
    :multiple="multiple"
    :allow-create="allowCreate"
    default-first-option
    :modelValue="modelValue"
    :placeholder="placeholder"
    :options="optionsValues"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue"
const emit = defineEmits(["update:modelValue", "change"])

const props = withDefaults(
  defineProps<{
    modelValue: any
    multiple: boolean
    allowCreate: boolean
    placeholder: string
    options: string[]
  }>(),
  {
    modelValue: [],
    multiple: true,
    allowCreate: false,
    placeholder: "Keine Angabe",
    options: [],
  }
)

const optionsValues = computed(() => props.options.map(option => ({ label: option, value: option })))
</script>
