export const statusCodes = {
  de: {
    "204": "Kein Inhalt",
    "400": "Anfrage fehlerhaft",
    "401": "Nicht authorisiert",
    "403": "Zugriff verboten",
    "404": "Nicht gefunden",
    "408": "Timeout",
    "410": "Nicht mehr vorhanden",
    "429": "Zu viele Anfragen",
    "500": "Fehler beim Laden",
    unspecific: "Etwas ist schief gelaufen",
  },
  en: {
    "204": "No content",
    "400": "Bad request",
    "401": "Not authorized",
    "403": "Forbidden",
    "404": "Not found",
    "408": "Timeout",
    "410": "Not available anymore",
    "429": "Too many requests",
    "500": "Could not be loaded",
    unspecific: "Something went wrong",
  },
} as const

export const translateStatusCode = (statusCode: string | number, language: string | undefined = "en") => {
  const statusCodesMap = statusCodes[language as keyof typeof statusCodes] ?? statusCodes["en"]
  return statusCodesMap[statusCode as keyof typeof statusCodesMap] ?? statusCodesMap.unspecific
}
