import { PagedResponse } from "../types/cloudios-api"
import {
  IIntegrationOrdersService,
  IntegrationOrder,
  IntegrationOrderContext,
  IntegrationOrderCreateData,
  IntegrationOrderQueryParams,
  IntegrationOrderStep,
} from "../types/integration-order-service"
import { IntegrationId } from "../types/integration-service"
import APIMapping from "./api-mapping"
import { APIClient } from "./base-client"

export class IntegrationOrdersService extends APIClient implements IIntegrationOrdersService {
  constructor() {
    super(APIMapping.integrationsService)
  }

  async getCurrentOrderByEntityId(integrationId: IntegrationId, entityId: string) {
    return this.invokeApiWithErrorHandling<IntegrationOrder>(
      `/integrations/${integrationId}/orders/current/entities/${entityId}`,
      "GET"
    )
  }

  async getOrders(integrationId: IntegrationId, params?: Partial<IntegrationOrderQueryParams>) {
    return this.invokeApiWithErrorHandling<PagedResponse<IntegrationOrder>>(
      `/integrations/${integrationId}/orders`,
      "GET",
      undefined,
      {
        queryParams: params,
      }
    )
  }

  async setOrderContext(orderId: string, orderContext: IntegrationOrderContext) {
    return this.invokeApiWithErrorHandling<IntegrationOrderContext>(`/orders/${orderId}/context`, "PUT", orderContext)
  }

  async setOrderStep(orderId: string, step: IntegrationOrderStep) {
    return this.invokeApiWithErrorHandling<IntegrationOrderStep>(`/orders/${orderId}/step`, "PUT", { step })
  }

  async deleteOrder(orderId: string) {
    return this.invokeApiWithErrorHandling<IntegrationOrder>(`/orders/${orderId}`, "DELETE")
  }

  async dispatchOrder(integrationId: IntegrationId, order: IntegrationOrderCreateData) {
    return this.invokeApiWithErrorHandling<IntegrationOrder>(`/integrations/${integrationId}/orders`, "POST", order)
  }
}

export default new IntegrationOrdersService()
