export const isDefined = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined

export const isFunction = <T>(value: T): boolean => typeof value === "function"

/**
 *
 * @param value string or numer
 * @returns boolean indicating this value is a representation of a number
 */
export const isNumeric = (value: string | number | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false
  }
  if (typeof value === "string") {
    // the old calcualtion gave false positives as date string will be parsed to numbers correctly (e.g. "10.13.2021" => 10.13)
    const parsed = parseFloat(value)
    return !isNaN(parsed) && String(parsed) === value // make sure the inverse operation is resulting in the same value
  } else {
    return isFinite(value)
  }
}
