import hotkeys from "hotkeys-js"

const instance = hotkeys

// to make it work globally, see: https://github.com/jaywcjlove/hotkeys/issues/51
instance.filter = event => {
  if (event.target.hasAttribute("allow-override-keys")) return true
  const tagName = event.target.tagName
  return (
    !(event.target.isContentEditable || tagName == "INPUT" || tagName == "SELECT" || tagName == "TEXTAREA") ||
    ((event.metaKey || event.ctrlKey) && event.key == "d")
  )
}

instance.bind = (...args) => {
  instance(...args)
}

let scopeStack = []
const _setScope = instance.setScope
instance.setScope = (...args) => {
  const index = scopeStack.indexOf(args[0])
  if (!scopeStack.length || index !== scopeStack.length - 1) {
    scopeStack.push(args[0])
  }
  _setScope(...args)
}

const _deleteScope = instance.deleteScope
instance.deleteScope = (...args) => {
  const index = scopeStack.indexOf(args[0])
  scopeStack.splice(index, 1)
  _deleteScope(...args)
  if (scopeStack.length && index === scopeStack.length) {
    _setScope(scopeStack[scopeStack.length - 1])
  }
}

export default instance
