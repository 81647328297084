<template>
  <small
    class="top-0 bottom-0 flex items-center"
    :class="{
      'text-yellow-500': total && count > total,
      'text-gray-500': !total || count <= total,
      absolute: absolute,
    }"
    style="right: 0.5rem"
  >
    {{ count || 0 }}{{ total ? `/${total}` : null }}
  </small>
</template>

<script>
export default {
  props: {
    count: Number,
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
  },
}
</script>