<script lang="ts">
import { defineComponent, ref, watch } from "vue"
export default defineComponent({
  setup() {},
})
</script>
<script setup lang="ts">
import { set, toRef, watchDebounced } from "@vueuse/core"
import { useIntegrationAPI } from "@/integrations/use-integration-api"
import { useIntegration } from "@/integrations/use-integration"
import { IntegrationId } from "@/integrations/types/integration-service"
import { useIntegrationVisibility } from "@/integrations/use-integration-visibility"

const props = defineProps<{
  integrationId: IntegrationId
}>()
const integrationId = toRef(() => props.integrationId)
const { integration, isPending } = useIntegration(integrationId)
const { isHidden, isWidgetHidden } = useIntegrationVisibility(integration)
const { mapping, isEnabled, isReady, hasPendingChanges, isUpdating, isToggling } = useIntegrationAPI(integration)
</script>
<template>
  <div v-if="isReady && !isHidden && !isWidgetHidden">
    <slot name="enabled" v-if="isEnabled" :integration="integration" :mapping="mapping" />
    <slot name="disabled" v-else :integration="integration" :mapping="mapping" />
  </div>
</template>

