  <template>
  <form-section>
    <form-row :title="$t('admin.connections.name')" :hint="$t('admin.connections.nameDesc')">
      <nice-input size="small" :placeholder="$t('admin.connections.name')" v-model="item.name" />
    </form-row>
    <form-row :title="$t('admin.connections.parentFolder')" :hint="$t('admin.connections.folderDesc')">
      <db-select collection="folders" v-model="item.parentId" :filter-func="(f) => item.id !== f.id"/>
    </form-row>
  </form-section>
</template>

<script lang="ts">
export default {
  props: {
    item: {
      type: Object
    },
  }
}
</script>