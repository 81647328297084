import { useMutation, useQueryClient } from "@tanstack/vue-query"

import { QueryKeys } from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { Integration, IntegrationId } from "../types/integration-service"

// revokes the currently active consent on the integration
async function deleteIntegrationConsent(integrationId: IntegrationId) {
  const response = await ServiceProvider.integrations.deleteConsent(integrationId)
  return response.isSuccessful2xx
}

export const useIntegrationDeleteConsentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteIntegrationConsent,
    onSuccess: (success, integrationId) => {
      // we just replace the whole integration object removing the consent, we do not invalidate or refetch
      const integrations = queryClient.getQueryData(QueryKeys.integrations.all()) as Record<IntegrationId, Integration>
      const integration = integrations[integrationId]
      if (!success || !integration) {
        return
      }

      const newIntegrations = {
        ...integrations,
        [integrationId]: { ...integration, consent: undefined },
      }

      queryClient.setQueryData(QueryKeys.integrations.all(), newIntegrations)
    },
  })
}
