<template>
  <el-date-picker v-bind="$enhancedAttrs" v-on="$listeners" />
</template>

<script>
export default {
  inheritAttrs: false, // optional
  computed: {
    $enhancedAttrs() {
      return {
        placeholder: this.$t("datePicker"),
        format: "dd.MM.yyyy",
        type: "date",
        class: "w-100",
        size: "small",
        pickerOptions: { firstDayOfWeek: 1 },
        ...this.$attrs,
      }
    },
  },
}
</script>

<style>
.el-date-editor.cursor-pointer > input {
  cursor: pointer;
}

.searchFilter-value .el-date-editor.el-input {
  width: 100%;
}
</style>