<template>
  <select-popover
    :title="$t('inbox.messageDetail.addSource')"
    :selected="inboxBrokerId"
    :items="inboxBrokers"
    @update:selected="selectInbox"
    :showHeader="false"
    :showDots="false"
    :width="250"
    label-key="internalName"
  >
    <template v-slot:reference="data">
      <div class="fake-select--inbox cursor-pointer">
        <fa-icon name="inbox" />
        <span class="ml-2">{{ data.selectLabel }}</span>
      </div>
    </template>
  </select-popover>
</template>

<script>
import saveStateMixin from "@/mixins/save-state-mixin"

export default {
  mixins: [saveStateMixin],
  data() {
    return {
      savedBroker: undefined,
    }
  },
  saveStateConfig: {
    cacheKey: "inboxBroker",
    saveProperties: ["savedBroker"],
  },
  methods: {
    selectInbox(inboxBrokerId) {
      // TODO: find out what the hell is happening here and if needs to be adapted for the new outlook structure
      let path = this.$route.path
      let smartFolderId = undefined
      if (path.match(/\/mailbox\/smart_folders\/\d+/)) {
        smartFolderId = path.match(/\d+/)[0]
      } else if (path.match(/\/\d+($|\?)/)) path = path.replace(/\/\d+/, "")

      let currentOpenBroker = undefined
      if (!this.$db.broker.showInboxMultiselect) inboxBrokerId = [inboxBrokerId.slice(-1)[0]].filter(Boolean)
      const lastElement = inboxBrokerId.slice(-1)[0]
      if (lastElement == "global") {
        currentOpenBroker = "?global=true"
        this.savedBroker = currentOpenBroker
      } else {
        inboxBrokerId = inboxBrokerId.filter(i => i !== "global")
        currentOpenBroker =
          inboxBrokerId.length === 0 || (inboxBrokerId.length === 1 && inboxBrokerId[0] == this.$db.broker.id)
            ? ""
            : `?inboxBrokerId=${inboxBrokerId.join(",")}`
        this.savedBroker = currentOpenBroker
        if (!smartFolderId) return this.$router.push(`${path}${currentOpenBroker}`).catch(() => {})
      }

      if (
        this.$db.broker.availableInboxes.some(
          o => o.id == inboxBrokerId && o.smartFolders.some(n => n.id == smartFolderId)
        )
      ) {
        this.$router.push(`${path}${currentOpenBroker}`).catch(() => {})
      } else {
        this.$router.push(`/mailbox/messages${currentOpenBroker}`).catch(() => {})
      }
    },
  },

  computed: {
    inboxBrokerId() {
      return this.$route.query.global
        ? ["global"]
        : !!this.$route.query.inboxBrokerId
        ? this.$route.query.inboxBrokerId.split(",").filter(Boolean).map(Number)
        : [this.$db.broker.availableInboxes.map(o => o.id)[0]].filter(Boolean)
    },
    inboxBrokers() {
      return [{ internalName: "Alle", name: "Alle", id: "global" }].concat(this.$db.broker.availableInboxes)
    },
  },

  mounted() {
    if (this.savedBroker) this.$router.push(`/mailbox/messages${this.savedBroker}`)
  },
}
</script>
<style lang="scss">
.nice-select--inbox {
  height: 32px;

  i {
    margin-right: 0.75rem !important;
  }
}
.nice-select--inbox::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  content: "\f107";
  color: #9e9e9f;
  position: absolute;
  top: 6px;
  right: 24px;
  pointer-events: none;
}
</style>
