import { get } from "@vueuse/core"
import { computed } from "vue"
import { sort } from "../utils/sort"
import { useIntegrationsQuery } from "./queries/use-integrations-query"

export const useIntegrations = () => {
  const { data, isPending } = useIntegrationsQuery()

  // transform the integrations object back into an array for easier usage in client code
  const integrations = computed(() => sort(Object.values(get(data) ?? {})).asc("id"))

  const visibleIntegrations = computed(() => get(integrations).filter(i => !i.fixedConfiguration?.hidden))

  return {
    data,
    integrations,
    visibleIntegrations, // filtered for hidden integrations
    isPending,
  }
}
