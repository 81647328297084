<template>
  <form-dialog
    :title="$t('inbox.editFormDialog.title')"
    :visible="visible"
    @update:visible="updateVisible"
    :saving="saving"
    @submit="updateMessages"
    :autofocus="false"
  >
    <form-section>
      <form-row :title="$t('inbox.editFormDialog.category')">
        <db-select v-model="categoryId" collection="messageCategories" />
      </form-row>
      <form-row :title="$t('inbox.editFormDialog.client')">
        <client-select v-model="clientId" include-children />
      </form-row>
      <form-row :title="$t('inbox.editFormDialog.property')">
        <property-select v-model="propertyId" />
      </form-row>
      <form-row :title="$t('inbox.editFormDialog.project')">
        <project-select v-model="projectId" />
      </form-row>
      <form-row :title="$t('inbox.editFormDialog.source')">
        <db-select v-model="sourceId" collection="clientSources" />
      </form-row>
      <form-row :title="$t('inbox.editFormDialog.tag')">
        <db-select v-model="groupId" collection="activityGroups" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script>
export default {
  props: ["visible", "selected"],

  data() {
    return {
      saving: false,
      categoryId: null,
      clientId: null,
      propertyId: null,
      projectId: null,
      sourceId: null,
      groupId: null,
    }
  },

  methods: {
    updateVisible(newValue) {
      if (!newValue) this.reset()
      this.$emit("update:visible", newValue)
    },

    reset() {
      this.categoryId = null
      this.clientId = null
      this.propertyId = null
      this.projectId = null
      this.sourceId = null
      this.groupId = null
    },

    updateMessages() {
      this.saving = true
      this.$store
        .dispatch(`inbox/updateMessages`, {
          action: "update_attributes",
          messages: this.selected,
          message_category_id: this.categoryId,
          add_client_id: this.clientId,
          add_property_id: this.propertyId,
          add_project_id: this.projectId,
          client_source_id: this.sourceId,
          add_group_id: this.groupId,
        })
        .then(_ => {
          this.reset()
          this.$store.commit("inbox/setSelected", [])
          this.$emit("update:visible", false)
          App.flashy(this.$t("inbox.editFormDialog.success"))
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.saving = false
        })
    },
  },
}
</script>
