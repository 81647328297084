const FORBIDDEN_FIELDS = [
  "custom_fields",
  "partial_custom_fields",
  "relationships",
  "children",
  "client_addresses",
  "photo_url",
  "intl_home_cell",
  "intl_home_phone",
]

export function formatPayload(record: any) {
  const formatRecord = client => {
    const recordWithoutForbiddenAndCustomFields = _.omitBy(
      client,
      (v, key) => key == "skipDirty" || FORBIDDEN_FIELDS.includes(key) || _.startsWith(key, "cf_")
    )
    const recordWithOnlyCustomFields = _.pickBy(client, (v, key) => _.startsWith(key, "cf_"))
    recordWithoutForbiddenAndCustomFields.partial_custom_fields = _.mapKeys(recordWithOnlyCustomFields, (v, k) =>
      k.replace("cf_", "")
    )

    recordWithoutForbiddenAndCustomFields.client_addresses_attributes = excludeIntlFromClientAddresses(
      client.client_addresses
    )
    return recordWithoutForbiddenAndCustomFields
  }

  const formatted = formatRecord(record)
  formatted.children_attributes = record.children.map(formatRecord)

  return formatted
}

function excludeIntlFromClientAddresses(client_addresses) {
  const clonedAddresses = _.clone(client_addresses)
  _.forEach(clonedAddresses, address => {
    delete address.intl
  })
  return clonedAddresses
}
