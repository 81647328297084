<template>
  <div>
    <form-row title="Aktivitätstyp">
      <nice-select
        placeholder="Alle Aktivitätstypen"
        v-model="fields.activity_type"
        :options="selectableItemTypes"
        @change="fields.categories = []"
      />
    </form-row>

    <form-row v-if="fields.activity_type == 'message'" title="E-Mail Richtung">
      <nice-select
        placeholder="Ausgehend/Eingehend"
        v-model="fields.sender_type"
        :options="{ '1': 'Eingehend', '2': 'Ausgehend' }"
      />
    </form-row>

    <form-row v-if="selectableCategories.length" title="Kategorie">
      <nice-select
        multiple
        v-model="fields.categories"
        placeholder="Jegliche Kategorien"
        :options="selectableCategories"
      />
    </form-row>
  </div>
</template>

<script>
export const ACTIVITY_TYPES = {
  cancelation: "Absage",
  inquiry: "Anfrage",
  reminder: "Aufgabe",
  letter: "Brief",
  message: "E-Mail",
  policy: "Nachweis",
  note: "Notiz",
  sms: "SMS",
  call: "Telefonat",
  event: "Termin",
  publishing: "Veröffentlichung",
}

export default {
  props: {
    fields: {
      default: () => ({
        activity_type: undefined,
        sender_type: undefined,
        categories: undefined,
      }),
    },
  },
  data() {
    return {
      selectableItemTypes: ACTIVITY_TYPES,
      filter: {},
    }
  },
  computed: {
    selectableCategories() {
      if (!this.fields.activity_type)
        return this.$db
          .get("messageCategories")
          .concat(this.$db.get("eventCategories"))
          .concat(this.$db.get("todoCategories"))
          .concat(this.$db.get("noteCategories"))
          .concat(this.$db.get("letterCategories"))
      if (this.fields.activity_type == "message") return this.$db.get("messageCategories")
      if (this.fields.activity_type == "event") return this.$db.get("eventCategories")
      if (this.fields.activity_type == "reminder") return this.$db.get("todoCategories")
      if (this.fields.activity_type == "note") return this.$db.get("noteCategories")
      if (this.fields.activity_type == "cancelation") return this.$db.get("cancelationReasons")
      if (this.fields.activity_type == "inquiry") return this.$db.get("clientSources")
      return []
    },
  },
}
</script>

<style>
</style>