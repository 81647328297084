<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { debounce } from "debounce"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const placeholders = {
  connections: "connections",
  uniqConnections: "connections",
  brokers: "brokers",
  activeBrokers: "brokers",
  unarchivedBrokers: "brokers",
  departments: "departments",
  projects: "projects",
  messageCategories: "categories",
  noteCategories: "categories",
  todoCategories: "categories",
  eventCategories: "categories",
  letterCategories: "categories",
  decisionCategories: "categories",
  clientSources: "sources",
  clientGroups: "tag",
  propertyGroups: "tag",
  activityGroups: "tag",
  cancelationReasons: "cancelationReasons",
  clientStatuses: "statuses",
  clientReasons: "reasons",
  propertyStatuses: "statuses",
  groupedDealStages: "dealStages",
  teams: "teams",
  letterTemplates: "letterTemplates",
  groupedLocations: "locations",
  groupedClientGroups: "tags",
  groupedPropertyGroups: "tags",
  dealPipelines: "dealPipelines",
  roomResources: "resources",
  scoutContacts: "contacts",
  clientSmartViews: "smartViews",
  propertySmartViews: "smartViews",
  dealSmartViews: "smartViews",
  activitySmartViews: "smartViews",
  documentTagsSelect: "documentTags",
  taskPipelines: "dealPipelines",
  taskStages: "dealStages",
  projectStatuses: "projectStatuses",
}

const SALUTATION_OPTIONS = [
  { id: "mr", name: "nameSalutationMr" },
  { id: "ms", name: "nameSalutationMs" },
  { id: "mrms", name: "nameSalutationMrms" },
]

const groupedPropertyGroups = () => {
  const superGroups = db.shopData.superGroups
  return db.shopData.propertyGroups
    .map(g => {
      const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
      return {
        id: g.id,
        name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
      }
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}

const groupedClientGroups = () => {
  const superGroups = db.shopData.superGroups
  return db.shopData.clientGroups
    .map(g => {
      const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
      return {
        id: g.id,
        name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
      }
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}

const groupedDealStages = () => {
  return db.shopData.dealPipelines.map(dp => ({
    id: dp.id,
    name: dp.name,
    children: db.shopData.dealStages.filter(ds => ds.dealPipelineId == dp.id),
  }))
}

const activeBrokers = () => db.get("brokers").filter(b => b.active)

const unarchivedBrokers = () => db.get("brokers").filter(b => !b.archived)

const uniqConnections = () => db.get("connections").filter(o => !o.parentConnectionId)

const roomResources = () => db.get("roomResources").map(rr => ({ id: rr.email, name: rr.name }))

const todoAndDecisionCategories = () => db.shopData.todoCategories.concat(db.shopData.decisionCategories)

const salutations = () =>
  SALUTATION_OPTIONS.map(o => ({ ...o, name: t(`detailView.${o.name}`) })).concat(
    db.get("salutations").map(o => ({ id: o.internalName, name: o.name }))
  )

const collections = {
  todoAndDecisionCategories: todoAndDecisionCategories,
  groupedPropertyGroups: groupedPropertyGroups,
  groupedClientGroups: groupedClientGroups,
  groupedDealStages: groupedDealStages,
  unarchivedBrokers: unarchivedBrokers,
  uniqConnections: uniqConnections,
  roomResources: roomResources,
  activeBrokers: activeBrokers,
  salutations: salutations,
}

const { db, graphql, axios } = useCore()

const emits = defineEmits(["change", "update:modelValue"])

interface DbSelectProps {
  collection: string
  placeholder?: string
  size?: string
  additionalOptions?: any | any[]
  multiple?: boolean
  allowCreate?: boolean
  clearable?: boolean
  filterFunc?: (item) => boolean
  tooltipFunc?: (item) => string
  labelKey?: string
  label?: string
  showDisabledRegularly?: boolean
  lazy?: boolean
  fixedPosition?: boolean
}

const props = withDefaults(defineProps<DbSelectProps>(), {
  tooltipFunc: undefined,
  collection: undefined,
  placeholder: undefined,
  size: "default",
  additionalOptions: null,
  multiple: false,
  allowCreate: false,
  clearable: true,
  filterFunc: undefined,
  label: undefined,
  labelKey: undefined,
  showDisabledRegularly: false,
  lazy: false,
  fixedPosition: false,
})

const fetchedItems = ref<any[] | undefined>(undefined)
const filteredOptions = ref<any[]>([])

const unfilteredItems = computed(() => {
  let items: any[] =
    (fetchedItems.value ||
      collections[props.collection]?.() ||
      (typeof db[props.collection] == "function" ? db[props.collection]() : db.get(props.collection))) ??
    []
  if (props.additionalOptions) items = items?.concat(props.additionalOptions)
  return items as any[]
})

const items = computed(() => {
  let items: any[] = unfilteredItems.value
  if (props.filterFunc) items = items?.filter(props.filterFunc)
  return items as any[]
})

const grouped = computed(() => {
  const GROUPABLE_COLLECTIONS = ["groupedDealStages"]
  return GROUPABLE_COLLECTIONS.includes(props.collection)
})

const renderAsync = computed(() => items.value?.length > 200)

const totalOptions = computed(() => {
  return renderAsync.value ? filteredOptions.value : items.value
})

const placeholderWithFallback = computed(() => {
  const key = placeholders[props.label || props.collection]
  return key
    ? t("dbSelect.text", {
        placeholder: t(`dbSelect.placeholders.${key}`),
      })
    : t("niceSelect.placeholder")
})

const isBrokerCollection = computed(() => ["brokers", "activeBrokers", "unarchivedBrokers"].includes(props.collection))
const labelKey = computed(() => (props.labelKey ?? isBrokerCollection.value ? "internalName" : "name"))

const preselect = val => {
  if (!renderAsync.value) return true
  filteredOptions.value = (
    props.multiple ? val.map(v => items.value.find(o => o?.id == v)) : [items.value.find(o => o?.id == val)]
  ).filter(Boolean)
}

const remoteMethod = debounce(function (query) {
  if (query) {
    filteredOptions.value = items.value
      .filter(item => {
        const idFound = item.id == query.trim().toLowerCase()
        return idFound || (item.name ?? item.internalName)?.toLowerCase().indexOf(query.trim().toLowerCase()) > -1
      })
      .slice(0, 500)
  } else {
    filteredOptions.value = []
  }
})

const lookupFn = val => {
  let labelDataSource = unfilteredItems.value

  // if we are looking for brokers, we need select the label from the full brokers collection
  // because some assigned brokers might be filtered out, but they are still assigned and we need to show their name
  if (isBrokerCollection.value) {
    labelDataSource = db.get("brokers")
    if (props.additionalOptions) labelDataSource = labelDataSource?.concat(props.additionalOptions)
  }

  if (grouped.value)
    return labelDataSource
      .map(({ children }) => children || [])
      .reduce((acc, val) => acc.concat(val), [])
      .find(({ id }) => id === val)

  const item = labelDataSource.find(d => d.id === val || d[labelKey.value] === val)

  return item
}

const labelFn = val => {
  return lookupFn(val)?.[labelKey.value]
}

const tooltipFn = props.tooltipFunc ? val => props.tooltipFunc?.(val) : undefined

const fetch = () => {
  graphql(`query dbSelectFor${props.collection} { shop { ${props.collection} { id name } } }`)
    .then(({ shop }) => {
      fetchedItems.value = shop[props.collection]
    })
    .catch(axios.handleError)
}
const model = defineModel()

watch(
  () => model.value,
  (newVal, oldVal) => {
    if (_.isEmpty(newVal) || newVal === oldVal) return
    preselect(newVal)
  },
  { immediate: true }
)

onMounted(() => {
  if (props.lazy) fetch()
})
</script>

<template>
  <nice-select
    :multiple="multiple"
    :allow-create="allowCreate"
    filterable
    :clearable="clearable"
    :remote="renderAsync"
    :remote-method="renderAsync ? remoteMethod : undefined"
    :model-value="model"
    @update:model-value="$emit('update:modelValue', $event)"
    default-first-option
    @change="$emit('change', $event)"
    :placeholder="placeholderWithFallback"
    :size="size"
    :options="totalOptions"
    :fixed-position="fixedPosition"
    :label-fn="labelFn"
  >
    <template #default>
      <template v-if="grouped">
        <el-option-group v-for="group in totalOptions" :key="group.id" :label="group.name">
          <el-option v-for="item in group.children" :key="item.id" :label="item[labelKey]" :value="item.id">
            <slot v-bind="{ item }" name="option">{{ item[labelKey] }}</slot>
          </el-option>
        </el-option-group>
      </template>
      <template v-else>
        <el-option
          v-for="item in totalOptions"
          :key="item.id"
          :label="item[labelKey]"
          :value="item.id"
          :disabled="!showDisabledRegularly && item.disabled"
        >
          <slot v-bind="{ item }" name="option">{{ item[labelKey] }}</slot>
        </el-option>
      </template>
    </template>
    <template #tags="{ $onRemoveValue, values }">
      <span v-for="value in values" :key="value">
        <el-tag :color="!totalOptions.find(o => o.id == value) ? '#eee' : ''">
          <nice-tooltip v-if="tooltipFn" :content="tooltipFn(value)">
            <span class="text-black">
              {{ labelFn(value) }}
            </span>
          </nice-tooltip>
          <span v-else>
            <span class="text-black">
              {{ labelFn(value) }}
            </span>
          </span>
          <i
            class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
            @click.prevent="$onRemoveValue(value)"
          />
        </el-tag>
      </span>
    </template>
  </nice-select>
</template>
