export interface PagedResponse<T> {
  entities: T[]
  totalCount: number
  page: number
  size: number
}

export enum SchemaNames {
  APPOINTMENTS = "appointments",
  INQUIRIES = "inquiries",
  ESTATES = "estates",
  DEVELOPER_PROJECTS = "developer_projects",
  CONTACTS = "contacts",
  CONTACTSGROUP = "contactsGroup",
  DOCUMENTS = "documents",
  TASKBOARD = "taskboard",
  TASKS = "tasks",
  ESTATE_SEARCH_PROFILES = "estate_search_profiles",
  EMAIL = "E-Mail",
  EMAILS = "emails", // the group schema?
  GLOBAL_CHANGE = "global_change",
  SERIAL_EMAIL = "serial_email",
  DRAFT_EMAIL = "draft_email",
  DRAFT_EXPOSE_EMAIL = "draft_expose_email",
  CALL = "calls",
  ACTIVITY_REPORT = "activity_report",
  NEWSFEED = "newsfeed",
  NOTES = "notes",
  IEX = "interactive_exposes",
  IEX_V2_TEMPLATE = "interactive_expose_v2_templates",
  IEX_TRACKING = "interactive_exposes_tracking",
  IEX_OFFER = "interactive_exposes_offers",
  COMPANIES = "companies",
  GDPR_CONSENTS = "gdpr_consents",
  SEARCHES = "searches",
  HISTORY = "history",
  DEVICES = "device",
  PROJECTS = "flowfact_projects",
  SCOUT_MORTGAGE_TRANSACTION = "SMACRMTransaction",
  SCOUT_MORTGAGE_SYSTEM_MESSAGE = "SMACRMSystemMessage",
}
