import { camelCase } from "@/utils/with-case"
import FORM_FIELDS from "./form-fields"
import { customFieldFormat } from "@/config/property/editable-fields"

const EXTRA_FIELDS = [
  { id: "add_broker_ids", dbOptions: "brokers" },
  { id: "sub_broker_ids", dbOptions: "brokers" },
  { id: "add_department_ids", dbOptions: "departments" },
  { id: "sub_department_ids", dbOptions: "departments" },
  { id: "add_access_broker_ids", dbOptions: "brokers" },
  { id: "sub_access_broker_ids", dbOptions: "brokers" },
  { id: "add_access_department_ids", dbOptions: "departments" },
  { id: "sub_access_department_ids", dbOptions: "departments" },
  { id: "add_contact_sync_broker_ids", dbOptions: "activeBrokers", type: "multiselect" },
  { id: "add_project_id", dbOptions: "projects" },
  { id: "sub_project_id", dbOptions: "projects" },
  { id: "salutation", dbOptions: "salutations" },
  { id: "add_group_ids", dbOptions: "clientGroups", type: "multiselect" },
  { id: "sub_group_ids", dbOptions: "clientGroups", type: "multiselect" },
  { id: "home_street", type: "string" },
  { id: "home_country", type: "string" },
  { id: "home_city", type: "string" },
  { id: "home_house_number", type: "string" },
  { id: "home_zip_code", type: "string" },
]

export const getEditableFields = (db, t) => {
  let formFields: any[] = Object.keys(FORM_FIELDS)
  let allFields: any[] = EXTRA_FIELDS.concat(formFields)
  let fields = allFields.map(key => {
    if (typeof key == "object") return key

    const field = FORM_FIELDS[camelCase(key)]
    return {
      id: key,
      ...field,
      options: typeof field.options == "function" ? field.options({ db, t }) : field.options,
    }
  })
  if (db.featureActive("tipster_portal")) {
    fields = fields.concat([{ id: "create_tipster", type: "boolean" }])
  }

  fields = fields.concat(
    db.shopData.customFieldGroupsForClients
      .map(cg => cg.customFields)
      .flat()
      .map(cf => customFieldFormat(cf, db))
      .flat()
  )

  return fields
}
