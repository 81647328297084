<script setup lang="ts">
import { computed } from "vue"
import { PredefinedColors } from "./ColorPicker"
import { ColorPicker as Picker } from "vue3-colorpicker"
import "vue3-colorpicker/style.css"

const props = withDefaults(
  defineProps<{
    customColors?: string[]
    canReset?: boolean
  }>(),
  {
    canReset: true,
  }
)

const model = defineModel()

const colors = computed(() => props.customColors || PredefinedColors)

const updateModel = (value: string | null) => {
  model.value = value
}
</script>

<template>
  <div class="ps-picker" :class="{ 'ps-picker__empty': !model }">
    <Picker
      disable-history
      disable-alpha
      use-type="pure"
      picker-type="chrome"
      :pure-color="model"
      format="hex"
      @update:pureColor="updateModel"
    >
      <template #extra>
        <div
          :id="color"
          v-for="color in colors"
          :key="color"
          class="inline-flex w-6 h-6"
          :style="{ 'background-color': color }"
          @click="updateModel(color)"
        ></div>
        <div v-if="canReset" class="mt-2">
          <nice-button size="small" type="secondary" @click="updateModel(null)">
            {{ $t("colorPicker.reset") }}
          </nice-button>
        </div>
      </template>
    </Picker>
  </div>
</template>

<style>
.ps-picker .vc-color-wrap {
  width: 24px;
}

.ps-picker__empty .vc-color-wrap .current-color {
  width: 100%;
  height: 100%;
  background-color: gray !important;
}

.ps-picker__empty .vc-color-wrap ::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  display: block;
  left: 8px;
  top: 2px;
}
</style>
