<template>
  <div
    class="psTable-row"
    :class="[{ 'psTable-row--archived': props.archived }]"
    @dblclick="$emit('dblclick', $event)"
    @click="$emit('click', $event)"
  >
    <slot name="default"></slot>
    <ps-row-cell v-if="$slots.last_cell || $slots.drag" class="psTable-cell--last relative">
      <slot name="last_cell"></slot>
      <slot name="drag"></slot>
    </ps-row-cell>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["click", "dblclick"])

const props = defineProps({
  archived: Boolean,
  dblclick: Function,
})
</script>
