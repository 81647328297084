<template>
  <div style="display: none">
    <div id="page">
      <slot class="printwrapper-slot"></slot>
    </div>
  </div>
</template>

<script>
import { Printd } from "printd"

export default {
  props: ["css"],
  data() {
    return {
      printer: undefined,
    }
  },
  mounted() {
    this.printer = new Printd({ parent: this.$el })
  },
  methods: {
    print() {
      this.printer.print(document.getElementById("page"), [this.css])
    },
  },
}
</script>