<template>
  <div>
    <div v-if="!loading && error" class="popover-empty">{{ $t("header.notifError") }}/</div>
    <ul class="tracking-list" v-infinite-scroll="fetchData" :infinite-scroll-disabled="loading || noMore">
      <li
        v-for="item in trackings"
        :key="item.id"
        class="tracking-item"
        @click="openMessage(item)"
        :class="{ 'tracking-item--clickable': global }"
      >
        <p class="tracking-itemHeader">
          <span class="tracking-itemTitle">
            <i class="tracking-itemIcon" :class="'fal fa-' + item.icon"></i>
            {{ item.client_name || $t("header.trackings.noName") }}
          </span>
          <span class="tracking-itemTime">
            {{ $customFilters.datetime(item.created_at) }}
          </span>
        </p>
        <p class="tracking-itemBody">{{ item.content }}</p>
      </li>
    </ul>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p class="text-center light py-3" v-else-if="trackings.length <= 0">{{ $t("header.notifEmpty") }}</p>
    <p class="text-center light py-3" v-else-if="noMore">{{ $t("header.notifNoMore") }}</p>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"
import { useI18n } from "vue-i18n"
const MESSAGE_OPENED = 1
const PROPERTY_LIKED = 2
const FILE_DOWNLOADED = 3
const LINK_CLICKED = 4
const POLICY_VISITED = 5
const LANDINGPAGE_VISITED = 6
const EXPOSE_DOWNLOADED = 7
const FILE_UPLOADED = 8
const FILE_DELETED = 9

const formatTracking = (tracking, t) => {
  let icon, name
  let { content } = tracking

  if (tracking.action_type === FILE_DOWNLOADED) {
    icon = "paperclip"
    content = t("header.trackings.logs.fileDownloaded", { name: tracking.content })
  } else if (tracking.action_type === EXPOSE_DOWNLOADED) {
    icon = "paperclip"
    name = tracking.property_name && tracking.property_name !== null ? tracking.property_name : tracking.project_name
    content = t("header.trackings.logs.exposeDownloaded", { name: name })
  } else if (tracking.action_type === PROPERTY_LIKED) {
    icon = "heart"
    content =
      t("header.trackings.logs.propertyLiked", { name: tracking.property_name }) +
      (tracking.project_name ? ` (${tracking.project_name})` : "")
  } else if (tracking.action_type === LINK_CLICKED) {
    icon = "mouse-pointer"
    content = t("header.trackings.logs.linkClicked", { name: tracking.content })
  } else if (tracking.action_type === LANDINGPAGE_VISITED) {
    icon = "eye"
    name =
      tracking.property_name ||
      tracking.project_name ||
      tracking.content ||
      _.get(tracking.message, "subject") ||
      t("header.trackings.logs.noSubject")
    content = t("header.trackings.logs.lpVisited", { name: name })
  } else if (tracking.action_type === FILE_UPLOADED) {
    icon = "paperclip"
    content = t("header.trackings.logs.fileUploaded", { name: tracking.content })
  } else if (tracking.action_type === FILE_DELETED) {
    icon = "paperclip"
    content = t("header.trackings.logs.fileDeleted", { name: tracking.content })
  } else {
    icon = "eye"
    content = t("header.trackings.logs.lpVisitedAlt", { name: tracking.content })
  }

  return {
    ...tracking,
    icon,
    content,
  }
}

const PAGE_PER = 20

export default {
  props: {
    messageId: {
      default: "",
    },
    global: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 0,
      trackings: [],
      loading: false,
      error: false,
      noMore: false,
    }
  },

  methods: {
    fetchData() {
      this.page++
      this.loading = true
      this.$axios
        .get(`/mailbox/message_trackings?message_id=${this.messageId}&page=${this.page}`)
        .then(({ data }) => {
          this.trackings = this.trackings.concat(data.data.map(tracking => formatTracking(tracking, this.$t)))
          this.loading = false
          this.error = false
          this.noMore = PAGE_PER * this.page >= data.meta.total_count
        })
        .then(() => this.$axios.post(`/mailbox/message_trackings/read?message_id=${this.messageId}`))
        .catch(err => {
          this.loading = false
          this.error = true
          console.error(err)
        })
    },

    openMessage(item) {
      if (!this.global || !item.message) return

      const id = item.message_id

      eventBus.$emit("quick-view", { type: "mail", mode: "view", id })

      $("body").trigger("click") // dismiss the popover
    },
  },

  mounted() {
    this.fetchData()
  },
}
</script>
