import { IntegrationOrderStepMapping } from "../orders/integration-order-mapping"
import {
  IntegrationOrderStepAction,
  IntegrationOrderStepActionTypes,
  IntegrationOrderStepDescriptor,
} from "../types/integration-order"
import { IntegrationOrderStep } from "../types/integration-order-service"
import { TranslationFunction } from "../types/integration-page"
import { IntegrationId, IntegrationOrderStepsConfiguration } from "../types/integration-service"

const getStepDescription = (t: TranslationFunction, integrationId: IntegrationId, step: IntegrationOrderStep) => {
  const specificKey = `integrations.${integrationId.replace(/-ps$/, "")}.order.steps.${step}`
  const specificKeyTranslationResult = t(specificKey)
  if (specificKeyTranslationResult !== specificKey) return specificKeyTranslationResult
  const genericKey = `integrations.orders.steps.${step}`
  const genericKeyTranslationResult = t(specificKey)
  if (genericKeyTranslationResult !== genericKey) return genericKeyTranslationResult
  return undefined
}

const getActionLabel = (
  t: TranslationFunction,
  integrationId: IntegrationId,
  type?: IntegrationOrderStepActionTypes
) => {
  if (!type) {
    return t("integrations.orders.actions.default")
  }
  const specificKey = `integrations.${integrationId.replace(/-ps$/, "")}.order.actions.${type}`
  const specificKeyTranslationResult = t(specificKey)
  if (specificKeyTranslationResult !== specificKey) return specificKeyTranslationResult
  const genericKey = `integrations.orders.actions.${type}`
  const genericKeyTranslationResult = t(specificKey)
  if (genericKeyTranslationResult !== genericKey) return genericKeyTranslationResult
  return t("integrations.orders.actions.default")
}

export const createIntegrationOrderStepDescriptors = (
  integrationId: IntegrationId,
  orderStepsConfig: IntegrationOrderStepsConfiguration,
  t: TranslationFunction
) => {
  const { steps } = orderStepsConfig

  let maxVisibleSteps = orderStepsConfig.maxVisibleSteps

  // when the maxVisibleSteps are not defined, we take the max step number from the steps
  if (!maxVisibleSteps) {
    maxVisibleSteps = Math.max(...steps.map(step => step.stepNumber || 0))
  }

  const config = IntegrationOrderStepMapping[integrationId]
  // no config for the integration available
  if (!config) {
    console.warn(`No order configuration found for integration ${integrationId}`)
    return {
      maxVisibleSteps,
      allStepConfigs: [],
      configuredSteps: {},
    }
  }

  const { defaultStepConfiguration, stepConfiguration } = config

  // deepmerge with fallbacks
  const configuredSteps = Object.entries(stepConfiguration).reduce((all, [step, stepConfig]) => {
    const description = getStepDescription(t, integrationId, step as IntegrationOrderStep)

    let action: IntegrationOrderStepAction | undefined = undefined

    if (stepConfig !== undefined && stepConfig.action) {
      action = { ...defaultStepConfiguration?.action, ...stepConfig.action } as IntegrationOrderStepAction // ensure that the action is of the correct type
      action.label ||= getActionLabel(t, integrationId, action.type).toString() // when there is an action but no label, use the default label
    }

    const currentStep = steps.find(s => s.step === step)

    all[step as IntegrationOrderStep] = {
      ...stepConfig,
      id: step,
      step,
      stepNumber: currentStep?.stepNumber,
      description,
      action,
      isProgressStep: currentStep?.stepNumber && currentStep.stepNumber <= maxVisibleSteps,
    } as IntegrationOrderStepDescriptor

    return all
  }, {} as Record<IntegrationOrderStep, IntegrationOrderStepDescriptor>)

  const allStepConfigs = Object.entries(configuredSteps).map(([_, stepConfig]) => stepConfig)

  return { allStepConfigs, configuredSteps, maxVisibleSteps }
}
