<template>
  <div class="d-flex align-items-center justify-content-center" :style="{ height: height }">
    <div class="text-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
    },
  },
}
</script>