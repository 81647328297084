export default {
  props: {
    canSelectItem: {
      type: Function,
      default: _ => true,
    },
  },
  methods: {
    resetSelected() {
      this.selected = []
    },

    toggleSelected(value) {
      const newSelected = this.idsSelected.includes(value.id)
        ? this.selected.filter(o => (this.shouldSelectItems ? o.id : o) != value.id)
        : this.canSelectItem(value) && this.selected.concat([this.shouldSelectItems ? value : value.id])
      this.$emit("update:selected", newSelected)
    },

    toggleSelectedAll() {
      const newSelected = this.allRowsSelected
        ? this.selected.filter(o => !this.idsFromCurrentPage.includes(this.shouldSelectItems ? o.id : o))
        : [...new Set(this.selected.concat(this.shouldSelectItems ? this.selectableItems : this.idsFromCurrentPage))]
      this.$emit("update:selected", newSelected)
    },
    isSelected(value) {
      return this.idsSelected.includes(value.id)
    },
  },
  computed: {
    selectableItems() {
      return this.data.filter(this.canSelectItem)
    },
    idsSelected() {
      return this.shouldSelectItems ? this.selected.map(s => s.id) : this.selected
    },
    idsFromCurrentPage() {
      return this.selectableItems.map(o => o.id)
    },
    allRowsSelected() {
      const selectedRows = this.idsFromCurrentPage.filter(o => !this.idsSelected.includes(o))
      return this.data.length > 0 && selectedRows.length <= 0
    },
  },
}
