<template>
  <nice-select
    ref="select"
    :multiple="multiple"
    filterable
    clearable
    :modelValue="modelValue"
    @update:modelValue="handleUpdate"
    default-first-option
    :placeholder="$t('propertySelect.placeholder')"
    :reserve-keyword="reserveKeyword"
    remote
    :remote-method="fetch"
    :options="this.displaySuggestions ? groupedProperties : properties"
    :grouped="this.displaySuggestions"
    :loading="loading"
    :size="size"
    :label-fn="labelFn"
    data-testid="property-select"
  >
    <template #label="{ label, value }">
      <span>{{ cachedPropertiesMap.get(value)?.name }}</span>
    </template>
    <template #tags="{ $onRemoveValue, values }">
      <span v-for="id in values" :key="id">
        <el-tag>
          <span class="text-black">
            {{ cachedPropertiesMap.get(id)?.name }}
          </span>
          <i
            class="fal fa-times clear-tag text-slate-500 hover:text-slate-800 ml-2 cursor-pointer"
            @click.prevent="$onRemoveValue(id)"
          />
        </el-tag>
      </span>
    </template>
    <template #option="{ item }">
      <property-select-option class="property-select" :property="item" :data-id="item.id" />
    </template>
  </nice-select>
</template>

<script>
import PropertySelectOption from "./PropertySelectOption"
import { cacheProperties, cachedPropertiesMap } from "@/config/remote-data-cache"
import { useI18n } from "vue-i18n"

export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reserveKeyword: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
    },
    variants: {
      type: Boolean,
      default: false,
    },
    suggestPropertiesForClientId: {
      type: Number,
      default: null,
    },
  },
  expose: ["prefill"],
  emits: ["update:modelValue", "change-project", "ready"],
  components: {
    PropertySelectOption,
  },
  setup() {
    const { t } = useI18n()
    return { cachedPropertiesMap, t }
  },
  data() {
    return {
      properties: [],
      loading: false,
      selectedProperties: [],
      selected: [],
    }
  },
  watch: {
    selected: {
      handler(newValue) {
        if (!newValue || !this.multiple) return
        const selectedPropertyMap = this.selectedProperties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
        const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
        this.selectedProperties = this.selected?.map(id => selectedPropertyMap[id] || propertyMap[id]) || []
      },
      deep: true,
    },
    selectedProperties: {
      handler(newValue) {
        if (!newValue) return
        setTimeout(() => {
          const projectIds = this.selectedProperties.length
            ? [...new Set(this.selectedProperties.map(s => (s ? s.project_id : undefined)).filter(Boolean))]
            : undefined
          this.$emit("change-project", projectIds)
        }, 100)
      },
      deep: true,
    },
  },
  computed: {
    displaySuggestions() {
      return this.suggestPropertiesForClientId != null
    },
    groupedProperties() {
      const suggestedProperties = this.filterPropertiesBySuggestClientId(this.properties)
      const groups = [
        {
          id: 0,
          name: this.t("inbox.messageDetail.lnkdSuggestions.suggested"),
          children: suggestedProperties,
        },
        {
          id: 1,
          name: this.t("inbox.messageDetail.lnkdSuggestions.all"),
          children: this.properties.filter(
            property => !suggestedProperties.map(suggestedProperty => suggestedProperty.id).includes(property.id)
          ),
        },
      ]
      return groups
    },
  },
  methods: {
    filterPropertiesBySuggestClientId(properties) {
      return properties.filter(property => property.suggestion != null)
    },
    labelFn(val) {
      return Array.isArray(this.selectedProperties)
        ? this.selectedProperties.find(({ id }) => id === val)?.name
        : this.selectedProperties?.name
    },
    async fetchDeals() {
      return this.$axios.post(`/api/v1/client_properties/search`, {
        per: 25,
        page: 1,
        sort_by: "deal_stage_chance",
        order: "desc",
        filter_set: {
          must: [
            {
              term: {
                client_id: this.suggestPropertiesForClientId,
              },
            },
          ],
        },
      })
    },
    async fetchProperties(query) {
      const getParameters = {
        include_variants: this.variants,
      }
      if (query && query.length > 0) getParameters.q = query
      if (this.displaySuggestions) getParameters.client_id_suggestion = this.suggestPropertiesForClientId
      return this.$axios.get(
        `/portfolio/properties?${Object.keys(getParameters)
          .map(key => `${key}=${getParameters[key]}`)
          .join("&")}`
      )
    },
    async fetch(query) {
      if (!query && !this.displaySuggestions) {
        this.properties = []
        return
      }

      this.loading = true
      const promises = []

      const getParameters = {
        include_variants: this.variants,
      }
      if (query && query.length > 0) getParameters.q = query
      if (this.displaySuggestions) getParameters.client_id_suggestion = this.suggestPropertiesForClientId
      promises.push(this.fetchProperties(query))

      if (this.displaySuggestions) {
        // fetch deals for client
        promises.push(this.fetchDeals())
      }

      const results = await Promise.all(promises)
      const propertiesData = results[0]?.data?.data || []

      let data = propertiesData

      if (results.length == 2) {
        const dealsData = results[1]?.data?.data || []
        // add suggestion flag to properties
        data = propertiesData.map(property => {
          let suggestion = null
          if (property.relationships.includes(this.suggestPropertiesForClientId)) suggestion = "relationship"
          else if (dealsData.find(deal => deal.property_id == property.id)) suggestion = "deal"
          return {
            ...property,
            ...{
              suggestion,
            },
          }
        })

        // add properties found in deals query but not in properties query if no search term was specified
        if (!query || query.length == 0) {
          data = [
            ...data,
            ...dealsData
              .filter(deal => !data.map(property => property.id).includes(deal.property_id))
              .map(deal => ({ ...deal.property, ...{ suggestion: "deal", id: deal.property_id } })),
          ]
        }
      }

      // In case we don't have a query and there are no property suggestions by relationship, revert to previous behaviour and display nothing
      if (!query && this.filterPropertiesBySuggestClientId(data).length == 0) {
        this.properties = []
        this.loading = false
        return
      }

      this.properties = data
      cacheProperties(data)
      this.loading = false
    },
    handleUpdate(value) {
      this.$emit("update:modelValue", value)
      this.selected = value
      this.$refs.select?.select?.blur()
    },
    prefillSetProperties() {
      const propertyMap = this.properties.reduce((agg, cur) => ({ ...agg, [cur.id]: cur }), {})
      this.selectedProperties = this.multiple
        ? this.modelValue.map(id => propertyMap[id])
        : propertyMap[this.modelValue]
    },
    prefill() {
      if (!this.modelValue) return
      const ids = this.multiple ? this.modelValue.join(",") : this.modelValue
      if (!ids) return

      this.$axios.get(`/portfolio/properties?property_ids=${ids}`).then(({ data }) => {
        this.properties = data.data
        this.prefillSetProperties()
        cacheProperties(data.data)
        this.$emit("ready")
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.prefill()
    })
  },
}
</script>

<style scoped>
.property-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
