<template>
  <div class="w-64 h-36 black flex border-2 border-gray" :style="`background-color: ${watermarkContrast};`">
    <div
      class="w-64 h-36"
      v-if="cssPosition != null"
      :style="`display: flex; align-items: ${cssPosition.vertical}; justify-content: ${cssPosition.horizontal};`"
    >
      <img
        :src="url"
        :style="`opacity: ${opacity}; width: ${
          watermarkSize.scale === 'width' ? watermarkSize.value + 'px' : 'auto'
        }; height: ${watermarkSize.scale === 'height' ? watermarkSize.value + 'px' : 'auto'};`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    opacity: Number,
    scale: Number,
    position: String,
    width: Number,
    height: Number,
  },
  mounted() {
    this.calculate()
  },
  data() {
    return {
      watermarkWidth: 0,
      watermarkHeight: 0,
      watermarkContrast: "lightgray",
    }
  },
  watch: {
    url() {
      this.calculate()
    },
  },
  methods: {
    calculate() {
      let watermarkImage = new Image()
      watermarkImage.onload = () => {
        this.watermarkWidth = watermarkImage.width
        this.watermarkHeight = watermarkImage.height
        // if (this.getWatermarkBrightness(watermarkImage) < 127.5) {
        //   this.watermarkContrast = "black"
        // } else {
        //   this.watermarkContrast = "white"
        // }
      }
      watermarkImage.src = this.url
    },
    // getWatermarkBrightness(image) {
    //   try {
    //     const canvas = document.createElement("canvas")
    //     canvas.crossOrigin = "anonymous"
    //     canvas.width = image.width
    //     canvas.height = image.height

    //     const ctx = canvas.getContext("2d")
    //     ctx.drawImage(image, 0, 0)

    //     const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    //     const data = imageData.data
    //     let colorSum = 0
    //     let r, g, b, avg
    //     for (let x = 0, len = data.length; x < len; x += 4) {
    //       r = data[x]
    //       g = data[x + 1]
    //       b = data[x + 2]

    //       avg = Math.floor((r + g + b) / 3)
    //       colorSum += avg
    //     }
    //     const brightness = Math.floor(colorSum / (image.width * image.height))
    //     return brightness
    //   } catch (ex) {
    //     // default: dark image
    //     console.error(ex)
    //     return 255
    //   }
    // },
  },
  computed: {
    watermarkSize() {
      const canvas = {
        width: 256,
        height: 144,
      }
      const heightDiff = this.watermarkHeight - canvas.height
      const widthDiff = this.watermarkWidth - canvas.width
      const largerScale = (
        heightDiff > 0 || widthDiff > 0 ? heightDiff > widthDiff : this.watermarkHeight > this.watermarkWidth
      )
        ? "height"
        : "width"
      const size = Math.floor(canvas[largerScale] * this.scale) - 1
      return {
        scale: largerScale,
        value: size,
      }
    },
    cssPosition() {
      const positions = {
        NorthWest: {
          vertical: "start",
          horizontal: "left",
        },
        North: {
          vertical: "start",
          horizontal: "center",
        },
        NorthEast: {
          vertical: "start",
          horizontal: "right",
        },
        West: {
          vertical: "center",
          horizontal: "left",
        },
        Center: {
          vertical: "center",
          horizontal: "center",
        },
        East: {
          vertical: "center",
          horizontal: "right",
        },
        SouthWest: {
          vertical: "end",
          horizontal: "left",
        },
        South: {
          vertical: "end",
          horizontal: "center",
        },
        SouthEast: {
          vertical: "end",
          horizontal: "right",
        },
      }
      return Object.keys(positions).includes(this.position) ? positions[this.position] : null
    },
  },
}
</script>

<style>
</style>
