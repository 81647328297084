/*
 * Liefert ein Data Attribut "currentPage" und Listener, damit "fetchData" automatisch aufgerufen wird
 */
export default {
  data() {
    return {
      currentPage: 1
    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    }
  }
}
