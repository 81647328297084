export const arrayToTree = (items, filter) => {
  /**
   * The nested tree.
   * @type {*[]}
   */
  const rootItems = []

  // (1) Create a holder for each item.

  const lookup = {}

  if (!filter) filter = key => key

  for (const item of items) {
    const lookupKey = item.folder ? `f${item.id}` : `d${item.id}`
    const parentLookupKey = item.parentId
      ? `f${item.parentId}`
      : filter(item.folderId)
      ? `f${item.folderId}`
      : undefined

    // (2) Create a placeholder for each item in the lookup.
    // Details are added later.

    if (!lookup[lookupKey]) lookup[lookupKey] = { items: [] }

    // (3) Add the details of the item.

    lookup[lookupKey] = { ...item, items: lookup[lookupKey]["items"] }

    // (4) Create a variable for the current item.

    const TreeItem = lookup[lookupKey]

    // (5) Determine where the item goes in the tree.

    // If the item has no parentLookupKey, it is the root node.
    if (!parentLookupKey) {
      rootItems.push(TreeItem)
    } else {
      /*
       * If the item has a parentLookupKey, add it to the tree.
       */
      // (6) Add a placeholder for parent of the current item.

      if (!lookup[parentLookupKey]) lookup[parentLookupKey] = { ["items"]: [] }

      // (7) Add the current item to its parent.

      lookup[parentLookupKey]["items"].push(TreeItem)
    }
  }

  // check if items are assigned to non existand folders
  // if yes, add to root
  Object.keys(lookup).forEach(key => {
    const item = lookup[key]
    // skip root elements, skip elements without id
    if (!item.folderId || !item.parentId || !item.id) return
    if (!rootItems.find(i => i.id == item.folderId || i.id == item.parentId)) rootItems.push(item)
  })

  return rootItems
}

export const removeEmptyItems = arr => {
  arr.forEach(item => {
    if (item.items && item.items.length) removeEmptyItems(item.items)
  })
  return arr
}
