export default (type: string, params: Record<string, any>) => {
  const elem = document.createElement(type)

  if (type === "style") {
    if (params["type"]) {
      Object.assign(elem, { type: params["type"] })
    }
  } else if (type === "script") {
    if (params["src"]) {
      Object.assign(elem, { src: params["src"] })
    }
    if (params["async"]) {
      Object.assign(elem, { async: params["async"] })
    }
    if (params["onload"]) {
      Object.assign(elem, { onload: params["onload"] })
    }
    if (params["id"]) {
      Object.assign(elem, { id: params["id"] })
    }
  } else {
    throw new Error("Unknown type.")
  }
  if (params["content"]) {
    elem.appendChild(document.createTextNode(params["content"]))
  }
  return document.head.appendChild(elem)
}
