<template>
  <form-dialog
    :autofocus="false"
    :visible="visible"
    :title="`${selected.length} ${title}`"
    width="720px"
    append-to-body
    @close="$emit('update:visible', false)"
    :saving="submitting"
    @submit="submit"
    :disabled="!payload[0]"
    :submitButtonTitle="$t('massEditDialog.btn')"
  >
    <form-section>
      <mass-edit-item
        v-for="(item, idx) in payload"
        :key="idx"
        :item="item"
        :fields="fields"
        @remove="removeField($event)"
      />
      <p v-if="payload.length <= 0" class="searchFilter-empty">{{ $t("massEditDialog.empty") }}</p>
    </form-section>
    <form-section>
      <button @click.prevent="addField">
        <fa-icon name="plus" class="mr-2" />
        {{ $t("massEditDialog.add") }}
      </button>
    </form-section>
  </form-dialog>
</template>

<script>
import MassEditItem from "./MassEditItem.vue"
import { snakeCase } from "@/utils/with-case"

export default {
  components: { MassEditItem },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      payload: [{ key: Date.now(), field: null, value: null }],
    }
  },
  methods: {
    submit() {
      const { selected: resourceIds, resourceType, payload: params } = this
      let payload = {}
      params.forEach(n => {
        if (!n.field) return
        const definition = this.fields.find(f => f.id == n.field)
        if (n.field.match(/^partial_custom_fields__/)) {
          payload.partial_custom_fields = payload.partial_custom_fields || {}
          payload.partial_custom_fields[n.field.replace("partial_custom_fields__", "")] = n.value
        } else if (typeof definition.resolve === "function") {
          payload = definition.resolve(payload, n.value)
        } else {
          payload[snakeCase(n.field)] = n.value
        }
      })

      this.submitting = true
      this.$api
        .mutation("massEdit", {
          resourceIds: resourceIds.map(Number),
          resourceType,
          payload,
        })
        .then(() => {
          this.payload = [{ key: Date.now(), field: null, value: null }]
          this.$emit("submitted")
        })
        .finally(_ => {
          this.submitting = false
        })
    },
    addField() {
      this.payload = this.payload.concat({ key: Date.now(), field: null, value: null })
    },
    removeField(key) {
      // this.selectedSmartView = null
      this.payload = this.payload.filter(f => key !== f.key)
    },
  },
}
</script>
