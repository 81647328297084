<template>
  <span v-if="today">{{ $customFilters.time(datetime) }}</span>
  <tooltip v-else :content="$customFilters.time(datetime) + ' Uhr'">
    <span>{{ $customFilters.date(datetime) }}</span>
  </tooltip>
</template>

<script>
export default {
  props: ["datetime"],
  computed: {
    today() {
      return this.$customFilters.date(this.datetime) == this.$customFilters.date(moment(Date.now()))
    },
  },
}
</script>