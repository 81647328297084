<template>
  <div class="flex">
    <div
      v-for="(tile, i) in dataFormatted"
      :key="tile.key"
      class="rounded-md p-3 border border-black flex flex-col text-center mr-1"
      style="width: 150px"
      @click="handleClick(tile, i)"
    >
      <span class="text-xl font-bold mb-1 truncate text-blue-500">{{ tile.dataFormatted }}</span>
      <span class="font-semibold text-xs">{{ tile.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    options: {
      type: Object,
    },
    clickHandler: {
      type: Function,
    },
  },
  computed: {
    dataFormatted() {
      return (this.data?.series?.data || this.data?.series || []).map((s, i) => {
        return {
          key: s === "object" ? s.name : this.data.labels?.[i]?.id || this.data.labels?.[i],
          label: s === "object" ? s.name : this.data.labels?.[i]?.name || this.data.labels?.[i],
          data: s === "object" ? s.data[0] : s,
          dataFormatted:
            s === "object"
              ? s.data.map(d => this.options.labelInterpolationFnc?.(d) || d)[0]
              : this.options.labelInterpolationFnc?.(s) || s,
        }
      })
    },
  },
  methods: {
    handleClick({ key }, index) {
      this.clickHandler?.({ index, value: { x: key, y: undefined } })
    },
  },
}
</script>
