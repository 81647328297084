<template>
  <div v-bind="attrs" class="psTable-cell" :style="{ width: props.width + 'px' }">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useAttrs } from "vue"

const attrs = useAttrs()

const props = defineProps({
  width: Number,
})
</script>
