<template>
  <div v-if="url" class="app-panel">
    <page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
    </page-header>
    <div class="app-panel-body flex justify-center">
      <iframe :src="url" height="100%" min-height="700" width="550" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: { type: String },
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>
