type RouteStateEntryConfig = {
  persistOn: String
}
type RouteState = Record<string, { value: any; config: RouteStateEntryConfig }>

const createRouteStoreState = () => {
  let current: RouteState = {}
  let next: RouteState = {}

  const set = <T = any>(key: string, value: T, config: RouteStateEntryConfig) => (next[key] = { value, config })
  const get = <T = any>(key: string) => current[key]?.value as T
  const tick = (to, from) => {
    current = Object.assign(
      {},
      Object.entries(current)
        .filter(([_, { config }]) => to.path.startsWith(config.persistOn))
        .reduce((agg, [key, val]) => ({ ...agg, [key]: val }), {}),
      next
    )
    next = {}
  }
  return { set, get, tick }
}

export default createRouteStoreState()
