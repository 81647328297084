<template>
  <span class="star-rating">
    <fa-icon v-for="idx in rating" :key="'r' + idx" name="star" class="active" />
    <fa-icon v-for="idx in 3 - rating" :key="'n' + idx" name="star" />
  </span>
</template>

<script>
export default {
  props: ["rating"],
}
</script>

<style scoped>
.star-rating {
  color: #999;
}
.star-rating > *:not(:first-child) {
  margin-left: 4px;
}

.star-rating .active {
  color: #ffbb00;
}
</style>