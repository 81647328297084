<template>
  <form-section :title="title">
    <div>
      <ul>
        <li v-for="(recipe, i) in item.recipes" :key="i">
          <form-section class="p-3 border border-gray-200 mt-3 bg-gray-50 rounded" v-show="!recipe._destroy">
            <form-row :title="$t('activityTypes.todoActions.actionType')">
              <nice-select v-model="recipe.actionType" :options="actionTypes" />
            </form-row>
            <template v-if="recipe.actionType == 'create_todo' || recipe.actionType == 'create_decision'">
              <form-row key="todo-category" :title="$t('activityTypes.todoActions.category')" v-if="recipe.actionType == 'create_todo'">
                <db-select v-model="recipe.actionOptions.note_type_id" collection="todoCategories" />
              </form-row>
              <form-row key="decision-category" :title="$t('activityTypes.todoActions.category')" v-if="recipe.actionType == 'create_decision'">
                <db-select v-model="recipe.actionOptions.decision_type_id" collection="decisionCategories" />
              </form-row>
              <form-row key="todo-broker" :title="$t('activityTypes.todoActions.assignedTo')">
                <div class="flex flex-col w-full items-center">
                  <nice-select
                    v-model="recipe.actionOptions.broker_id"
                    :options="brokers"
                    @change="recipe.actionOptions.department_id = null"
                  />
                  <span class="block py-2 text-gray-600 text-sm">{{ $t('activityTypes.todoActions.assignedToAlternate') }}</span>
                  <db-select
                    v-model="recipe.actionOptions.department_id"
                    collection="departments"
                    @change="recipe.actionOptions.broker_id = null"
                  />
                </div>
              </form-row>
              <form-row
                :title="$t('activityTypes.followupActivitiesItem.reviewerIds')"
                v-if="recipe.actionType == 'create_todo' && $db.extensionEnabled('task_review')"
              >
                <div class="flex flex-col w-full items-center">
                  <db-select
                    multiple
                    collection="activeBrokers"
                    v-model="recipe.actionOptions.reviewer_ids"
                    :filter-func="b => recipe.actionOptions.broker_id !== b.id"
                  />
                </div>
              </form-row>
              <form-row key="todo-due-date" :title="$t('activityTypes.todoActions.dueDate')">
                <nice-select v-model="recipe.actionOptions.due_date" :options="dueDates" />
              </form-row>
            </template>
            <template v-else-if="recipe.actionType == 'create_message'">
              <form-row key="message-broker" :title="$t('activityTypes.todoActions.message.sender')">
                <nice-select v-model="recipe.actionOptions.broker_id" :options="brokers" />
              </form-row>
              <form-row key="message-snippet" :title="$t('activityTypes.todoActions.message.snippet')">
                <snippet-select v-model="recipe.actionOptions.snippet_id" :placeholder="$t('activityTypes.todoActions.message.snippetPlaceholder')"/>
              </form-row>
              <form-row key="message-recipient" :title="$t('activityTypes.todoActions.message.recipient')">
                <nice-select v-model="recipe.actionOptions.to" :options="messageRecipients" />
              </form-row>
              <form-row
                key="message-recipient"
                :title="$t('activityTypes.todoActions.message.referencedContact')"
                :hint="$t('activityTypes.todoActions.message.referencedContactHint')"
              >
                <nice-select v-model="recipe.actionOptions.referenced_client_id" :options="messageReferenceContacts" />
              </form-row>
              <form-row key="message-due-date" :title="$t('activityTypes.todoActions.message.sendingDueDate')">
                <nice-select v-model="recipe.actionOptions.send_at" :options="dueDates" />
              </form-row>
            </template>
            <div class="d-flex justify-content-end mt-2">
              <a @click.prevent="remove(item, recipe, i)" class="d-block text-danger">
                <fa-icon name="times" />
                <span class="ml-2">{{ $t('activityTypes.todoActions.remove') }}</span>
              </a>
            </div>
          </form-section>
        </li>
      </ul>
      <a @click="item.recipes.push({ actionType: 'create_todo', actionOptions: {} })" class="d-block mt-2">
        <fa-icon name="plus" />
        <span class="ml-2">{{ $t('activityTypes.todoActions.add') }}</span>
      </a>
    </div>
  </form-section>
</template>

<script>
import { MOMENTS, getBrokerOptions } from "@/config/followup-activity"

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    remove(item, recipe, index) {
      if (recipe.id) {
        recipe._destroy = true
      } else {
        item.recipes = item.recipes.filter((r, idx) => idx != index)
      }
    },
  },
  computed: {
    brokers() {
      let options = getBrokerOptions(this.$db.shopData, this.$t)
      // options.splice(2, 0, { name: "Projekt-Ansprechpartner", id: "{{ project__broker_id }}" })
      if (this.type == "deal") {
        options.splice(3, 0, { name: "dealBroker", id: "{{ broker_id }}", translate: true })
        options.splice(3, 0, { name: "dealEditor", id: "{{ updater_id }}", translate: true })
      }
      options = options.map(m => ({
        id: m.id,
        name: m.translate ? this.$t(`activityTypes.followupActivity.brokers.${m.name}`) : m.name,
      }))
      return options
    },
    dueDates() {
      const mom = MOMENTS.map(m => ({
        id: m.value,
        name: this.$t(`activityTypes.followupActivity.moments.${m.label}`, { days: m.days }),
      }))
      return mom
    },
    messageRecipients() {
      const DEFAULT_RECIPIENTS = [
        { id: "{{ client__email }}", name: this.$t('activityTypes.todoActions.message.defaultRecipients.clientEmail') },
        { id: "{{ property__owner__email }}", name: this.$t('activityTypes.todoActions.message.defaultRecipients.propertyOwnerEmail') }
      ]

      return DEFAULT_RECIPIENTS.concat(
        this.$db.shopData.relationshipPartnerNames.map(r => ({
          id: `{{ property__rs__${r.id}__email }}`,
          name: `Objekt → ${r.name}`,
        }))
      )
    },
    messageReferenceContacts() {
      const DEFAULT_REFERENCE_CONTACTS = [
        { id: "{{ client__id }}", name: this.$t('activityTypes.todoActions.message.defaultReferenceContacts.clientId') },
        { id: "{{ property__owner__id }}", name: this.$t('activityTypes.todoActions.message.defaultReferenceContacts.propertyOwnerId') }
      ]

      return DEFAULT_REFERENCE_CONTACTS.concat(
        this.$db.shopData.relationshipPartnerNames.map(r => ({
          id: `{{ property__rs__${r.id}__id }}`,
          name: `Objekt → ${r.name}`,
        }))
      )
    },
    actionTypes() {
      return { create_todo: this.$t("activityTypes.todoActions.actionTypes.create_todo"), create_message: this.$t("activityTypes.todoActions.actionTypes.create_message"), create_decision: this.$t("activityTypes.todoActions.actionTypes.create_decision") }
    },
  },
}
</script>
