<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({ setup() {} })
</script>
<script setup lang="ts">
const emit = defineEmits(["click"])
const props = withDefaults(defineProps<{ disabled?: boolean }>(), { disabled: false })
// https://learn.microsoft.com/en-us/entra/identity-platform/howto-add-branding-in-apps
</script>
<template>
  <nice-button class="msi-button" @click="emit('click')" :disabled="props.disabled">
    <div class="msi-button-state"></div>
    <div class="msi-button-content-wrapper">
      <div class="msi-button-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
          <rect x="1" y="1" width="9" height="9" fill="#f25022" />
          <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
          <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
          <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
        </svg>
      </div>
      <span class="msi-button-contents">
        {{ $t("admin.connectInboxModal.connectTo", { provider: "Microsoft" }) }}
      </span>
    </div>
  </nice-button>
</template>
<style scoped>
@font-face {
  font-family: SegoeUI;
  src: local("Segoe UI"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2) format("woff2"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff) format("woff"),
    url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf) format("truetype");
  font-weight: 400;
}

.msi-button {
  background-color: WHITE;
  background-image: none;
  border: 1px solid #8c8c8c;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #5e5e5e;
  cursor: pointer;
  font-family: "SegoeUI", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.msi-button .msi-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.msi-button .msi-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.msi-button .msi-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "SegoeUI", arial, sans-serif;
  font-weight: 600;
  text-overflow: ellipsis;
  vertical-align: top;
}

.msi-button .msi-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.msi-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.msi-button:disabled .msi-button-contents {
  opacity: 38%;
}

.msi-button:disabled .msi-button-icon {
  opacity: 38%;
}

.msi-button:not(:disabled):active .msi-button-state,
.msi-button:not(:disabled):focus .msi-button-state {
  background-color: #303030;
  opacity: 12%;
}

.msi-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.msi-button:not(:disabled):hover .msi-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>
