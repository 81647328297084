import CustomColumnMixin from "../../mixins/custom-columns"

export const DEFAULT_COLUMNS = [
  {
    name: "name",
    width: 250,
  },
]

export const formatClientRow = (c, options) => {
  const groups = (options.clientGroups || []).filter(o => c.group_ids?.includes(parseInt(o.id))).map(o => o.name)
  const projects = (options.projects || []).filter(o => c.project_ids?.includes(parseInt(o.id))).map(o => o.name)

  const validated_contact_data = []
  if (c.home_address_validated) {
    validated_contact_data.push("Anschrift")
  }
  if (c.email_validated) {
    validated_contact_data.push("E-Mail")
  }
  if (c.phone_number_validated) {
    validated_contact_data.push("Telefonnummer")
  }

  const invalidated_contact_data = []
  if (c.home_address_validated === false) {
    invalidated_contact_data.push("Anschrift")
  }
  if (c.email_invalidated) {
    invalidated_contact_data.push("E-Mail")
  }
  if (c.phone_number_invalidated) {
    invalidated_contact_data.push("Telefonnummer")
  }

  return {
    ...c,
    path: c.url || `/contacts/clients/${c.id}`,
    clientSource: options.clientSources.find(o => c.client_source_id == o.id),
    broker: options.brokers.find(o => c.broker_id == o.id),
    creator: options.brokers.find(o => c.creator_id == o.id),
    clientReason: options.clientReasons.find(o => c.client_reason_id == o.id),
    team: options.teams.find(o => c.team_id == o.id),
    phone: c.phone || (c.phone_numbers && c.phone_numbers.length > 0 ? c.phone_numbers[0] : undefined),
    groups,
    projects,
    contact_data_validated: c.home_address_validated || c.phone_number_validated || c.email_validated,
    validated_contact_data: `Gültig: ${validated_contact_data.join(", ")}`,
    invalidated_contact_data: `Ungültig: ${invalidated_contact_data.join(", ")}`,
    children_name: c.children_name || [],
    rating: c.rating || [],
  }
}

const BROKER_ATTRIBUTE_FOR_STORING_COLUMNS_CONFIG = "customColumnsForClients"

export const CustomClientColumnsMixin = CustomColumnMixin(BROKER_ATTRIBUTE_FOR_STORING_COLUMNS_CONFIG)

export const SORT_OPTIONS = [
  { value: "last_contact_at" },
  { value: "item_id.raw" },
  { value: "first_name.raw" },
  { value: "last_name.raw" },
  { value: "company.raw", fieldName: "company" },
  { value: "emails.raw", fieldName: "email" },
  { value: "created_at" },
  { value: "updated_at" },
  { value: "followup_date" },
  { value: "zip_code.keyword" },
  { value: "birthday", fieldName: "dob" },
  { value: "broker_changed_at" },
  { value: "rating" },
  { value: "accept_contact" },
  { value: "gdpr_status" },
]

export const ALLOWED_HEADERS = [
  "Propstack-ID",
  "Datensatznummer",
  "Kundennummer",
  "KdNr",
  "Anrede",
  "Titel",
  "Vorname",
  "Name",
  "Bemerkung",
  "Homepage",
  "Webseite",
  "Status",
  "Betreuer-ID",
  "Betreuer E-Mail",
  "Sprache",
  "Erstellt am",
  "Archiviert",
  "Email",
  "Email1",
  "Email2",
  "E-Mail",
  "Telefon",
  "Telefonnummer",
  "Fax",
  "Handy",
  "Mobil",
  "Telefonnummern",
  "Anschrift",
  "Straße",
  "Hausnummer",
  "PLZ",
  "Ort",
  "Land",
  "Land ISO",
  "Telefon Büro",
  "Mobil Büro",
  "Fax Büro",
  "Anschrift Büro",
  "Straße Büro",
  "Hausnummer Büro",
  "PLZ Büro",
  "Ort Büro",
  "Land Büro",
  "Geburtsdatum",
  "Geburtsname",
  "Geburtsname2",
  "Anrede in der E-Mail",
  "Quelle",
  "Merkmale",
  "Merkmal 1",
  "Merkmal 2",
  "Merkmal 3",
  "Firma",
  "Unternehmen",
  "Position",
  "Briefanrede",
  "Newsletter",
  "Warnhinweis",
  "Letzter Kontakt",
  "Anrede2",
  "Titel2",
  "Vorname2",
  "Name2",
  "Geburtsdatum2",
  "Straße2",
  "Hausnummer2",
  "PLZ2",
  "Ort2",
  "Land2",
  "Land ISO2",
  "Handy2",
  "Telefon2",
  "Firma2",
  "Suche Miete oder Kauf",
  "Suche Immobilientyp",
  "Suche Immobilienart",
  "Suche Geolagen",
  "Suche Städte",
  "Suche Regionen",
  "Suche Aufzug",
  "Suche Balkon",
  "Suche EBK",
  "Suche Garten",
  "Suche Keller",
  "Suche Vermietet",
  "Suche Notiz",
  "Suche Wohnfläche von",
  "Suche Wohnfläche bis",
  "Suche Anzahl Zimmer von",
  "Suche Anzahl Zimmer bis",
  "Suche Anzahl Schlafzimmer von",
  "Suche Anzahl Schlafzimmer bis",
  "Suche Kaufpreis von",
  "Suche Kaufpreis bis",
  "Suche Kaltmiete von",
  "Suche Kaltmiete bis",
  "Suche Warmmiete von",
  "Suche Warmmiete bis",

  "Deal-Objekt-ID",
  "Deal-Pipeline",
  "Deal-Phase",
  "Deal-Benutzer-ID",
  "Deal-Datum",
  "Deal-Preis",

  "DSGVO_session",
  "Mietbeginn",

  "Firma Zusatz",
  "Plz",
  "Eintragsdatum",
  "Betreuer",
  "Kontaktart",
  "AGB akzeptiert",
  "Speichern-bis-Datum",
  "Speichern-bis-Grund",
  "DSGVO-Status",
  "Staatsangehörigkeit",
  "Geburtsort",
  "Telefon1",
  "Telefon3",
  "Telefon4",
  "Telefax1",
  "Gewerblicher Kontakt",
  "Kontakterlaubnis erteilt",
]

export const FOLLOWUP_DATES = [
  { label: "tmr", value: 1 },
  { label: "threeD", value: 3 },
  { label: "oneW", value: 7 },
  { label: "twoW", value: 14 },
  { label: "oneM", value: 30 },
  { label: "twoM", value: 60 },
  { label: "sixM", value: 180 },
  { label: "oneY", value: 365 },
  { label: "never", value: 1000000 },
]
