<template>
  <form-dialog
    :title="$t('activities.massDelete.title', { length: selected.filter(o => o.activatable_type != 'Policy').length })"
    :visible="visible"
    width="450px"
    :show-footer="false"
    @close="$emit('update:visible', false)"
  >
    <form ref="form" @submit.prevent="submit" class="mb-4 -mt-2">
      {{
        `${this.$t("activities.massDelete.desc")} ${
          this.selected.length - this.selected.filter(o => o.activatable_type != "Policy").length
            ? this.$t("activities.massDelete.desc2", {
                length: this.selected.length - this.selected.filter(o => o.activatable_type != "Policy").length,
              })
            : ""
        }`
      }}
    </form>
    <div class="text-right -mb-4">
      <nice-button @click="$emit('update:visible', false)">{{ $t("formDialog.backBtn") }}</nice-button>
      <nice-button type="danger" :loading="deleting" @click="massDeleteActivites">
        {{ $t("activities.massDelete.submit") }}
      </nice-button>
    </div>
  </form-dialog>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
    }
  },
  methods: {
    massDeleteActivites() {
      this.deleting = true
      const filteredSelected = this.selected.filter(o => o.activatable_type != "Policy")
      if (!filteredSelected.length) {
        this.deleting = false
        this.$emit("update:visible", false)
        return App.alert(this.$t("activities.deleteWarning"))
      }

      this.$api
        .mutation("massDelete", {
          resourceType: "activity",
          resourceIds: filteredSelected.map(o => o.id),
        })
        .then(_ => {
          App.flashy(this.$t("activities.massDelete.success"))
        })
        .catch(this.$axios.handleError)
        .finally(() => {
          this.deleting = false
          this.$emit("submitted")
        })
    },
  },
}
</script>
