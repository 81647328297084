<template>
  <section class="todoActivity">
    <aside class="mr-3">
      <span class="todoActivity-counter">{{ step }}</span>
    </aside>
    <div class="w-100">
      <header class="todoActivity-header">
        <strong class="todoActivity-title mr-1">{{ ta.label }}</strong>
        <input type="checkbox" v-if="!ta.mandatory" v-model="ta.execute" />
      </header>
      <slot v-if="ta.execute" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    ta: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.todoActivity {
  padding: 0.5rem 0;
  display: flex;
}
.todoActivity-counter {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
.todoActivity-header {
  margin-top: 0.35rem;
  margin-bottom: 0.75rem;
}
.todoActivity-title {
  font-size: 1.1rem;
  font-weight: 500;
}
</style>