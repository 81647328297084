<template>
  <div class="flex flex-col items-center" :class="customClass">
    <fa-icon :name="icon" style="font-size: 50px" class="text-gray-700" />
    <p class="fs-15 mt-4 text-gray-700">
      {{ text }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
    },
  },
}
</script>