import { parse } from "date-fns"
import moment from "moment"
import { isDefined, isNumeric } from "../types"

/**
 * this file contains helpers to format dates specific to business use cases of our application.
 * see it as a facade over the date library (currently moment.js) -> move to date-fns
 */

export const getDayName = (index: number) => {
  return moment()
    .isoWeekday(index + 1)
    .format("dddd")
}

export const getCurrentTimestamp = () => parseInt(moment().format("X"))

export const dateToUnix = (date: Date) => moment(date).unix()
export const dateToTimestamp = (date: Date) => Math.floor(date.getTime() / 1000)

export const unixToDate = (seconds: number) => moment.unix(seconds).toDate()
export const timestampToDate = (milliseconds: number | string) => unixToDate(timestampToUnix(milliseconds))
export const timestampToUnix = (milliseconds: number | string) => Math.floor(+milliseconds / 1000)
export const timestampToDays = (milliseconds: number | string) => Math.floor(+milliseconds / 1000 / 60 / 60 / 24)
export const timestampToMonths = (milliseconds: number | string) => timestampToDays(milliseconds) / 28

export const getDateDiff = (date1: Date, date2: Date, unit: "seconds" | "minutes" | "hours") => {
  return moment(date1).diff(date2, unit)
}

export const getAllMonths = () => {
  return moment.months("MMMM")
}

export const endOfDay = (date: Date) => {
  return moment(date).endOf("day").toDate()
}

export const formatFullDateTime = (date: Date) => moment(date).format("DD.MM.YYYY HH:mm")

export const TIME_FORMAT = "LT"
export const DATE_FORMAT = "L"
export const DATE_TIME_FORMAT = "L LT"

export const unixToDateFormat = (seconds: number): string => moment.unix(seconds).format(DATE_FORMAT)
export const unixToTimeFormat = (seconds: number): string => moment.unix(seconds).format(TIME_FORMAT)
export const unixToDateTimeFormat = (seconds: number): string => moment.unix(seconds).format(DATE_TIME_FORMAT)

export const isOlderThan = (seconds: number, hours: number) =>
  moment(new Date()).diff(moment.unix(seconds), "hours") >= hours

export const valueToUnix = (value: string | number | undefined) =>
  isDefined(value) && isNumeric(value) ? Math.floor(Number(value) / 1000) : undefined

export const valueToTimestampMS = (value: number | undefined) => (isDefined(value) ? value * 1000 : undefined)

export const formatDate = (date: Date, format?: string) => moment(date).format(format ?? DATE_TIME_FORMAT)

export const unixMiliSecondsToDate = (unixMiliSecondsTimestamp?: number) => {
  try {
    const parsedNumber = Number(unixMiliSecondsTimestamp)
    if (isNaN(parsedNumber)) {
      return undefined
    }
    const unixSecondsTimestamp = Math.round(parsedNumber / 1000)
    return parse(String(unixSecondsTimestamp), "t", new Date())
  } catch (e) {
    console.error(e)
    return undefined
  }
}
