import i18n from "@/i18n"
import { Inbox } from "@/interfaces"
import { FolderID, InboxID, InboxMenuItemConfigMap, InboxNavigationItem } from "./inbox.types"

export const isDefaultFolderId = (id: string) => {
  return id.match(/^%.+%$/)
}

const DEFAULT_FOLDERS: InboxNavigationItem[] = [
  {
    id: "%inbox%",
    path: "inbox",
    icon: "fal fa-envelope",
    iconActive: "fal fa-envelope-open",
  },
  {
    id: "%sent%",
    path: "sent",
    icon: "fal fa-paper-plane",
  },
  {
    id: "%spam%",
    path: "spam",
    icon: "fal fa-exclamation-triangle",
  },
  {
    id: "%archive%",
    path: "archive",
    icon: "fal fa-archive",
  },
  {
    id: "%trash%",
    path: "trash",
    icon: "fal fa-trash-alt",
  },
  {
    id: "%draft%",
    path: "draft",
    icon: "fal fa-file",
  },
]

export function deleteChildWalk(folder: InboxNavigationItem, maxDepth: number, depth: number) {
  if (depth >= maxDepth) {
    folder.child = undefined
  }
  if (folder.child) {
    folder.child.forEach(child => {
      deleteChildWalk(child, maxDepth, depth + 1)
    })
  }
}

// Helper function to recursively add children to a folder
const addChildrenToFolder = (folder: InboxNavigationItem, folderMap: Map<FolderID, InboxNavigationItem>) => {
  if (!folder.childIds?.length) {
    return
  }

  folder.childIds.forEach(childId => {
    if (!folderMap.has(childId)) {
      return
    }

    const child = folderMap.get(childId)!
    folder.child ||= []

    // it can happen that a child is already in the folder, so we don't add it again
    if (!folder.child.find(c => c.id == childId)) {
      folder.child.push(child)
    }

    addChildrenToFolder(child, folderMap)
  })
}

// creates map of folders for each inbox referencing by childId
const createInboxFolderMap = (inbox: Inbox) => {
  const mailboxFoldersAsMenuItems =
    inbox.mailboxFolders?.map(f => ({ ...f, id: `${f.id}` } as InboxNavigationItem)) ?? []

  const folders: InboxNavigationItem[] = DEFAULT_FOLDERS.concat(mailboxFoldersAsMenuItems)

  const folderMap: Map<InboxID, InboxNavigationItem> = new Map()

  folders.forEach(folder => {
    // clone is important otherwise we save references to other folders
    folderMap.set(folder.id, structuredClone(folder))
  })

  // first pass: assign all childIds
  for (const [folderID, folder] of folderMap) {
    if (folder.parentId && folderMap.has(folder.parentId)) {
      const parent = folderMap.get(folder.parentId)!
      parent.childIds ||= []
      parent.childIds.push(folderID)
    }
  }

  // knowing the number of children, we can assign all necessary data
  for (const [folderID, folder] of folderMap) {
    // it is not yet known what the correct language is, so this will need to be recalculated later
    folder.title = folder.label || i18n.global.t(`sidebar.${folder["path"]}`)
    folder.href = `/mailbox/messages/${folder.path}`
    if (!isDefaultFolderId(folderID)) {
      if (folder.childIds?.length) {
        folder.icon ||= "fal fa-chevron-right"
        folder.iconActive ||= "fal fa-chevron-down"
      } else {
        folder.icon ||= "fal fa-folder"
      }
    }
  }

  // add children and remove top level folders that have a parent
  for (const [_, folder] of folderMap) {
    if (folder.childIds?.length) {
      addChildrenToFolder(folder, folderMap)
    }
    if (folder.parentId) {
      folderMap.delete(folder.id)
    }
  }

  return folderMap
}

// add children and remove top level folders that have a parent
const createFolderTreeFromMap = (
  folderMap: Map<string, InboxNavigationItem>,
  maxDepth = 0
): Map<string, InboxNavigationItem> => {
  const f = new Map(folderMap) // clone map
  for (const [_, folder] of f) {
    if (folder.childIds?.length) {
      addChildrenToFolder(folder, f)
    }
    if (folder.parentId) {
      f.delete(folder.id)
    }
  }

  // delete all children that are deeper than maxDepth
  if (maxDepth > 0) {
    for (const [_, folder] of folderMap) {
      deleteChildWalk(folder, maxDepth, 1)
    }
  }
  return f
}

// creates a map of folders for each inbox with each folder tree
export const calculateInboxMenuItems = (inboxes: Inbox[], _locale?: string, maxDepth = 0): InboxMenuItemConfigMap => {
  // i18n.locale = locale

  const inboxesWithDefault = inboxes.concat([{ id: "default" } as unknown as Inbox])

  const menuItems = inboxesWithDefault.reduce((inboxConfigs, currentInbox) => {
    // a map of folders for each inbox with each folder containing childIds as references to other folders
    const folderMap = createInboxFolderMap(currentInbox)
    const folderTree = createFolderTreeFromMap(folderMap, maxDepth)

    // get the custom folder roots
    const customFolderRoots = Array.from(folderTree.values()).filter(
      folder => !folder.parentId && !isDefaultFolderId(folder.id)
    )

    // aggregate all the folder inboxes
    inboxConfigs[`${currentInbox.id}`] = {
      folderMap: Object.fromEntries(folderMap),
      customFolderRoots,
      folderTree: Object.fromEntries(folderTree),
    }

    return inboxConfigs
  }, {} as InboxMenuItemConfigMap)

  return menuItems
}
