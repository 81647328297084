<template>
  <div v-if="loading" class="w-full h-full flex justify-center items-center">
    <spinner />
  </div>
  <div v-else class="app-panel">
    <slot name="header">
      <page-header v-if="title" :title="title" />
    </slot>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
