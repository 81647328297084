export const getTaskType = ({
  isReminder,
  isEvent,
  isNote,
  taskType,
}: {
  isReminder: boolean
  isEvent: boolean
  isNote: boolean
  taskType: string
}) => {
  if (taskType == "letter") return "letter"
  if (taskType == "decision") return "decision"
  if (isReminder) return "todo"
  if (isEvent) return "event"
  if (isNote) return "note"
  return "inquiry"
}
