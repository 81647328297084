import { IntegrationOrderStepMcGrundriss } from "../types/integration-mcgrundriss"
import { IntegrationOrderStepDescriptor, PartialIntegrationOrderStepConfiguration } from "../types/integration-order"

export const defaultStepConfiguration = {
  action: {
    // type: "edit",
    // variant: "neutral",
    // onClick: order => {
    //   const linkOut = order.context?.linkOut
    //   if (linkOut) {
    //     window.open(linkOut, "_blank", "noopener noreferrer")
    //   }
    // },
  },
} as unknown as IntegrationOrderStepDescriptor

const stepConfiguration = {
  started: {
    action: {}, // will take over all defaults
  },
  confirmed: {
    action: {},
  },
  "in-progress": {
    action: {},
  },
  completed: {
    action: {
      // will show a request change button, with the same action as the default
      type: "requestChange",
    },
  },
  "clarification-required": {
    action: {
      type: "feedback",
      variant: "ghost",
    },
  },
  "changes-reported": {
    action: {},
  },
  "changes-in-progress": {
    action: {},
  },
  cancelled: {
    action: undefined,
  },
}

export default {
  stepConfiguration,
  defaultStepConfiguration,
} as PartialIntegrationOrderStepConfiguration<IntegrationOrderStepMcGrundriss>
