<template>
  <div v-if="resource.allSuggestedEvents">
    <p class="mb-2">
      <strong>{{ $t("task.suggestedEvents.title") }}</strong>
    </p>
    <p class="mb-2" v-for="item in resource.allSuggestedEvents" :key="item.id">
      {{ $customFilters.datetime(item.startsAt) }}:
      <a href="#" @click.prevent="confirm(item)" class="text-green-600 pull-right semi-bold">
        {{ $t("task.suggestedEvents.accept") }}
      </a>
    </p>
    <p>
      {{ $t("task.or") }}
      <a href="#" @click.prevent="cancel" class="text-red-600 semi-bold">{{ $t("task.suggestedEvents.declineAll") }}</a>
    </p>
  </div>
  <div v-else>
    <!-- public booking calendar with only 1 option -->
    <p class="mb-2">
      <strong>{{ $t("task.suggestedEvents.titleAlt") }}</strong>
    </p>
    <p class="mb-2">
      {{ $customFilters.datetime(resource.startsAt) }}:
      <a href="#" @click.prevent="confirm(resource)" class="text-green-600 pull-right semi-bold">
        {{ $t("task.suggestedEvents.accept") }}
      </a>
    </p>
    <p>
      {{ $t("task.or") }}
      <a href="#" @click.prevent="cancel" class="text-red-600 semi-bold">{{ $t("task.suggestedEvents.decline") }}</a>
    </p>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {},
  },
  methods: {
    confirm(event) {
      this.$axios
        .put(`/services/suggested_events/${event.id}`)
        .then(() => {
          App.flashy(this.$t("task.suggestedEvents.acceptSuccess"))
          eventBus.$emit("quick-view", {
            type: "message",
            mode: "edit",
            params: { source: { suggestedEventId: event.id } },
          })
        })
        .catch(this.$axios.handleError)
    },
    cancel() {
      this.$warn(
        {
          title: this.$t("task.suggestedEvents.cancel.title"),
          desc: this.$t("task.suggestedEvents.cancel.desc"),
          confirmButtonText: this.$t("task.suggestedEvents.cancel.submit"),
        },
        () => {
          this.$axios
            .delete(`/services/suggested_events/${this.resource.id}`)
            .then(() => {
              App.flashy(this.$t("task.suggestedEvents.cancel.success"))
              eventBus.$emit("quick-view", {
                type: "message",
                mode: "edit",
                params: {
                  source: {
                    clientIds: this.resource.clients.map(o => o.id),
                    propertyIds: this.resource.properties.map(o => o.id),
                    projectIds: this.resource.projects.map(o => o.id),
                  },
                },
              })
            })
            .catch(this.$axios.handleError)
        }
      )
    },
  },
}
</script>