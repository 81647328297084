<template>
  <form-dialog
    :title="$t('project.mergeModal.title')"
    width="500px"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :submitButtonTitle="$t('project.mergeModal.submit')"
    @submit="confirmMerge"
    footerType="bigButton"
    :disabled="loading || !selected"
    :saving="loading"
    append-to-body
    :autofocus="false"
  >
    <form-section>
      <p class="mb-1">{{ $t("project.mergeModal.text1") }}</p>
      <form-row>
        <project-select v-model="selected" />
      </form-row>
      {{ $t("project.mergeModal.text2") }}
      <strong>{{ projectName }}</strong>
    </form-section>
  </form-dialog>
</template>

<script>
export default {
  props: ["visible", "projectId", "projectName"],

  data() {
    return {
      loading: false,
      selected: undefined,
    }
  },

  methods: {
    confirmMerge() {
      this.loading = true

      this.$axios
        .post(`/portfolio/projects/${this.projectId}/merge`, {
          other_id: this.selected,
        })
        .then(() => {
          this.loading = false
          this.$emit("update:visible", false)
          App.flashy(this.$t("project.mergeModal.success"))
        })
        .catch(err => {
          this.loading = false
          this.$axios.handleError(err)
        })
    },
  },
}
</script>
