<template>
  <div id="mass-viewings-list">
    <p class="mb-1">
      <strong>{{ $t("task.viewings.participants") }}</strong>
      <tooltip
        v-if="resource.viewings.length < resource.possibleSpots.length"
        :content="$t('task.viewings.addParticipants')"
      >
        <a href="#" @click.prevent="dialogVisible = true"><fa-icon name="plus" /></a>
      </tooltip>
      <span class="pull-right" style="font-family: monospace">
        {{ `${resource.viewings.length}/${resource.possibleSpots.length}` }}
      </span>
    </p>
    <ul v-if="resource.viewings.length > 0">
      <viewing-item
        v-for="item in resource.viewings"
        :key="item.id"
        :item="item"
        :resource="resource"
        @remove="viewingRemoved"
        class="mb-2"
      />
    </ul>
    <p v-else class="light">
      <i>{{ $t("task.viewings.empty") }}</i>
    </p>
    <form-dialog
      :title="$t('task.viewings.addParticipants')"
      :visible="dialogVisible"
      @update:visible="!$event ? reset() : null"
      :submit-func="create"
      @complete="complete"
      width="600px"
      append-to-body
    >
      <form-section v-if="resource">
        <form-row :title="$t('task.viewings.participants')">
          <client-select v-model="form.clientId" />
        </form-row>
        <form-row :title="$t('task.viewings.timeframe')">
          <nice-select v-model="form.viewingDate" :options="viewingDateOptions" />
        </form-row>
      </form-section>
    </form-dialog>
  </div>
</template>

<script>
import ViewingItem from "./ViewingItem"

export default {
  props: {
    resource: {},
  },
  components: { ViewingItem },
  data() {
    return {
      dialogVisible: false,
      form: {
        clientId: null,
        viewingDate: null,
      },
    }
  },
  methods: {
    create() {
      return this.$axios.post(`/viewings`, {
        viewing: {
          task_id: this.resource.id,
          client_id: this.form.clientId,
          viewing_date: this.form.viewingDate,
        },
      })
    },
    reset() {
      this.dialogVisible = false
      this.form = { clientId: null, viewingDate: null }
    },
    async complete() {
      this.reset()
      const { task } = await this.$graphql(`{
        task(id: ${this.resource.id}) {
          viewings { id viewingDate phone email name clientId }
        }
      }`)
      this.resource.viewings = task.viewings
      App.flashy(this.$t("task.viewings.success"))
    },
    viewingRemoved(item) {
      this.resource.viewings = this.resource.viewings.filter(v => v.id != item.id)
    },
  },
  computed: {
    availablViewingDates() {
      const reservedSpots = this.resource.viewings.map(o => o.viewingDate)
      return _.uniq(this.resource.possibleSpots).filter(spotDate => {
        const occurencesInReservedSpots = _.countBy(reservedSpots)[spotDate] || 0
        return occurencesInReservedSpots < (this.resource.maxParticipantsPerSlot || 1)
      })
    },
    viewingDateOptions() {
      return this.availablViewingDates.map(spot => ({ name: `${this.$customFilters.time(spot)} Uhr`, id: spot }))
    },
  },
}
</script>