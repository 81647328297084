<script setup lang="ts">
import { computed, ref } from 'vue'
import fallback from '/assets/images/api-avatars/fallback.svg'

interface Props {
  category?: string
  size?: string
}

const { category, size } = withDefaults(defineProps<Props>(), {
  size: '26px',
})

const image = ref(fallback)

const style = computed(() => {
  return {
    width: size,
    height: size,
  }
})

import(`~/assets/images/api-avatars/${category}.svg`).then((file) => {
  file.default && (image.value = file.default)
}).catch(() => {
  image.value = fallback
})
</script>

<template>
  <div :style="style">
    <img :src="image" class="w-full" />
  </div>
</template>
