export const getCustomActivityColumns = db => [
  { name: "icon", description: "icon", title: "", width: 50, visible: true },
  { name: "title", width: 320, main: true, visible: true },
  { name: "attachments", description: "attachments", title: "", width: 50, visible: true },
  { name: "trackings", description: "trackings", title: "", width: 30, visible: true },
  { name: "client", width: 250, visible: true },
  { name: "category", width: 180, visible: true },
  { name: "date", width: 100, visible: true },
  { name: "broker", description: "broker", title: "", width: 50, visible: true },
  { name: "original_created_at", width: 100, visible: false },
  { name: "client_broker_ids", width: 130, visible: false },
]
