<template>
  <section :class="{ branch: isChild, lastChild }" :id="`item-${item.id}`">
    <main class="w-100 flex">
      <div :class="{ ['end-branch']: isChild }"></div>

      <div class="relative rounded border shadow-sm w-100" :class="'bg-white'">
        <component
          v-if="useComponent"
          @collapse="toggleCollapse"
          :class="'bg-white'"
          :is="useComponent"
          :item="item"
          :showActions="actions.length"
          :children="children"
          :search="search"
          :showNestedItems="showNestedItems"
          :sortable="sortable"
          @edit="editFunc(item)"
          @delete="deleteFunc(item)"
          @add="addFunc(item)"
          @multiplex="payload => $emit('multiplex', payload)"
        ></component>
        <template v-else>
          <div
            @click="toggleCollapse"
            class="px-3 py-2 text-dark standard-hover d-flex align-items-center"
            :class="{ pointer: actions.length && ((children && children.length) || sortable) }"
          >
            <span v-if="$slots.icon" class="mr-3 text-gray-600 text-xl">
              <nestable-list-icon
                :children="children"
                :search="search"
                :showNestedItems="showNestedItems"
                :item="item"
                :sortable="sortable"
              >
                <template v-slot:icon>
                  <slot name="icon" />
                </template>
              </nestable-list-icon>
            </span>
            <span class="txt-overflow w-full">
              <slot name="title">
                <span class="block font-medium">{{ title }}</span>
              </slot>
              <span class="block text-sm text-muted">
                <slot name="subtitle" />
              </span>
            </span>
          </div>

          <slot v-if="!useComponent" name="actions">
            <hover-actions>
              <hover-action v-if="actions.includes('add')" @click="addFunc(item)" icon="plus" />
              <hover-action v-if="actions.includes('edit')" @click="editFunc(item)" icon="pencil" />
              <hover-action
                v-if="actions.includes('delete')"
                @click="deleteFunc(item)"
                icon="trash-alt"
                class="text-danger"
              />
            </hover-actions>
          </slot>
        </template>
      </div>
    </main>
    <div
      v-if="((search && item.isMatch) || (showNestedItems && !search)) && ((children && children.length) || sortable)"
      class="mt-2"
      :class="{ 'ml-3': isChild }"
    >
      <component
        :is="'draggable'"
        :item-key="`${item.isFolder ? 'folder_' : 'id_'}${item.id}`"
        :list="children || []"
        :group="{ name: `g1` }"
        :undraggable="disableDragging"
        @change="$emit('update:value', children)"
        class="draggable-container"
      >
        <template #item="{ element, index }">
          <nestable-list-item
            :depth="depth + 1"
            :key="`${element.isFolder ? 'folder_' : 'id_'}${element.id}`"
            :add-func="addFunc"
            :edit-func="editFunc"
            :delete-func="deleteFunc"
            :get-actions-for-item="getActionsForItem"
            :children="element.children"
            :item="element"
            :sortable="sortable"
            is-child
            :initial-show-nested-items="initialShowNestedItems"
            :search="search"
            :useComponent="useComponent"
            :disableDragging="disableDragging"
            :lastChild="index === children.length - 1"
            @multiplex="payload => $emit('multiplex', payload)"
          >
            <template v-slot:icon>
              <slot name="icon" :item="element" />
            </template>
            <template v-slot:title>
              <slot name="name" :item="element">
                {{ element.name }}
              </slot>
            </template>
            <template v-slot:actions>
              <slot name="actions" :item="element" />
            </template>
          </nestable-list-item>
        </template>
      </component>
    </div>
  </section>
</template>

<script>
import NestableListIcon from "./NestableListIcon.vue"

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    getActionsForItem: {
      type: Function,
      default: _ => ["add", "edit", "delete"],
    },
    children: {
      type: Array,
      required: false,
    },
    addFunc: {
      type: Function,
      required: false,
    },
    editFunc: {
      type: Function,
      required: false,
    },
    deleteFunc: {
      type: Function,
      required: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
    initialShowNestedItems: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Number,
    },
    useComponent: {
      type: String,
    },
    disableDragging: {
      type: Boolean,
      default: false,
    },
    lastChild: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNestedItems: this.initialShowNestedItems,
    }
  },
  methods: {
    toggleCollapse() {
      this.showNestedItems = !this.showNestedItems
    },
  },
  computed: {
    actions() {
      return this.getActionsForItem(this.item, this.depth)
    },
  },
  components: {
    NestableListIcon,
  },
}
</script>

<style>
.end-branch {
  border-left: 2px solid rgb(201, 201, 201);
  border-bottom: 2px solid rgb(201, 201, 201);
  padding-left: 1rem;
  border-bottom-left-radius: 4px;
  height: 27px;
  margin-left: -2px;
  margin-top: -7px;
}

.branch {
  border-left: 2px solid rgb(201, 201, 201);
  margin-left: 1.25rem;
  padding-bottom: 0.5rem;
}
.sorting--active .branch {
  padding-bottom: 0;
}
.branch.lastChild {
  border-left: none;
  border-bottom: none;
  margin-left: 1.4rem;
  padding-bottom: 0;
}
.draggable-container {
  padding-bottom: 0.5rem;
}
</style>
