import moment from "moment"

const formatParams = (val, $db) => {
  let departments = $db.shopData.departments
  const vars = departments.reduce((a, v) => ({ ...a, [`{{ department_${v.id} }}`]: v.brokerIds }), {
    "{{ broker_id }}": $db.broker.id,
  })
  if (vars[val] && vars[val].length > 1) return vars[val]
  const filteredVal = Array.isArray(val) ? val.filter(v => v !== "empty") : val !== "empty" ? val : []
  return Array.isArray(filteredVal)
    ? filteredVal.map(v => vars[v] || v).flat()
    : vars[filteredVal].flat() || filteredVal.flat()
}

export const openTodosWithReviewStateQuery = (db, brokerIds, departmentIds = []) => ({
  should: [
    {
      bool: {
        must: [
          {
            bool: {
              should: [{ term: { done: false } }, { bool: { must_not: { exists: { field: "done" } } } }],
            },
          },
          !db.shopData.legacySearchServer && brokerIds.length
            ? {
                bool: {
                  should: [
                    { terms: { broker_id: brokerIds } },
                    { terms: { broker_ids: brokerIds } },
                    { terms: { reviewer_ids: brokerIds } },
                    departmentIds.length ? { terms: { department_id: departmentIds } } : undefined,
                  ].filter(Boolean),
                },
              }
            : undefined,
        ].filter(Boolean),
      },
    },
    !db.shopData.legacySearchServer && brokerIds.length
      ? {
          nested: {
            path: "todos",
            query: {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        { terms: { "todos.broker_id": brokerIds } },
                        departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
                      ].filter(Boolean),
                    },
                  },
                ],
                must_not: [{ exists: { field: "todos.done_at" } }],
              },
            },
          },
        }
      : undefined,
  ].filter(Boolean),
})

export const openTodosQuery = (db, brokerIds, departmentIds = [], noAssignee) => ({
  should: [
    {
      bool: {
        must: [
          {
            bool: {
              should: [{ term: { done: false } }, { bool: { must_not: { exists: { field: "done" } } } }],
            },
          },
          {
            bool: {
              should: [
                { term: { review_state: "rejected" } },
                { bool: { must_not: { exists: { field: "review_state" } } } },
              ],
            },
          },
          !db.shopData.legacySearchServer && brokerIds.length
            ? {
                bool: {
                  should: [
                    noAssignee
                      ? {
                          bool: {
                            must: [
                              { bool: { must_not: { exists: { field: "broker_id" } } } },
                              { bool: { must_not: { exists: { field: "department_id" } } } },
                            ],
                          },
                        }
                      : undefined,
                    { terms: { broker_id: brokerIds } },
                    { terms: { broker_ids: brokerIds } },
                    departmentIds.length ? { terms: { department_id: departmentIds } } : undefined,
                  ].filter(Boolean),
                },
              }
            : undefined,
        ].filter(Boolean),
      },
    },
    !db.shopData.legacySearchServer && brokerIds.length
      ? {
          nested: {
            path: "todos",
            query: {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        noAssignee
                          ? {
                              bool: {
                                must: [
                                  { bool: { must_not: { exists: { field: "broker_id" } } } },
                                  { bool: { must_not: { exists: { field: "department_id" } } } },
                                ],
                              },
                            }
                          : undefined,
                        { terms: { "todos.broker_id": brokerIds } },
                        departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
                      ].filter(Boolean),
                    },
                  },
                ],
                must_not: [{ exists: { field: "todos.done_at" } }],
              },
            },
          },
        }
      : undefined,
  ].filter(Boolean),
})

export const assignedTodosQuery = (db, brokerIds, departmentIds = [], noAssignee) => ({
  bool: {
    should: [
      noAssignee
        ? {
            bool: {
              must: [
                { bool: { must_not: { exists: { field: "broker_id" } } } },
                { bool: { must_not: { exists: { field: "department_id" } } } },
              ],
            },
          }
        : undefined,
      { terms: { broker_id: brokerIds } },
      departmentIds.length ? { terms: { department_id: departmentIds } } : undefined,
      db.shopData.legacySearchServer
        ? {
            bool: {
              should: [
                noAssignee
                  ? {
                      bool: {
                        must: [
                          { bool: { must_not: { exists: { field: "todos.broker_id" } } } },
                          { bool: { must_not: { exists: { field: "todos.department_id" } } } },
                        ],
                      },
                    }
                  : undefined,
                { terms: { "todos.broker_id": brokerIds } },
                departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
              ],
            },
          }
        : {
            bool: {
              must: [
                {
                  nested: {
                    path: "todos",
                    query: {
                      bool: {
                        should: [
                          noAssignee
                            ? {
                                bool: {
                                  must: [
                                    { bool: { must_not: { exists: { field: "todos.broker_id" } } } },
                                    { bool: { must_not: { exists: { field: "todos.department_id" } } } },
                                  ],
                                },
                              }
                            : undefined,
                          { terms: { "todos.broker_id": brokerIds } },
                          departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
                        ].filter(Boolean),
                      },
                    },
                  },
                },
              ],
            },
          },
    ].filter(Boolean),
  },
})

export const assignedOrSubscribedTodosQuery = (db, brokerIds, departmentIds = []) => ({
  bool: {
    should: [
      { terms: { broker_id: brokerIds } },
      { terms: { broker_ids: brokerIds } },
      { terms: { reviewer_ids: brokerIds } },
      departmentIds.length ? { terms: { department_id: departmentIds } } : undefined,
      db.shopData.legacySearchServer
        ? {
            bool: {
              should: [
                { terms: { "todos.broker_id": brokerIds } },
                departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
              ].filter(Boolean),
            },
          }
        : {
            bool: {
              must: [
                {
                  nested: {
                    path: "todos",
                    query: {
                      bool: {
                        should: [
                          { terms: { "todos.broker_id": brokerIds } },
                          departmentIds.length ? { terms: { "todos.department_id": departmentIds } } : undefined,
                        ].filter(Boolean),
                      },
                    },
                  },
                },
              ],
            },
          },
    ].filter(Boolean),
  },
})

export const formatPayloadToElastic = (payload, $db) => {
  let filters = [] as any
  const noAssignee = payload.brokerId.includes("empty")
  filters.push({ terms: { conversation_type: ["reminder", "decision"] } })
  switch (payload.type) {
    case "uncompleted":
      let assigneeBrokerIds = []
      if (payload.brokerId.length) assigneeBrokerIds = formatParams(payload.brokerId, $db)
      if (payload.brokers.length) assigneeBrokerIds = assigneeBrokerIds.concat(formatParams(payload.brokers, $db))
      const assigneeDepartmentIds = $db.shopData.departments
        .filter(d => assigneeBrokerIds.some(id => d.brokerIds.includes(id)))
        .map(d => d.id)

      filters.push({
        bool: openTodosQuery($db, assigneeBrokerIds, assigneeDepartmentIds, noAssignee),
      })
      break
    case "pending":
      filters.push({
        bool: {
          should: [{ term: { review_state: "pending" } }],
        },
      })
      break
    case "completed":
      filters.push({
        bool: {
          should: [{ term: { done: true } }],
        },
      })
      break
    case "all":
      break
  }
  switch (payload.isDue) {
    case "open":
      if ($db.shopData.legacySearchServer) {
        filters.push({
          bool: {
            should: [
              { range: { starts_at: { lte: moment().format() } } },
              { bool: { must_not: { exists: { field: "starts_at" } } } },
              {
                bool: {
                  must: [
                    { range: { "todos.due_date": { lte: moment().format() } } },
                    { term: { "todos.broker_id": $db.broker.id } },
                  ],
                  must_not: [{ exists: { field: "todos.done_at" } }],
                },
              },
            ],
          },
        })
      } else {
        filters.push({
          bool: {
            should: [
              { range: { starts_at: { lte: moment().format() } } },
              { bool: { must_not: { exists: { field: "starts_at" } } } },
              {
                nested: {
                  path: "todos",
                  query: {
                    bool: {
                      must: [
                        { range: { "todos.due_date": { lte: moment().format() } } },
                        { term: { "todos.broker_id": $db.broker.id } },
                      ],
                      must_not: [{ exists: { field: "todos.done_at" } }],
                    },
                  },
                },
              },
            ],
          },
        })
      }
      break
    case "upcoming":
      filters.push({ range: { starts_at: { gt: moment().format() } } })
      break
    case "none":
      filters.push({ bool: { must_not: { exists: { field: "starts_at" } } } })
      break
    case "all":
      break
  }

  if (payload.categories.length > 0) filters.push({ terms: { category_id: payload.categories } })
  if (payload.brokers.length > 0) {
    const brokerIds = formatParams(payload.brokers, $db)
    const departmentIds = $db.shopData.departments
      .filter(d => brokerIds.some(id => d.brokerIds.includes(id)))
      .map(d => d.id)
    filters.push(assignedOrSubscribedTodosQuery($db, brokerIds, departmentIds))
  }
  if (payload.brokerId.length > 0) {
    const brokerId = formatParams(payload.brokerId, $db)
    const departmentIds = $db.shopData.departments
      .filter(d => brokerId.some(id => d.brokerIds.includes(id)))
      .map(d => d.id)
    filters.push(assignedTodosQuery($db, brokerId, departmentIds, noAssignee))
  }
  if (payload.projects.length > 0) filters.push({ terms: { project_ids: payload.projects } })
  if (payload.clients.length > 0) filters.push({ terms: { client_ids: payload.clients } })
  if (payload.properties.length > 0) filters.push({ terms: { property_ids: payload.properties } })
  if (payload.group_ids.length > 0) filters.push({ terms: { group_ids: payload.group_ids } })
  if (payload.priority.length > 0) filters.push({ terms: { priority: payload.priority } })
  if (payload.hideDecisions) filters.push({ term: { conversation_type: "reminder" } })
  return {
    filter_set: {
      must: filters,
    },
  }
}
