import useCore from "@/plugins/use-core"
import { computed } from "vue"

export const ONE_SYSTEM_FRANCHISE_NAME = "OneSystem MASTER"

export const useOneSystem = () => {
  const { db } = useCore()

  const franchiseName = computed(() => db.shopData?.franchiserName)

  const isOneSystem = computed(() => franchiseName.value === ONE_SYSTEM_FRANCHISE_NAME)

  return {
    isOneSystem,
    franchiseName,
  }
}
