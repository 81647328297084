import { MaybeRef, get, set, toRefs, watchOnce } from "@vueuse/core"
import { ComputedGetter, Ref, ToRefs, UnwrapRef, computed, ref } from "vue"
import { isFunction } from "./types"

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never

type Primitive = string | symbol | number

// create an empty object for given keys
const scaffold = <TKeys extends Array<Primitive>>(keys: TKeys) =>
  Object.fromEntries(keys.map(key => [key, undefined])) as Record<ArrayElement<TKeys>, undefined>

// converts an object ref into property refs for given keys
export const toPropRefs = <T extends object, TKeys extends Array<keyof T>>(
  getter: ComputedGetter<T | undefined>,
  keys: TKeys
) => toRefs(computed(() => getter() ?? (scaffold(keys) as T))) as ToRefs<T>

type StateUpdate<T> = ((previousState: T) => T) | T | undefined
type StateUpdater<T> = (update: StateUpdate<T>) => T

// useState implementation
export const useState = <T>(initialValue: MaybeRef<T> | undefined = undefined) => {
  const state = ref(initialValue)
  const setState = (update: StateUpdate<T>) => {
    const nextState = (isFunction(update) ? (update as Function)(get(state)) : get(update)) as UnwrapRef<T>
    set(state, nextState)
    return nextState
  }
  return [state, setState] as [Ref<T>, StateUpdater<T>]
}

export const useDelayedState = <T>(initialValue: ComputedGetter<T>) => {
  const isGetter = isFunction(initialValue)
  const lazyValue = isGetter ? (initialValue as Function)() : undefined
  const [state, setState] = useState<T>(isGetter ? lazyValue : initialValue)
  if (isGetter && !lazyValue) watchOnce(initialValue as ComputedGetter<T>, newState => setState(newState as T))
  return [state, setState] as [Ref<T>, StateUpdater<T>]
}
