<template>
  <section class="mb-4">
    <main class="mb-2 mt-4 p-3 rounded border border-blue-100 bg-blue-50">
      <h5 class="leading-relaxed uppercase text-sm text-gray-600 mb-2">{{ $t("task.scoutInquiryDetails.title") }}</h5>
      <fieldset class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.contact") }}</span>
        <span>{{ salutation }} {{ resource.seekerPayload.first_name }} {{ resource.seekerPayload.last_name }}</span>
      </fieldset>
      <fieldset class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.email") }}</span>
        <span>{{ resource.seekerPayload.email }}</span>
      </fieldset>
      <fieldset class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.phone") }}</span>
        <span>{{ resource.seekerPayload.inquiry_phone_number || "–" }}</span>
      </fieldset>
      <fieldset class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.location") }}</span>
        <span v-if="resource.seekerPayload.home_street">
          {{ resource.seekerPayload.home_street }} {{ resource.seekerPayload.home_house_number }},
          {{ resource.seekerPayload.home_zip_code }} {{ resource.seekerPayload.home_city }}
        </span>
      </fieldset>
      <br />
      <h5 class="leading-relaxed uppercase text-sm text-gray-600 mb-2">{{ $t("task.scoutInquiryDetails.other") }}</h5>
      <fieldset v-if="additional.personalInformation.homeOwner" class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.owner") }}</span>
        <span>{{ $t("task.scoutInquiryDetails.yes") }}</span>
      </fieldset>
      <fieldset v-if="additional.propertyUsage && additional.propertyUsage.buyReason" class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.usage") }}</span>
        <span>{{ additional.propertyUsage.buyReason }}</span>
      </fieldset>
      <fieldset v-if="additional.propertyUsage && additional.propertyUsage.numberOfPersons" class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.area") }}</span>
        <span>{{ additional.propertyUsage.numberOfPersons }}</span>
      </fieldset>
      <fieldset
        v-if="additional.personalInformation.employment && additional.personalInformation.employment.type"
        class="mb-1 flex"
      >
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.employmentType") }}</span>
        <span>{{ additional.personalInformation.employment.type }}</span>
      </fieldset>
      <fieldset v-if="additional.householdIncome && additional.householdIncome.netIncomeRange" class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.income") }}</span>
        <span>{{ additional.householdIncome.netIncomeRange }}</span>
      </fieldset>
      <fieldset v-if="additional.ownCapital" class="mb-1 flex">
        <span style="width: 200px" class="font-medium">{{ $t("task.scoutInquiryDetails.ownCapital") }}</span>
        <span>{{ additional.ownCapital }}</span>
      </fieldset>
    </main>
    <footer class="text-center">
      <ps-button v-if="loading" class="bg-blue-100" disabled>
        <spinner />
      </ps-button>
      <ps-button
        v-else-if="resource.clients.length"
        icon="long-arrow-right"
        :title="$t('task.scoutInquiryDetails.toContact', { name: resource.clients[0].name })"
        class="bg-blue-100"
        @click="$router.push(resource.clients[0].path)"
      />
      <ps-button
        v-else
        icon="plus"
        :title="$t('task.scoutInquiryDetails.createContact')"
        class="bg-blue-100"
        @click="createSeeker"
      />
    </footer>
  </section>
</template>

<script>
const SALUTATION_MAPPING = { mr: "mr", ms: "ms" }

export default {
  props: {
    resource: {},
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    createSeeker() {
      if (this.loading) return
      this.loading = true

      this.$api
        .mutation("createInquirySeeker", { inquiryId: this.resource.id }, "client { id name path emails phone email }")
        .then(({ client }) => {
          this.resource.clients.push(client)
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.loading = false))
    },
  },
  computed: {
    salutation() {
      return this.$t(`task.scoutInquiryDetails.${SALUTATION_MAPPING[this.resource.seekerPayload.salutation]}`)
    },
    additional() {
      return this.resource.seekerAdditionalInfo
    },
  },
}
</script>