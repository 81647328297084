export default {
  salutation: null,
  academic_title: null,
  first_name: null,
  last_name: null,
  home_cell: null,
  home_phone: null,
  office_cell: null,
  office_phone: null,
  email: null,
  emails: [],
  client_status_id: null,
  client_source_id: null,
  group_ids: [],
  broker_id: null,
  second_broker_id: null,
  description: null,
  followup_date: null,
  warning_notice: null,
  locale: null,
  message_salutation: null,
  office_url: null,
  home_url: null,
  locked: false,
  archived: false,
  newsletter: null,
  client_sync_broker_ids: [],
  broker_ids: [],
  department_ids: [],
  keep_data_till: null,
  client_reason_id: null,
  gdpr_status: 0,
  accept_contact: null,
  home_street: null,
  home_house_number: null,
  home_zip_code: null,
  home_city: null,
  home_country: null,
  company: null,
  position: null,
  office_street: null,
  office_house_number: null,
  office_zip_code: null,
  office_city: null,
  office_country: null,
  office_fax: null,
  dob: null,
  birth_name: null,
  birth_place: null,
  birth_country: null,
  pass_type: null,
  identity_number: null,
  issuing_authority: null,
  tax_identification_number: null,
  nationality: null,
  legal_form: null,
  register_number: null,
  conspicuity: null,
  equity: null,
  handover_date: null,
  income: null,
  mvsigned: false,
  hvsigned: false,
  custom_fields: {},
  project_ids: [],
  relationships: [],
  children: [],
  client_addresses: []
}
