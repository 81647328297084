<template>
  <bubble
    :tooltip="
      $t('client.acceptContact.text', {
        accept: accepted ? $t('client.acceptContact.y') : $t('client.acceptContact.n'),
      })
    "
    :type="accepted ? 'success' : 'danger'"
    class="relative"
  >
    <fa-icon name="megaphone" />
    <fa-icon v-if="!accepted" name="slash" class="absolute" />
  </bubble>
</template>

<script>
export default {
  props: {
    accepted: Boolean,
  },
}
</script>