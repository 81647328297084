export function formatCurrency(price: number, formatOptions: Partial<Intl.NumberFormatOptions> = {}) {
  const defaultOptions = Object.assign(
    {
      style: "currency",
      currency: "EUR",
    },
    formatOptions
  )

  return price.toLocaleString(undefined, defaultOptions)
}
