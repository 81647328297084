import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { QueryKeys } from "../query-keys"
import ServiceProvider from "../services/service-provider"
import {
  Integration,
  IntegrationConsentRequest,
  IntegrationConsentResponse,
  IntegrationId,
} from "../types/integration-service"

async function createIntegrationConsent(integrationId: IntegrationId, body: IntegrationConsentRequest) {
  const response = await ServiceProvider.integrations.saveConsent(integrationId, body)
  if (response.isSuccessful2xx) {
    return response.data as IntegrationConsentResponse
  } else {
    return undefined
  }
}

export const useIntegrationCreateConsentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ integrationId, consent }: { integrationId: IntegrationId; consent: IntegrationConsentRequest }) =>
      createIntegrationConsent(integrationId, consent),
    onSuccess: (consentResponse, { integrationId }) => {
      // we just replace the whole integration object adding the new consent, we do not invalidate or refetch
      const integrations = queryClient.getQueryData(QueryKeys.integrations.all()) as Record<IntegrationId, Integration>
      const integration = integrations[integrationId]

      if (!integration) {
        queryClient.invalidateQueries({ queryKey: QueryKeys.integrations.all() })
        return
      }

      const newIntegrations = {
        ...integrations,
        [integrationId]: { ...integration, consent: consentResponse },
      }

      queryClient.setQueryData(QueryKeys.integrations.all(), newIntegrations)
    },
  })
}
