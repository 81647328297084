<template>
  <widget-container :widget="widget" :config="config">
    <template v-slot:title>
      <div class="flex justify-between">
        <span>{{ widget.title }}</span>
      </div>
    </template>
    <p v-if="!widget.source" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.iframe") }}
    </p>
    <iframe width="100%" height="100%" frameborder="0" :src="widget.source" allowfullscreen></iframe>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"

export default {
  components: { WidgetContainer },
  props: {
    widget: {},
    config: {},
  },
}
</script>
