<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { prettyNumber } from "../../config/number-filters"

export default {
  props: {
    value: {},
  },
  computed: {
    formattedValue() {
      switch (typeof this.value) {
        case "boolean":
          return this.value ? this.$t("formattedValue.yes") : this.$t("formattedValue.no")
        case "number":
          return prettyNumber(this.value)
        default:
          if (Date.parse(this.value)) return $customFilters.date(value)
          return this.value
      }
    },
  },
}
</script>