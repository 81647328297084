import { detect, IDetectedMap } from "jschardet"
import { parse } from "papaparse"

const parseAsync = (source, options = {}) => {
  return new Promise<any>((resolve, reject) => {
    parse(source, {
      ...options,
      complete(results) {
        resolve(results)
      },
      error(error) {
        reject(error)
      },
    })
  })
}

const readFileAsync = file => {
  return new Promise<{ buffer: any; metadata: IDetectedMap }>((resolve, reject) => {
    if (!file) reject()
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = function (e) {
      const buffer = (e.target as any).result
      const metadata = detect(buffer)
      console.log("Encoding detected: ", metadata)
      resolve({ buffer: file, metadata })
    }
    reader.onerror = reject
  })
}

const defaultConfig = {
  skipEmptyLines: "greedy",
}

export const parseCSV = async file => {
  const { buffer, metadata } = await readFileAsync(file)
  const config = { encoding: metadata.encoding, ...defaultConfig }
  const { data } = await parseAsync(buffer, { preview: 1, delimiter: ",", ...config })
  // guess the delimiter only of the first row
  const delimiterOfFirstRow = (await parseAsync(data + "", config)).meta.delimiter
  return (await parseAsync(buffer, { delimiter: delimiterOfFirstRow, ...config })) as any
}
