<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <div
      v-for="item in items"
      :key="item.id"
      class="flex rounded p-2 mx-1 cursor-pointer hover:bg-black/5"
      @click="open(item)"
    >
      <aside class="mr-2 relative" style="bottom: 2px">
        <fa-icon name="envelope" class="text-gray-600 text-lg" />
        <span
          v-if="!item.read"
          class="block absolute bg-blue-600 w-2 h-2 rounded-full"
          style="left: 4px; top: 27px"
          title="Ungelesen"
        ></span>
      </aside>
      <main class="flex-grow pl-1 overflow-hidden">
        <header class="flex justify-between">
          <p class="leading-snug font-medium">
            <fa-icon v-if="item.replied" name="reply" class="text-sm mr-1" />
            <fa-icon v-if="item.forwarded" name="share" class="text-sm mr-1" />
            {{ item.sender || item.from || $t("widget.emails.noSender") }}
          </p>
          <aside class="text-gray-600 text-sm ml-2 text-right flex items-center">
            <fa-icon v-if="item.message_attachments_count" name="paperclip" />
            <nice-divider direction="vertical" v-if="item.message_attachments_count" />
            <time style="min-width: 80px">
              {{ $customFilters.emailCalendar(item.created_at) }}
            </time>
          </aside>
        </header>
        <p class="text-sm text-ellipsis whitespace-nowrap overflow-hidden block">{{ item.subject }}</p>
      </main>
    </div>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.emails") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import eventBus from "@/config/event-bus"

export default {
  components: { WidgetContainer },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData() {
      if (this.loading) return
      this.loading = true
      let url = `/api/v1/messages`
      if (this.widget.location == "me") url = "/api/v1/messages/me"
      if (this.widget.location?.startsWith("sf-"))
        url = `/api/v1/messages/smart_folders/${this.widget.location.match(/\d+/)[0]}`

      const { data } = await this.$axios.get(url, {
        params: {
          per: 10,
          page: this.page,
          message_category_id: this.widget.categoryIds,
          inbox_broker_id: this.widget.inboxBrokerIds,
        },
      })

      this.items = this.items.concat(
        data.data.map(item => ({
          ...item,
          broker: this.$db.shopData.brokers.find(b => b.id === item.broker_id),
          category: this.$db.shopData.todoCategories.find(b => b.id === item.category_id),
          client: item.client_names && item.client_names.map((n, i) => ({ id: item.client_ids[i], name: n }))[0],
          property: item.properties && item.properties.length ? item.properties[0] : undefined,
        }))
      )
      this.total = data.meta.total_count
      this.loading = false
      this.noMore = 10 * this.page >= this.total
      this.page++
    },
    open(item) {
      eventBus.$emit("quick-view", {
        type: "message",
        mode: "view",
        id: item.id,
        params: { markAsRead: !item.read },
      })
      item.read = true
    },
  },
  computed: {},
  mounted() {
    this.fetchData()

    this.$pusher.on(`message:created:${this.$db.broker.id}`, () => this.fetchData())
    this.$pusher.on("messages:saved", this.fetchData())
  },
}
</script>
