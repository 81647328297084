<script setup lang="ts">
import { Integration } from "@/integrations/types/integration-service"
import { IntegrationMapping } from "@/integrations/types/integration-page"
import ConfigurationTableRow from "./ConfigurationTableRow.vue"
import IntegrationPricing from "./../integrations/IntegrationPricing.vue"
import IntegrationPermissions from "../integrations/IntegrationPermissions.vue"
import { computed } from "vue"

const props = defineProps<{
  integration: Integration
  mapping: IntegrationMapping<any>
}>()

const hasPermissions = computed(() =>
  Boolean(props.integration?.provider?.permissions || props.mapping.customPermissions)
)
</script>
<template>
  <div>
    <configuration-table-row :label="$t('integrations.configuration.billing')" v-if="integration.billing">
      <integration-pricing :billing="integration.billing" />
    </configuration-table-row>
    <configuration-table-row :label="$t('integrations.configuration.provider')" v-if="integration.provider">
      {{ integration.provider.name }}
    </configuration-table-row>
    <configuration-table-row :label="$t('integrations.configuration.permissions')" v-if="hasPermissions">
      <div className="mb-2">{{ $t("integrations.permissions.caption") }}</div>
      <integration-permissions
        :billing="integration.billing"
        :permissions="integration.provider?.permissions"
        :custom-permissions="mapping.customPermissions"
        class="pull-right"
      />
    </configuration-table-row>
  </div>
</template>
