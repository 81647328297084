<template>
  <section>
    <label v-if="title" class="text-lg semi-bold mb-4 mt-3 border-b pb-3 block">
      {{ title }}
      <tip v-if="hint" :hint="hint" class="ml-2" />
    </label>
    <ul class="form-field-rows">
      <slot />
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    hint: {
      type: String,
    },
  },
}
</script>
