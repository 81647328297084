<template>
  <div class="mb-2">
    <span class="uppercase text-sm font-semibold text-gray-400">
      {{ $customFilters.pluralize(title[0], title[1], title[2]) }}
    </span>
    <router-link v-if="link" :to="link" class="heading-link text-gray-600">
      <fa-icon name="long-arrow-right" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array,
      required: true,
    },
    link: {
      type: String,
      required: undefined,
    },
  },
}
</script>

<style scoped>
.heading-link {
  float: right;
}

.text-gray-600 text-sm {
  color: gray;
}
</style>