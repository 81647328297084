<template>
 <div 
    class="px-3 py-2 text-dark d-flex align-items-center pointer" 
    @click="item.isFolder && item.children && item.children.length > 0 ? $emit('collapse') : $emit('edit')">
  <span class="mr-3 text-gray-600 text-xl">
    <nestable-list-icon
      :children="children"
      :search="search"
      :showNestedItems="showNestedItems"
      :item="item"
      :sortable="sortable"
    >
      <template v-slot:icon>
        <ConnectionIcon :item="item"></ConnectionIcon>
      </template>
    </nestable-list-icon>
  </span>
  <span class="txt-overflow w-full">
    <ConnectionBody :item="item"></ConnectionBody>
  </span>
  <hover-actions>
    <ConnectionActions
      v-if="showActions && item.isConnection"
      :item="item"
      :beingTested="beingTested(item)"
      @testPortal="$emit('multiplex', { evt: 'testPortal', item} )"
      @visit="$emit('multiplex', { evt: 'visit', item} )"
      @reconnectScoutWebhook="$emit('multiplex', { evt: 'reconnectScoutWebhook', item} )"
      @importFromIS24="$emit('multiplex', { evt: 'importFromIS24', item} )"
      @importFromMls="$emit('multiplex', { evt: 'importFromMls', item} )"
      @startRotation="$emit('multiplex', { evt: 'startRotation', item} )"
      class="flex gap-2"
    >
    </ConnectionActions>
    <hover-action v-if="showActions" @click="$emit('edit', item)" icon="pencil" />
    <hover-action v-if="showActions && (!item.children || item.children.length == 0)" @click="$emit('delete', item)" icon="trash-alt" class="text-danger" />
  </hover-actions>
 </div>
</template>

<script>
import ConnectionIcon from "@/components/connections/ConnectionIcon.vue"
import ConnectionBody from "@/components/connections/ConnectionBody.vue"
import ConnectionActions from "@/components/connections/ConnectionActions.vue"
import NestableListIcon from "@/components/settings/NestableListIcon.vue"

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean
    },
    children: {
      type: Array
    },
    search: {
      type: Number
    },
    showNestedItems: {
      type: Boolean
    },
    showNestedItems: {
      type: Boolean
    },
    sortable: {
      type: Boolean
    }
  },
  computed: {
    beingTested() {
      return (() => false)
    }
  },
  components: {
    ConnectionIcon, ConnectionBody, ConnectionActions, NestableListIcon
  },
}
</script>
