<template>
  <section class="flex py-2 items-center">
    <a href="#" @click.prevent="$emit('remove', item.key)" class="searchFilter-remove">
      <fa-icon name="times" />
    </a>
    <div class="w-full">
      <nice-select fixed-position v-model="item.field" :options="fields" @change="fieldChanged" label-key="label" />
    </div>
    <p><fa-icon name="long-arrow-right" class="text-xl mx-5" /></p>
    <div class="w-full">
      <div v-if="selectedField">
        <component v-if="allowedComponent" :is="selectedField.component" v-model="item.value" />
        <db-select
          v-else-if="selectedField.dbOptions"
          :collection="selectedField.dbOptions"
          fixed-position
          v-model="item.value"
          :multiple="selectedField.type == 'multiselect'"
        />
        <nice-select
          v-else-if="selectedField.options"
          :options="selectedField.options"
          v-model="item.value"
          fixed-position
        />
        <nice-input
          size="small"
          v-else-if="selectedField.type == 'string' || selectedField.type == 'link' || selectedField.type == 'phone'"
          v-model="item.value"
        />
        <nice-input size="small" v-else-if="selectedField.type == 'number'" type="number" v-model="item.value" />
        <nice-textarea v-else-if="selectedField.type == 'text'" v-model="item.value" />
        <nice-checkbox v-else-if="selectedField.type == 'boolean'" v-model="item.value" />
        <nice-date-picker v-else-if="selectedField.type == 'date'" v-model="item.value" fixed-position />
        <client-select
          v-else-if="selectedField.type == 'client' || selectedField.type == 'clients'"
          :multiple="selectedField.type == 'clients'"
          v-model="item.value"
          include-children
          fixed-position
        />
        <property-select
          v-else-if="selectedField.type == 'property' || selectedField.type == 'properties'"
          :multiple="selectedField.type == 'properties'"
          v-model="item.value"
          fixed-position
        />
      </div>
    </div>
  </section>
</template>

<script>
import Commission from "@/components/mass-edit/Commission.vue"

export default {
  components: {
    Commission,
  },
  props: {
    fields: {},
    item: {},
  },
  computed: {
    selectedField() {
      if (!this.item.field) return
      return this.fields.find(f => f.id == this.item.field)
    },
    allowedComponent() {
      return (
        this.selectedField.component &&
        !["exchange-field", "property-energy-certificate"].includes(this.selectedField.component)
      )
    },
  },
  methods: {
    fieldChanged() {
      if (this.selectedField && this.selectedField.type == "boolean") {
        this.item.value = false
      } else {
        this.item.value = null
      }
    },
  },
}
</script>
