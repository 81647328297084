<template>
  <form-dialog
    :title="formTitle"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    @close="onClose"
    :saving="saving"
    @opened="$emit('opened')"
    @submit="submit"
    :autofocus="!overrideSearchFilterId"
    :disabled="name.length <= 0 && !overrideSearchFilterId"
  >
    <form-section>
      <div v-if="!editId">
        <form-row :title="$t('smartViews.editForm.override')">
          <nice-select
            v-model="overrideSearchFilterId"
            :options="smartViewsForOverride"
            :placeholder="$t('smartViews.editForm.overridePh')"
          />
        </form-row>
        <nice-divider>
          <span class="light">{{ $t("smartViews.editForm.or") }}</span>
        </nice-divider>
      </div>
      <div :class="{ disabled: !!overrideSearchFilterId && !editId }">
        <form-row :title="$t('smartViews.editForm.name')">
          <nice-input size="small" v-model="name" />
        </form-row>
        <form-row
          v-if="hasSelect && !overrideSearchFilterId && !editId"
          :title="$t('smartViews.editForm.type')"
          :hint="$t('smartViews.editForm.typeHint')"
        >
          <nice-radio-group
            v-model="storageType"
            :options="[
              { id: 'filter', name: $t('smartViews.editForm.typeOption1') },
              { id: 'filter_and_layout', name: $t('smartViews.editForm.typeOption2') },
              { id: 'layout', name: $t('smartViews.editForm.typeOption3') },
            ]"
          />
        </form-row>
        <form-row :title="$t('smartViews.editForm.shareAll')">
          <nice-switch v-model="isPublic" />
        </form-row>
        <form-row v-if="!isPublic" :title="$t('smartViews.editForm.shareUsers')">
          <db-select collection="activeBrokers" multiple v-model="brokerIds" />
        </form-row>
        <form-row v-if="!isPublic" :title="$t('smartViews.editForm.shareDepartments')">
          <db-select collection="departments" multiple v-model="departmentIds" />
        </form-row>
        <form-row v-if="allowMailchimpExport" :title="$t('smartViews.editForm.export')">
          <nice-switch v-model="mcExportable" />
        </form-row>
        <form-row
          v-if="entity == 'for_properties'"
          :title="$t('smartViews.editForm.iframe')"
          :hint="$t('smartViews.editForm.iframeHint')"
        >
          <nice-switch v-model="widgetActive" />
        </form-row>
      </div>
    </form-section>
  </form-dialog>
</template>

<script>
import * as _ from "lodash"

export default {
  emits: ["close", "opened", "saved", "update:smartViews", "update:editId", "update:visible"],
  props: {
    smartViews: {
      type: Array,
    },
    visible: {
      type: Boolean,
    },
    params: {
      type: Object,
    },
    additionalParamsToStore: {
      type: Object,
      default: () => ({}),
    },
    allowMailchimpExport: {
      type: Boolean,
    },
    entity: {
      type: String,
      default: "for_clients",
    },
    hasSelect: {
      type: Boolean,
    },
    selected: {
      type: Number,
    },
    editId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      storageType: "filter",
      name: "",
      isPublic: false,
      brokerIds: [],
      departmentIds: [],
      mcExportable: false,
      saving: false,
      overrideSearchFilterId: this.selected,
      widgetActive: false,
    }
  },

  watch: {
    selected(newValue) {
      if (!this.smartViewsForOverride.find(({ id }) => newValue === id)) return
      this.overrideSearchFilterId = newValue
    },
    visible(newValue) {
      if (newValue && this.editId) this.editFields()
    },
  },

  methods: {
    editFields() {
      this.name = this.editData.name
      this.isPublic = this.editData.isPublic
      this.brokerIds = this.editData.brokerIds
      this.departmentIds = this.editData.departmentIds
      this.mcExportable = this.editData.mcExportable
      this.widgetActive = this.editData.widgetActive
    },
    onClose() {
      this.$emit("update:editId", null)
      ;(this.storageType = "filter"), this.reset()
    },
    getParams() {
      let flatParams = _.merge(this.params, this.params.params)
      const filterParams = ["filter_set", "es_filters", "per", "sort_by", "order"]
      const layoutParams = ["columns"]
      const storageType = this.overrideSearchFilter
        ? this.overrideSearchFilter.storageType || "filter"
        : this.storageType
      const omittedParams = storageType === "filter" ? layoutParams : storageType === "layout" ? filterParams : []
      flatParams = _.omit(flatParams, omittedParams)
      return {
        params: JSON.stringify({
          ...this.additionalParamsToStore,
          filter_set: flatParams.filter_set,
          per: flatParams.per,
          sort_by: flatParams.sort_by,
          order: flatParams.order,
          columns: flatParams.columns,
        }),
        es_filters: flatParams.es_filters,
      }
    },

    reset() {
      this.overrideSearchFilterId = null
      this.name = ""
      this.isPublic = false
      this.brokerIds = []
      this.departmentIds = []
      this.mcExportable = false
      this.widgetActive = false
    },

    submit() {
      let data, method, url
      if (this.editId) {
        url = `/services/search_filters/${this.editId}`
        method = "patch"
        data = {
          search_filter: {
            name: this.name,
            is_public: this.isPublic,
            broker_ids: this.isPublic ? [] : this.brokerIds,
            department_ids: this.isPublic ? [] : this.departmentIds,
            broker_id: this.brokerId,
            mc_exportable: this.mcExportable,
            for_entity: this.editData.forEntity,
            widget_active: this.widgetActive,
          },
        }
      } else if (this.overrideSearchFilterId) {
        url = `/services/search_filters/${this.overrideSearchFilterId}`
        method = "patch"
        data = { search_filter: this.getParams() }
      } else {
        url = "/services/search_filters"
        method = "post"
        data = {
          search_filter: {
            ...this.getParams(),
            name: this.name,
            is_public: this.isPublic,
            broker_ids: this.isPublic ? [] : this.brokerIds,
            department_ids: this.isPublic ? [] : this.departmentIds,
            broker_id: this.brokerId,
            mc_exportable: this.mcExportable,
            for_entity: this.entity,
            storage_type: this.storageType,
            widget_active: this.widgetActive,
          },
        }
      }
      this.saving = true

      this.$axios
        .request({
          method,
          url,
          data,
        })
        .then(({ data: response }) => {
          if (this.overrideSearchFilterId) {
            App.flashy(this.$t("smartViews.editForm.editSuccess"))
          } else {
            App.flashy(this.$t("smartViews.editForm.createSuccess"))
          }
          this.reset()
          this.$emit("update:editId", null)
          this.$emit("close")
          const smartViewJSON = {
            id: response.id,
            forEntity: this.entity,
            name: response.name,
            brokerId: response.broker_id,
            brokerIds: response.broker_ids,
            departmentIds: response.department_ids,
            isPublic: response.is_public,
            mcExportable: response.mc_exportable,
            esFilters: response.es_filters,
            workList: response.work_list,
            params: response.params,
            storageType: response.storage_type,
            widgetActive: response.widget_active,
            widgetUrl: response.widget_url,
          }
          this.$emit("saved", smartViewJSON)
          this.$emit("update:smartViews", this.smartViews.filter(o => o.id !== response.id).concat([smartViewJSON]))
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.saving = false))
    },
  },

  computed: {
    smartViewsForOverride() {
      return this.smartViews.filter(({ brokerId }) => brokerId === this.$db.broker.id)
    },
    overrideSearchFilter() {
      return this.smartViews.find(sv => sv.id == this.overrideSearchFilterId)
    },
    brokerId() {
      return this.$db.broker.id
    },
    formTitle() {
      return this.editId ? this.$t("smartViews.editForm.editTitle") : this.$t("smartViews.editForm.createTitle")
    },
    editData() {
      return this.smartViews.find(sf => sf.id == this.editId)
    },
  },
}
</script>
