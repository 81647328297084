<template>
  <section class="mb-3">
    <nav class="mb-3">
      <p class="font-medium">{{ title }}</p>
      <p v-if="hint" class="text-sm text-gray-600">{{ hint }}</p>
    </nav>
    <ul class="form-field-rows">
      <slot />
    </ul>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    title: {
      type: String,
    },
    hint: {
      type: String,
      required: false,
    },
  },
}
</script>
