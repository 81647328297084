<template></template>
<script type="ts">
export default {
  props: {
    dispatch: {
      type: Function,
      required: true,
    },
    pathBack: {
      type: Number,
      required: false,
    },
    redirect: {
      type: String,
      required: false,
    },
    fallback: {
      type: String,
      required: false,
      default: "/404",
    },
  },
  methods: {
    async execute() {
      const handled = await this.dispatch(this)
      if (!handled) {
        this.$router.push(this.fallback).catch(() => {})
        return
      }
      if (this.redirect) {
        this.$router.push(this.redirect).catch(() => {})
        return
      }
      if (this.pathBack) {
        const { path } = this.$route
        const parent = path.split("/").slice(0, this.pathBack).join("/")
        this.$router.push(parent)
        return
      }
      this.$router.push("/").catch(() => {})
    },
  },
  beforeMount() {
    this.execute()
  },
}
</script>