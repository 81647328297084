<template>
  <card>
    <div class="d-flex align-items-center justify-content-between px-3 py-2 mb-4">
      <div class="d-flex align-items-center">
        <fa-icon name="calendar-alt" class="mr-3 text-primary" style="font-size: 20px" />
        <p>
          <span style="font-size: 16px">{{ data.summary || $t("inbox.eventCard.invitation") }}</span>
          <br />
          <small>
            {{ $t("inbox.eventCard.eventStart", { date: date, startTime: startTime, endTime: endTime }) }}
            <span v-if="data.location" class="ml-2">
              <fa-icon name="map-marker-alt" />
              {{ data.location }}
            </span>
          </small>
        </p>
      </div>
      <a href="#" @click.prevent="quickviewEvent" class="btn btn-primary">{{ $t("inbox.eventCard.create") }}</a>
    </div>
  </card>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: ["data", "message"],

  methods: {
    quickviewEvent() {
      eventBus.$emit("quick-view", {
        type: "event",
        mode: "edit",
        params: {
          source: {
            clientIds: this.message.clients.map(o => o.id),
            title: this.data.summary,
            startsAt: this.data.start,
            endsAt: this.data.end,
            location: this.data.location,
          },
        },
      })
    },
  },

  computed: {
    date() {
      return moment(this.data.start).format("L")
    },
    startTime() {
      return moment(this.data.start).format("HH:mm")
    },
    endTime() {
      return moment(this.data.end).format("HH:mm")
    },
  },
}
</script>