<template>
  <settings-section>
    <template #description>
      <slot name="description" />
    </template>
    <slot name="header" :items="modelValue" />
    <alert v-if="modelValue && !modelValue.length && (emptyState || $slots['empty-state'])" class="mb-3">
      <slot name="empty-state">{{ emptyState }}</slot>
    </alert>
    <draggable
      :undraggable="!sortable"
      :modelValue="modelValue"
      :group="{ name: 'g1' }"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #item="{ element }">
        <nestable-list-item
          :key="`${element.isFolder ? 'folder_' : 'id_'}${element.id}`"
          class="mb-2"
          :add-func="addFunc"
          :edit-func="editFunc"
          :delete-func="deleteFunc"
          :depth="depth + 1"
          :get-actions-for-item="getActionsForItem"
          :children="element.children"
          :sortable="sortable"
          :item="element"
          :initial-show-nested-items="initialShowNestedItems"
          :search="search"
          :useComponent="useComponent"
          :disableDragging="disableDragging"
          @update:modelValue="$emit('update:modelValue', modelValue)"
          @multiplex="payload => $emit('multiplex', payload)"
        >
          <template v-slot:icon>
            <slot name="icon" :item="element" />
          </template>
          <template v-slot:title>
            <slot name="name" :item="element">
              {{ element.name }}
            </slot>
          </template>
          <template v-slot:actions>
            <slot name="actions" :item="element" />
          </template>
        </nestable-list-item>
      </template>
    </draggable>

    <slot name="add" v-if="canAdd">
      <ps-button
        class="mt-3 max-w-none"
        active
        @click="$emit('new')"
        icon="plus"
        :title="$t('settings.create', { label: $t(label) })"
      />
    </slot>
  </settings-section>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
  emits: ["update:modelValue", "new", "multiplex"],
  props: {
    disableDragging: {
      type: Boolean,
      default: false,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    emptyState: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: "settings.entry",
    },
    getActionsForItem: {
      type: Function,
      default: _ => ["add", "edit", "delete"],
    },
    addFunc: {
      type: Function,
      required: false,
    },
    editFunc: {
      type: Function,
      required: false,
    },
    deleteFunc: {
      type: Function,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
    search: {
      type: Number,
    },
    useComponent: {
      type: String,
    },
    initialCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    initialShowNestedItems() {
      return !this.initialCollapsed && (!this.size || this.size <= 200)
    },
  },
}
</script>
