<template>
  <img :src="item.photoUrl" v-if="item.photoUrl" style="height: 20px" class="block rounded" />
  <fa-icon v-else-if="!$db.featureActive('portal_folders') || item.isConnection" name="plug" class="text-green-600" />
  <fa-icon v-else name="folder" class="text-green-600" />
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  }
}
</script>