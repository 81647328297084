<template>
  <span>{{ availableLocales[locale] }}</span>
</template>

<script>
const availableLocales = {
  de: "🇩🇪",
  "zh-CN": "🇨🇳",
  en: "🇺🇸",
  fr: "🇫🇷",
  it: "🇮🇹",
  ru: "🇷🇺",
  es: "🇪🇸",
  hu: "🇭🇺",
}

export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      availableLocales,
    }
  },
}
</script>