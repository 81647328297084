<template>
  <div>
    <form-section title="Basis">
      <form-row title="Titel auf der Landing Page">
        <nice-input size="small" placeholder="Name" v-model="item.name" />
      </form-row>
      <form-row title="Statistikart">
        <nice-radio-group v-model="item.category" class="whitespace-nowrap" :options="categories" />
      </form-row>
    </form-section>
    <template v-if="item.category">
      <property-report-filter :fields="configMap[item.category].filters" v-model="item.paramsSnapshot" />
      <form-section title="Anzeige auf der Landing Page">
        <form-row v-if="configMap[item.category].views" title="Berechnung der Zahl">
          <nice-select v-model="item.view" :options="configMap[item.category].views" />
        </form-row>
        <form-row
          v-if="groups.length"
          title="Gruppieren nach..."
          hint="Wenn ausgefüllt, wird eine zweite Tabelle angezeigt, in der das Ergebnis gruppiert angezeigt wird nach der ausgewählten Dimension. So kann man sich z.B. die Anzahl je Einheit anzeigen lassen."
        >
          <nice-select v-model="item.group" :options="groups" multiple />
        </form-row>
      </form-section>
    </template>
  </div>
</template>

<script>
import PropertyReportFilter from "@/components/PropertyReportFilter"

export default {
  props: {
    config: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: { PropertyReportFilter },
  computed: {
    categories() {
      return this.config
    },
    configMap() {
      return _.keyBy(this.config, c => c.id)
    },
    groups() {
      if (this.item.category == "activity") {
        let groups = [
          {
            id: "properties.id",
            name: "Objekte",
          },
        ]

        if (this.item.paramsSnapshot.activity_type == "inquiry")
          groups.push({
            id: "source_id",
            name: "Quelle",
          })

        if (this.item.paramsSnapshot.activity_type == "cancelation")
          groups.push({
            id: "reason_id",
            name: "Absagegrund",
          })

        return groups
      }
      return []
    },
  },
}
</script>