<template>
  <form-dialog
    :title="$t('deals.importDialog.title')"
    :visible="visible"
    @update:visible="updateVisible"
    :saving="uploading"
    @submit="upload"
    :disabled="!activeFile || errors.length > 0 || rows.length <= 0"
    :submitButtonTitle="$t('deals.importDialog.submit')"
  >
    <div>
      <div class="d-flex align-items-center">
        <nice-button @click="$refs.fileInput.click()" :loading="loading">
          {{ $t("deals.importDialog.btn") }}
        </nice-button>
      </div>
      <input ref="fileInput" type="file" accept=".csv" style="display: none" @change="validate($event)" />
    </div>

    <div v-if="activeFile && !loading">
      <hr class="mt-4 mb-3" />

      <p v-if="errors.length > 0" class="mb-2">
        <fa-icon name="times" class="text-danger mr-1" />
        {{ $t("deals.importDialog.logError") }}
        <strong>{{ errors.join(", ") }}</strong>
      </p>
      <div v-if="errors.length <= 0">
        <p class="mb-2">
          <fa-icon name="check" class="text-success mr-1" />
          {{ $t("deals.importDialog.logChecked") }}
          <strong>{{ activeFile }}</strong>
        </p>
        <p class="mb-2">
          <fa-icon v-if="rows.length > 0" name="check" class="text-success mr-1" />
          <fa-icon v-else name="times" class="text-danger mr-1" />
          {{ $t("deals.importDialog.logData") }}
          <strong>{{ rows.length }}</strong>
        </p>
        <p v-if="unknownHeaders.length <= 0" class="mb-2">
          <fa-icon name="check" class="text-success mr-1" />
          {{ $t("deals.importDialog.logSuccess") }}
        </p>
        <p v-if="unknownHeaders.length > 0" class="mb-2">
          <fa-icon name="times" class="text-warning mr-1" />
          {{ $t("deals.importDialog.logUnknown") }}
          <strong>{{ unknownHeaders.join(", ") }}</strong>
        </p>
      </div>
    </div>
  </form-dialog>
</template>

<script>
import { parseCSV } from "@/config/csv-parser"

export default {
  props: {
    visible: {
      type: Boolean,
    },
  },

  data() {
    return {
      errors: [],
      unknownHeaders: [],
      rows: [],
      activeFile: null,
      uploading: false,
      loading: false,
    }
  },

  methods: {
    updateVisible(newValue) {
      this.$emit("update:visible", newValue)

      if (!newValue) {
        this.errors = []
        this.unknownHeaders = []
        this.rows = []
        this.activeFile = null
        this.uploading = false
        this.loading = false
        this.$refs.fileInput.value = null
      }
    },

    validate(event) {
      if (event.target.files.length <= 0) {
        console.log("no file found")
        return
      }
      const self = this
      const file = event.target.files[0]
      this.loading = true
      this.activeFile = file.name

      parseCSV(file).then(results => {
        const rows = results.data

        if (results.errors.length > 0) {
          self.errors = results.errors.map(o => o.message).slice(0, 3)
        }

        if (self.errors.length > 0) {
          self.loading = false
          return
        }

        self.$api
          .mutation("validateDealImportJson", { json: rows }, "jsonImportValidation { unknownHeaders }")
          .then(data => {
            console.log(data)
            self.rows = rows
            self.unknownHeaders = data.jsonImportValidation.unknownHeaders
            self.loading = false
          })
          .catch(self.$axios.handleError)
      })
    },

    upload() {
      this.uploading = true
      this.$api
        .mutation("importDealJson", { json: this.rows })
        .then(_ => {
          this.updateVisible(false)
          App.flashy(this.$t("deals.importDialog.success"))
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.uploading = false
        })
    },
  },
}
</script>
