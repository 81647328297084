import { useQuery } from "@tanstack/vue-query"

import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import QueryKeys from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { IntegrationId } from "../types/integration-service"

// we drop the paged response for now, because it complicates the use case
export const fetchCurrentIntegrationOrder = async (integrationId: IntegrationId, entityId: string) => {
  const response = await ServiceProvider.orders.getCurrentOrderByEntityId(integrationId, entityId)

  if (response.isSuccessful2xx) {
    return response.data ?? null
  }

  return null
}

export const useIntegrationCurrentOrderByEntityIdQuery = (
  integrationId: MaybeRef<IntegrationId>,
  entityId: MaybeRef<string>
) => {
  const queryKey = computed(() => QueryKeys.orders.byIntegrationByEntityId(get(integrationId), get(entityId)))

  return useQuery({
    queryKey,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    queryFn: () => fetchCurrentIntegrationOrder(get(integrationId), get(entityId)),
    // refetchOnMount: false,
  })
}
