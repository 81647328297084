export const formatEvent = function (task, brokers, noteTypes) {
  const broker = brokers.find(b => b.id === task.brokerId)
  const noteType = noteTypes.find(n => n.id === task.categoryId)

  return {
    ...task,
    title: task.title,
    broker,
    noteType,
    color: broker ? broker.color : null,
  }
}

export const startOfWeek = date => moment(date).startOf("week")
export const endOfWeek = date => moment(date).endOf("week")
export const startOfDay = date => moment(date).startOf("day").format()
export const endOfDay = date => moment(date).endOf("day").format()

export const createRecurrings = (recurrings, startDate, endDate) =>
  recurrings
    .map(event => {
      let rruleSet
      try {
        rruleSet = rrulestr(event.rrule, { forceset: true })
      } catch (e) {
        console.error("[PROPSTACK ERROR] in createRecurrings:", e)
        return []
      }

      const datesForWeek = rruleSet.between(startDate.toDate(), endDate.toDate(), true)
      return datesForWeek.map((date, i) => {
        const diff = moment(event.endsAt).diff(moment(event.startsAt), "minutes")
        return _.assign({}, event, {
          id: `${event.id}-r-${date.getTime()}`,
          startsAt: moment(date).format(),
          endsAt: moment(date).add(diff, "minutes").format(),
          recurring: false,
        })
      })
    })
    .flat()

export const getDateRange = (startDate, endDate) => {
  const dateArray = []
  let currentDate = moment(startDate)
  const stopDate = moment(endDate)
  while (currentDate <= stopDate) {
    dateArray.push(currentDate)
    currentDate = moment(currentDate).add(1, "days")
  }
  return dateArray
}
