<script setup lang="ts">
import { IntegrationConsentResponse } from "@/integrations/types/integration-service"
import { computed } from "vue"
import { formatDate, timestampToDate } from "@/utils/formatters/date"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  lastConsent: IntegrationConsentResponse
  consentText: string
  consentGiven: boolean
  disabled: boolean
}>()

const emit = defineEmits(["consent-change"])
const formattedDate = computed(() =>
  props.lastConsent?.timestamp ? formatDate(timestampToDate(props.lastConsent.timestamp)) : ""
)
const { db } = useCore()
const activeBrokerId = computed(() => db.broker.id)
const broker = computed(() =>
  props.lastConsent.userId
    ? db.shopData.brokers.find(broker => broker.id === Number(props.lastConsent.userId))
    : undefined
)

const { t } = useI18n()
</script>
<template>
  <div>
    <tooltip
      class="ml-2"
      :disabled="!lastConsent?.timestamp"
      :content="
        lastConsent?.timestamp
          ? t('integrations.consent.consentGiven', { user: broker?.name, date: formattedDate })
          : ''
      "
    >
      <nice-checkbox
        :model-value="consentGiven"
        @change="value => emit('consent-change', value)"
        :disabled="disabled"
        class="flex"
      >
        <span v-if="consentText" v-html="consentText" />
        <span v-else>{{ t("integrations.consent.consentGeneric") }}</span>
      </nice-checkbox>
    </tooltip>
  </div>
</template>
