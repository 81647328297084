<template>
  <div>
    <div class="searchFilter-item">
      <a href="#" @click.prevent="$emit('remove')" class="searchFilter-remove">
        <fa-icon name="times" />
      </a>
      <div class="mr-2" style="width: 280px">
        <nice-select
          allow-create
          v-model="option.key"
          placeholder="Attribut"
          :options="actionOptionKeys"
          id-key="name"
          label-key="label"
        />
      </div>
      <div class="searchFilter-value w-100 mr-2" v-if="actionOptionCategory">
        <nice-select :disabled="useFunc" clearable filterable allow-create v-model="option.category" placeholder="Wert">
          <template #default>
            <el-option v-for="item in actionOptionCategoryValues" :key="item.id" :label="item.name" :value="item.id">
              <p>
                {{ item.name }}
                <span class="ml-0 text-muted">- ({{ item.id }})</span>
              </p>
            </el-option>
          </template>
        </nice-select>
      </div>
      <div class="searchFilter-value w-100 mr-2">
        <nice-select
          :disabled="useFunc"
          :multiple="isMultiSelect"
          clearable
          filterable
          allow-create
          v-model="transformedOptionValue"
          @change="transformOptionValue"
          placeholder="Wert"
          :label-fn="labelFn"
        >
          <template #default>
            <el-option-group label="Feste Werte">
              <el-option v-for="item in actionOptionValues" :key="item.id" :label="item.name" :value="item.id">
                <p>
                  {{ item.name }}
                  <span class="ml-0 text-muted">- ({{ item.id }})</span>
                </p>
              </el-option>
            </el-option-group>
            <el-option-group v-if="variablesForSelectedAction.length" label="Variable Werte">
              <el-option
                v-for="item in variablesForSelectedAction"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-option-group>
          </template>
        </nice-select>
      </div>
    </div>
    <div class="ml-7 mb-2 -mt-1">
      <span class="text-sm" v-if="isChronic">
        {{ $t("automations.chronicEvaluation", { calculation: chronicDate }) }}
      </span>
      <nice-checkbox v-if="useFuncAvailable" v-model="useFunc" class="mb-1">
        <small class="text-sm text-gray-600">Wenn-Miete-Funktion nutzen</small>
      </nice-checkbox>
      <section v-if="useFunc" class="flex items-center">
        <p class="mr-3 whitespace-nowrap">Wenn Miete</p>
        <nice-select v-model="rentOption" :options="actionOptionValues" @change="generateValue" />
        <p class="mx-3 whitespace-nowrap">ansonsten</p>
        <nice-select v-model="buyOption" :options="actionOptionValues" @change="generateValue" />
      </section>
    </div>
  </div>
</template>

<script>
import { getVariablesForModel } from "@/config/recipes"
import { isEmpty } from "lodash"
import { transformSelectModelToConditionString } from "@/config/recipes"

const DB_OPTIONS_MAPPING = {
  broker_id: "brokers",
  client_status_id: "clientStatuses",
  department_ids: "departments",
  group_ids: "clientGroups",
  add_group_ids: "clientGroups",
  sub_group_ids: "clientGroups",
  note_type_id: "noteCategories",
  decision_type_id: "decisionCategories",
  todo_type_id: "todoCategories",
  snippet_id: "snippets",
  deal_stage_id: "dealStages",
  add_department_ids: "departments",
  sub_department_ids: "departments",
  project_status_id: "projectStatuses",
  department_id: "departments",
}

const BOOLEAN_VALUES = [
  {
    id: true,
    name: "Ja",
  },
  {
    id: false,
    name: "Nein",
  },
]

const unallowedTriggerTypes = [
  "CLIENT_CREATED",
  "CLIENT_UPDATED",
  "CLIENT_UPSERTED",
  "CLIENT_DELETED",
  "SAVED_QUERY_CREATED",
  "SAVED_QUERY_UPDATED",
  "SAVED_QUERY_DELETED",
  "CONTACT_PROFILE_COMPLETED",
  "UNSUBSCRIBE_LINK_CLICKED",
  "EVENT_CANCEL_LINK_CLICKED",
  "BIRTHDAY",
]

const propertyTriggerTypes = ["PROPERTY_CREATED", "PROPERTY_UPDATED"]

const allowedEmailReceiverTypes = ["to", "cc", "bcc"]

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    recipes: {
      type: Array,
      required: true,
    },
    recipe: {
      type: Object,
      required: true,
    },
    recipesConfig: {
      type: Object,
      required: true,
    },
    actionOptionsData: {
      type: Object,
    },
  },
  data() {
    const useFunc =
      Boolean(this.option.value) &&
      typeof this.option.value == "string" &&
      this.option.value.includes('marketing_type | equals: "RENT"')
    const rentOption = useFunc && this.option.value.match(/either_or: (\d+),/)
    const buyOption = useFunc && this.option.value.match(/either_or: \d+, (\d+) /)
    return {
      useFunc: useFunc,
      rentOption: useFunc && rentOption ? Number(rentOption[1]) : null,
      buyOption: useFunc && buyOption ? Number(buyOption[1]) : null,
      chronicDate: null,
    }
  },
  methods: {
    transformOptionValue(value) {
      if (this.isMultiSelect) this.option.value = !value.length ? null : transformSelectModelToConditionString(value)
      else this.option.value = value
    },
    labelFn(val) {
      const allOption = this.actionOptionValues.concat(this.variablesForSelectedAction)
      if (allOption.length && allOption.find(({ id }) => id === val)) return allOption.find(({ id }) => id === val).name
      else return val
    },
    generateValue() {
      this.$nextTick(() => {
        this.option.value = `{{ ${this.marketingTypeVariable} | equals: "RENT" | either_or: ${this.rentOption}, ${this.buyOption} }}`
      })
    },
    async getChronicResponse() {
      if (!this.isChronic || this.option.value.startsWith("{{")) return

      const { chronicDate } = await this.$graphql(`query chronicDate{
        chronicDate(prompt: "${this.option.value}")
      }`)

      this.chronicDate = this.$customFilters.datetime(chronicDate)
    },
  },
  computed: {
    isMultiSelect() {
      return ["sub_group_ids", "add_group_ids"].includes(this.option.key)
    },
    transformedOptionValue() {
      if (!this.isMultiSelect) return this.option.value
      if (typeof this.option.value === "string")
        return this.option.value
          .split(",")
          .map(v => {
            if (v.includes("{{")) return v
            else return parseInt(v.trim())
          })
          .filter(Boolean)
      if (Array.isArray(this.option.value))
        return this.option.value.filter(Boolean).map(v => {
          if (typeof v === "number") return v
          if (v.includes("{{")) return v
          return parseInt(v)
        })
      return this.option.value ? [this.option.value] : []
    },
    isChronic() {
      return this.selectedOption?.type == "chronic"
    },
    actionOptionKeys() {
      if (!this.selectedAction) return []

      return this.selectedAction.options.filter(
        o => !this.recipe.actionOptions.some(ao => ao.key == o.name) || this.option.key == o.name
      )
    },
    selectedTrigger() {
      if (!this.recipe.triggerType) return null
      return this.recipesConfig.triggers.find(a => a.name == this.recipe.triggerType)
    },
    selectedAction() {
      if (!this.recipe.actionType) return null
      return this.recipesConfig.actions.find(a => a.name == this.recipe.actionType)
    },
    selectedOption() {
      if (!this.selectedAction || !this.option.key) return null
      return this.selectedAction.options.find(o => this.option.key == o.name)
    },
    triggerModel() {
      return this.selectedTrigger?.model
    },
    variables() {
      let variables = []

      if (this.triggerModel) {
        variables = getVariablesForModel(this.$db, this.recipesConfig, this.triggerModel)
      } else {
        const previousRecipe = this.recipes.find(r => r.nextRecipeIds?.includes(this.recipe.id))
        const triggerModel = this.recipesConfig.triggers.find(a => a.name == previousRecipe?.triggerType)?.model
        if (triggerModel) {
          variables = variables.concat(
            getVariablesForModel(this.$db, this.recipesConfig, triggerModel, { prefix: "previous__" })
          )
        }

        const actionModel = this.recipesConfig.actions.find(a => a.name == previousRecipe?.actionType)?.model
        if (actionModel) {
          variables = variables.concat(getVariablesForModel(this.$db, this.recipesConfig, actionModel))
        }
      }

      return variables
    },
    variablesForSelectedAction() {
      return this.variables
        .filter(v => isEmpty(this.selectedOption?.type) || v.type == this.selectedOption.type)
        .map(v => ({
          ...v,
          id: `{{ ${v.id} }}`,
        }))
    },
    actionOptionValues() {
      if (!this.option.key) return []

      const values = this.selectedOption?.type == "boolean" ? BOOLEAN_VALUES : []
      const options = this.selectedOption?.options || []
      const remoteStaticValues =
        this.actionOptionsData[DB_OPTIONS_MAPPING[this.option.key] || this.selectedOption?.dboptions] || []

      if (allowedEmailReceiverTypes.includes(this.option.key)) {
        const projectVariables = this.$db.shopData.projectRelationshipPartnerNames.map(r => ({
          id: `{{ project__rs__${r.id}__email }}`,
          name: `Projekt → ${r.name}`,
        }))
        if (propertyTriggerTypes.includes(this.recipe.triggerType)) {
          const objectVariables = this.$db.shopData.relationshipPartnerNames.map(r => ({
            id: `{{ rs__${r.id}__email }}`,
            name: `Objekt → ${r.name}`,
          }))
          return [...values, ...options, ...remoteStaticValues, ...objectVariables.concat(projectVariables)]
        }
        if (!unallowedTriggerTypes.includes(this.recipe.triggerType)) {
          const objectVariables = this.$db.shopData.relationshipPartnerNames.map(r => ({
            id: `{{ property__rs__${r.id}__email }}`,
            name: `Objekt → ${r.name}`,
          }))
          return [...values, ...options, ...remoteStaticValues, ...objectVariables.concat(projectVariables)]
        }
      }

      return [...values, ...options, ...remoteStaticValues]
    },
    actionOptionCategory() {
      return this.selectedOption?.category
    },
    actionOptionCategoryValues() {
      return this.actionOptionsData[this.actionOptionCategory] || []
    },
    marketingTypeVariable() {
      return this.variables.find(v => v.id.includes("marketing_type"))?.id
    },
    useFuncAvailable() {
      return this.marketingTypeVariable && this.selectedOption?.has_use_func
    },
  },
  watch: {
    "option.value": {
      handler() {
        this.getChronicResponse()
      },
    },
  },
  mounted() {
    this.getChronicResponse()
  },
}
</script>
