<template>
  <card>
    <div class="px-4 py-3 mb-4">
      <header>
        <p style="font-size: 16px" class="mb-1">
          <strong>{{ event.title || $t("inbox.eventCard.invitation") }}</strong>
        </p>
        <p class="light">
          {{ $t("inbox.eventCard.eventStart", { date: date, startTime: startTime, endTime: endTime }) }}
        </p>
      </header>
      <hr style="margin: 1rem 0" />
      <p v-if="event.location" class="mb-3">
        <fa-icon name="map-marker-alt" class="mr-2 ml-2 light" />
        {{ event.location }}
        –
        <a :href="$customFilters.gmapsUrl(event.location)" target="_blank">
          {{ $t("inbox.inviteEventCard.link") }}
        </a>
      </p>
      <section v-if="event.participants.length > 0" class="mt-2">
        <event-participant v-for="p in event.participants" :key="p.email" :participant="p" />
      </section>

      <footer class="d-flex justify-content-between align-items-center mt-3">
        <span>{{ $t("inbox.inviteEventCard.question") }}</span>
        <div>
          <button class="btn btn-sm ml-2" :class="{ 'btn-success': event.rsvpStatus == 'yes' }" @click="rsvp('yes')">
            {{ $t("inbox.inviteEventCard.y") }}
          </button>
          <button
            class="btn btn-sm ml-2"
            :class="{ 'btn-warning': event.rsvpStatus == 'maybe' }"
            @click="rsvp('maybe')"
          >
            {{ $t("inbox.inviteEventCard.m") }}
          </button>
          <button class="btn btn-sm ml-2" :class="{ 'btn-danger': event.rsvpStatus == 'no' }" @click="rsvp('no')">
            {{ $t("inbox.inviteEventCard.n") }}
          </button>
        </div>
      </footer>
    </div>
  </card>
</template>

<script>
import EventParticipant from "./EventParticipant"

export default {
  props: ["event", "externalInviteEventId"],

  components: {
    EventParticipant,
  },

  methods: {
    rsvp(status) {
      this.event.rsvpStatus = status
      this.$axios
        .post(`/services/tasks/${this.event.id}/rsvp`, {
          status,
          external_event_id: this.externalInviteEventId,
        })
        .catch(err => {
          this.event.rsvpStatus = null
          console.log(err)
          App.alert(this.$t("inbox.inviteEventCard.error"))
        })
    },
  },

  computed: {
    date() {
      return moment(this.event.startsAt).format("LL")
    },
    startTime() {
      return moment(this.event.startsAt).format("HH:mm")
    },
    endTime() {
      return moment(this.event.endsAt).format("HH:mm")
    },
  },
}
</script>