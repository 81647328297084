<template>
  <div :data-id="field.id" class="flex justify-between items-center">
    <span class="mr-2">
      {{ field.prettyName }}
      <tooltip v-if="field.formula" :content="`Formel: ${field.formula}`">
        <fa-icon name="function" class="ml-1 text-green-700" />
      </tooltip>
      <tooltip v-if="field.exportToOpenimmo" :content="$t('customFields.exportedToPortal')">
        <fa-icon name="globe" class="ml-1 text-green-700" />
      </tooltip>
      <tooltip
        v-if="missingInDetailView"
        :content="`Dieses Feld ist aktuell nicht in der Maske sichtbar.<br>Wenn das nicht gewollt ist, muss es noch über die Verwaltung eingefügt werden.`"
      >
        <fa-icon name="exclamation-triangle" class="ml-1 text-yellow-500" />
      </tooltip>
    </span>
    <code class="text-xs">{{ field.name }}</code>
  </div>
</template>

<script>
export default {
  props: {
    field: {},
    group: {},
  },
  computed: {
    missingInDetailView() {
      const fieldExists = collection => {
        const fields = collection.map(g => g.detailViewFields).flat()
        return !fields.length || fields.some(f => f.fieldName == `cf_${this.field.name}`)
      }

      switch (this.group.entity) {
        case "for_brokers":
          return false
        case "for_properties":
          return !fieldExists(this.$db.broker.propertyDetailViewGroups)
        case "for_projects":
          return !fieldExists(this.$db.shopData.projectDetailViewGroups)
        case "for_clients":
          return !fieldExists(this.$db.shopData.clientDetailViewGroups)
      }
    },
  },
}
</script>