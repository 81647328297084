<template>
  <span>
    <fa-icon
      v-if="(children && children.length) || sortable"
      name="chevron-right"
      class="transition-transform"
      :class="{ 'transform rotate-90': (search && item.isMatch) || (showNestedItems && !search) }"
    />
    <slot v-else name="icon" />
  </span>
</template>

<script>
export default {
  props: {
    children: {
      type: Array
    },
    search: {
      type: Number
    },
    showNestedItems: {
      type: Boolean
    },
    item: {
      type: Object
    },
    showNestedItems: {
      type: Boolean
    },
    sortable: {
      type: Boolean
    }
  }
}
</script>