<template>
  <div class="pages-editor">
    <textarea v-bind="$attrs" ref="input" :value="modelValue" @input="handleUpdate"></textarea>
    <take-over-link-button
      v-if="hasTakeOver && $attrs.placeholder"
      @update="setValue($attrs.placeholder)"
      class="mt-1"
    />
  </div>
</template>

<script>
import TakeOverLinkButton from "@/views/admin/components/TakeOverLinkButton.vue"
import { initDescriptionEditorFor, initHtmlEditorFor, monkeyPatchFroalaEditorHelpers } from "@/config/richtext-editor"

export default {
  emits: ["update:modelValue"],
  components: { TakeOverLinkButton },
  props: {
    modelValue: {
      type: String,
      // default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasTakeOver: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "description",
    },
  },
  expose: ["froalaEditor", "setValue"],
  data() {
    return {
      froalaEditor: undefined,
    }
  },
  methods: {
    handleUpdate(newValue) {
      if (newValue === (this.modelValue ?? "")) return
      this.$emit("update:modelValue", newValue)
    },
    setValue(newValue) {
      this.froalaEditor.html.set(newValue)
    },
    initEditor() {
      const initEditor = this.type == "description" ? initDescriptionEditorFor : initHtmlEditorFor
      const options =
        this.type == "description"
          ? {}
          : {
              pluginsEnabled: [
                "align",
                "codeView",
                "colors",
                "fontAwesome",
                "fontFamily",
                "fontSize",
                "image",
                "link",
                "lists",
                "url",
                "fullscreen",
                "table",
              ],
            }

      const that = this
      this.froalaEditor = initEditor(
        this,
        this.$refs.input,
        {
          ...options,
          model: this.modelValue,
          events: {
            contentChanged(e) {
              const newContent = this.html.get()
              that.handleUpdate(newContent)
            },
            blur(e) {
              this.selection.save()
              if (!this.codeView.isActive()) return
              this.html.set(this.codeView.get())
            },
          },
        },
        () => {
          if (this.readonly) this.froalaEditor.edit.off()
          monkeyPatchFroalaEditorHelpers(this.froalaEditor)
        }
      )
    },
  },
  mounted() {
    this.initEditor()
  },
}
</script>
