<template>
  <section>
    <div v-for="item in logs.data" :key="item.id" class="activityLog-item padding-0">
      <p>
        <strong>{{ item.broker ? item.broker.name : "System" }}</strong>
        {{ item.prettyText }}
      </p>
      <tooltip class="activityLog-date" :content="$customFilters.datetime(item.created_at)" placement="left">
        <span>{{ $customFilters.calendar(item.created_at) }}</span>
      </tooltip>
    </div>
  </section>
</template>

<script>
import { formatLog } from "../config/logs"

export default {
  props: ["resourceType", "resourceId", "brokers"],

  data() {
    return {
      logs: {
        data: [],
        loading: false,
      },
    }
  },

  watch: {
    resourceId() {
      this.fetchLogs()
    },
  },

  methods: {
    fetchLogs() {
      this.logs.loading = true
      this.$axios
        .get(`/api/v1/activity_logs?loggable_id=${this.resourceId}&loggable_type=${this.resourceType}`)
        .then(({ data }) => {
          this.logs.data = data.data.map(b => formatLog(b, this.brokers))
          this.logs.loading = false
        })
    },

    subscribeToLogs() {
      channel.bind(`log:${this.resourceType}:${this.resourceId}:created`, log => {
        this.logs.data = this.logs.data.concat([formatLog(log)])
      })
    },
  },

  mounted() {
    this.fetchLogs()
  },
}
</script>
