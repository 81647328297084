<template>
  <tooltip content="Hohe Priorität" v-if="priority == 'high' || priority == 1">
    <fa-icon name="exclamation-triangle" class="text-red-500 fa-fw" />
  </tooltip>
  <tooltip content="Niedrige Priorität" v-else-if="priority == 'low' || priority == -1">
    <fa-icon name="level-down" class="text-blue-600 fa-fw" />
  </tooltip>
</template>

<script>
export default {
  props: {
    priority: {},
  },
}
</script>