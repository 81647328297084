<template>
  <div 
    class="rounded border-black mb-8 bg-white break-words select-none"
    :class="{
      'flowchart-node__end': isEnd,
      'border-blue-500 shadow-md': selected, 
      'flowchart-node__regular pointer': !isEnd, 
      'border-solid border pt-1 px-2 pb-4': isTrigger || isAction || isCondition,
      'border-dashed border-2 p-4': isAdd || isEnd,
      'border-gray-400': (isAdd || isEnd) && !selected,
      'border-black': (isTrigger || isAction || isCondition) && !selected,
    }"
    @click="click"
  >
    <div v-if="isEnd">
      <fa-icon name="badge-check" class="fs-36 text-gray-600"/>
    </div>

    <div class="text-center" v-else-if="isAdd">
      <fa-icon 
        name="plus" 
        class="fs-18"
        :class="{
          'text-blue-500': selected, 
          'text-gray-500': !selected
        }"
      />
    </div>

    <div v-else>
      <div  v-if="isCondition" class="text-right text-sm">
        {{$t('automations.flowchart.condition')}}
      </div>
      <div  v-if="isAction" class="text-right text-sm">
        {{$t('automations.flowchart.action')}}
      </div>
      <div v-if="isTrigger" class="text-right text-sm">
        {{$t('automations.flowchart.trigger')}}
      </div>
      <div class="flex flex-row w-full justify-between flowchart-node__title pt-1">
        <div class="capitalize text-center">{{title}}</div>
        <div class="w-6">
          <fa-icon 
            name="pen" 
            class="fs-18 text-black"
            :class="{
              'text-black': !selected, 
              'text-gray-400': selected
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NODE_TYPE } from "@/config/automation-helpers"
import { getVariablesForModel } from "@/config/recipes"

export default {
  props: {
    recipes: {
      type: Array,
      required: true
    },
    recipe: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    },
    recipesConfig: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isTrigger() { return this.node.nodeType === NODE_TYPE.TRIGGER },
    isCondition() { return this.node.nodeType === NODE_TYPE.CONDITION },
    isAction() {return this.node.nodeType === NODE_TYPE.ACTION},
    isAdd() { return this.node.nodeType === NODE_TYPE.ADD_CONDITION || this.node.nodeType === NODE_TYPE.ADD_CONDITION },
    isEnd() { return this.node.nodeType === NODE_TYPE.END },
    triggerData() {
      return (this.recipesConfig.triggers || []).find((trigger) => trigger.name === this.recipe?.triggerType)
    },
    actionData() {
      return (this.recipesConfig.actions || []).find((action) => action.name === this.recipe?.actionType)
    },
    title() {
      if(this.isTrigger) return this.triggerData?.label
      if(this.isCondition) {
        if (this.node.conditions.length === 1) {
          return (this.variables || []).find((variable) => variable.id === this.node.conditions[0].key)?.name
        } else {
          return [String(this.node.conditions.length), this.$t("automations.conditions")].join(' ')
        }
      }
      if(this.isAction) return this.actionData?.label
    },
    variables() {
      let variables = []

      if (this.triggerData?.model) {
        variables = getVariablesForModel(this.$db, this.recipesConfig, this.triggerData?.model)
      } else {
        const previousRecipe = this.recipes.find(r => r.nextRecipeIds?.includes(this.recipe.id))
        const triggerModel = this.recipesConfig.triggers.find(a => a.name == previousRecipe?.triggerType)?.model
        if (triggerModel) {
          variables = variables.concat(
            getVariablesForModel(this.$db, this.recipesConfig, triggerModel, { prefix: "previous__" })
          )
        }

        const actionModel = this.recipesConfig.actions.find(a => a.name == previousRecipe?.actionType)?.model
        if (actionModel) {
          variables = variables.concat(getVariablesForModel(this.$db, this.recipesConfig, actionModel))
        }
      }

      return variables
    },
  },
  methods: {
    click(node) {
      if (this.isEnd) return
      this.$emit("select", this.node)
    }
  }
}
</script>

<style>
.flowchart-node__regular {
  width: 300px;
}

.border-solid.flowchart-node__regular::after{
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='1.5,1 1.5,60' style='fill:none;stroke:black;stroke-width:1'/%3E%3C/svg%3E ");
  position: absolute;
  left: 250px;
  transform: translateX(150px);
  z-index: -1;
}

.border-dashed.flowchart-node__regular::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='1.5,1 1.5,60' stroke-dasharray='5,2' style='fill:none;stroke: %239ca3af;stroke-width:2'/%3E%3C/svg%3E ");
  position: absolute;
  left: 250px;
  transform: translateX(150px);
  z-index: -1;
}
.flowchart-node__end {
  width: 80px;
  height: 80px;
}

.flowchart-node__title::before {
  content: ' ';
  display: block;
  width: 20px;
}
</style>