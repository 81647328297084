<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
  setup() {},
})
</script>
<script setup lang="ts">
const props = defineProps<{
  label: string
}>()
</script>
<template>
  <div class="d-flex justify-content-between">
    <div class="font-bold">{{ label }}</div>
    <div class="col-span-4">
      <slot />
    </div>
  </div>
</template>
