<template>
  <div class="searchFilter-item">
    <a href="#" @click.prevent="$emit('remove')" class="searchFilter-remove">
      <fa-icon name="times" />
    </a>
    <div class="searchFilter-column">
      <nice-select
        v-model="condition.key"
        @change="resetCondition"
        :options="variables"
        placeholder="Attribut"
        allow-create
      />
    </div>
    <div class="mr-2" style="width: 150px">
      <nice-select
        v-model="condition.match"
        placeholder="Operator"
        :options="operators"
        label-key="label"
        id-key="value"
      />
    </div>
    <div
      :class="{ invisible: condition.match == 'exists' || condition.match == 'missing' }"
      :style="isMultiSelectCondition ? 'width: 400px' : 'width: 250px'"
    >
      <template v-if="selectedVariable?.type == 'broker'">
        <db-select
          v-if="isMultiSelectCondition"
          key="brokers-multi"
          v-model="conditionValueMultiselectModel"
          multiple
          collection="unarchivedBrokers"
          allow-create
        />
        <db-select v-else key="brokers-single" v-model="condition.value" collection="unarchivedBrokers" allow-create />
      </template>

      <template v-else-if="selectedVariable?.type == 'project'">
        <project-select
          key="projects-multi"
          v-if="isMultiSelectCondition"
          v-model="conditionValueMultiselectModel"
          multiple
          allow-create
        />
        <project-select v-else key="projects-single" v-model="condition.value" allow-create />
      </template>

      <template v-else-if="selectedVariable?.dboptions">
        <db-select
          v-if="isMultiSelectCondition"
          :key="`${selectedVariable.dboptions}-multi`"
          :collection="selectedVariable.dboptions"
          multiple
          v-model="conditionValueMultiselectModel"
          allow-create
        />
        <db-select
          v-else
          :key="`${selectedVariable.dboptions}-single`"
          :collection="selectedVariable.dboptions"
          v-model="condition.value"
          allow-create
        />
      </template>

      <template v-else-if="selectedVariable?.plabeloptions">
        <nice-select
          v-if="isMultiSelectCondition"
          :key="`${selectedVariable.name}-plabeloptions`"
          :options="$db.shopData.propertyOptionsMapping[selectedVariable.plabeloptions]"
          multiple
          v-model="conditionValueMultiselectModel"
          allow-create
        />
        <nice-select v-else key="plabeloptions-single"
          :options="$db.shopData.propertyOptionsMapping[selectedVariable.plabeloptions]"
          v-model="condition.value"
          allow-create
        />
      </template>

      <template v-else-if="selectedVariable?.grouped">
        <nice-select
          v-if="isMultiSelectCondition"
          :key="`${selectedVariable.name}-grouped-multi`"
          multiple
          v-model="conditionValueMultiselectModel"
          :options="actionOptionsData?.[selectedVariable.grouped]"
          allow-create
          :children-key="selectedVariable.options"
          grouped
        />
        <nice-select
          v-else
          :key="`${selectedVariable.name}-grouped-single`"
          v-model="condition.value"
          :options="actionOptionsData?.[selectedVariable.grouped]"
          :children-key="selectedVariable.options"
          grouped
          allow-create
        />
      </template>
      <template v-else-if="selectedVariable?.options">
        <nice-select
          :key="`${selectedVariable.name}-options-multi`"
          v-if="isMultiSelectCondition"
          multiple
          v-model="conditionValueMultiselectModel"
          :options="selectedVariable.options"
          allow-create
        />
        <nice-select
          :key="`${selectedVariable.name}-options-single`"
          v-else
          v-model="condition.value"
          :options="selectedVariable.options"
          allow-create
        />
      </template>
      <nice-input size="small" v-else :type="selectedVariable?.type === 'number' ? 'number' : 'text'" v-model="condition.value" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  OPERATORS,
  getVariablesForModel,
  BOOLEAN_OPERATORS,
  MULTI_SELECT_CONDITIONS,
  transformConditionToSelectModel,
  transformSelectModelToConditionString,
  RecipeOptionVariable,
} from "@/config/recipes"
import { Recipe, RecipeCondition } from "@/interfaces"
import useCore from "@/plugins/use-core"
import { computed } from "vue"

const props = defineProps<{
  condition: Partial<RecipeCondition> // will be built up here
  actionOptionsData?: Record<string, any>
  recipes: Recipe[]
  recipe: Recipe
  recipesConfig: any
}>()

const { db } = useCore()

const isMultiSelectCondition = computed(
  () => props.condition?.match && MULTI_SELECT_CONDITIONS.includes(props.condition.match)
)

// wrapper around the condition value model that handles transforming of multi-selects between a comma seperated string and a list for the selects
const conditionValueMultiselectModel = computed({
  get: () => {
    return transformConditionToSelectModel(props.condition.value)
  },
  set: val => {
    props.condition.value = transformSelectModelToConditionString(val)
  },
})

const resetCondition = () => {
  props.condition.match = undefined
  props.condition.value = undefined
}

const selectedTrigger = computed(() => {
  if (!props.recipe.triggerType) return null
  return props.recipesConfig.triggers.find(a => a.name == props.recipe.triggerType)
})

const triggerModel = computed(() => selectedTrigger?.value?.model)

const variables = computed<RecipeOptionVariable[]>(() => {
  if (triggerModel.value) {
    return getVariablesForModel(db, props.recipesConfig, triggerModel.value).filter(v => v.label)
  } else {
    let variables = [] as RecipeOptionVariable[]
    const previousRecipe = props.recipes.find(r => r.nextRecipeIds?.includes(props.recipe.id))
    const triggerModel = props.recipesConfig.triggers.find(a => a.name == previousRecipe?.triggerType)?.model
    if (triggerModel) {
      variables = variables.concat(
        getVariablesForModel(db, props.recipesConfig, triggerModel, { prefix: "previous__" })
      )
    }

    const actionModel = props.recipesConfig.actions.find(a => a.name == previousRecipe?.actionType)?.model
    if (actionModel) {
      variables = variables.concat(getVariablesForModel(db, props.recipesConfig, actionModel))
    }

    return variables.filter(v => v.label)
  }
})

const selectedVariable = computed(() => variables.value.find(o => o.id == props.condition.key))

const operators = computed(() => {
  if (selectedVariable.value?.type == "boolean") return BOOLEAN_OPERATORS

  return OPERATORS
})
</script>
