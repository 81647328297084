<template>
  <tooltip
    v-if="!interactive || resource.conversation_type != 'reminder'"
    :disabled="
      !(
        resource.state == 'took_place' ||
        resource.state == 'cancelled' ||
        (resource.conversation_type == 'message' && resource.done)
      )
    "
  >
    <fa-icon
      :name="iconClass"
      class="text-gray-600 text-lg"
      :class="{
        'text-green-600': resource.state == 'took_place' || resource.done,
        'text-red-600': resource.state == 'cancelled',
      }"
    />
    <template v-slot:content>
      <span v-if="resource.state == 'took_place'">{{ $t("activities.icon.tookPlace") }}</span>
      <span v-if="resource.state == 'cancelled'">{{ $t("activities.icon.cancelled") }}</span>
      <span v-if="resource.conversation_type == 'message' && resource.done">{{ $t("activities.icon.archived") }}</span>
    </template>
  </tooltip>

  <todo-checkbox v-else :resource="resource" :id="resource.activatable_id" />
</template>

<script>
import TodoCheckbox from "@/components/task/TodoCheckbox"

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
  },
  components: { TodoCheckbox },
  computed: {
    iconClass() {
      if (this.resource.source_id) {
        if (this.resource.done) {
          return "archive"
        }
        return "question-circle"
      } else if (this.resource.conversation_type === "message") {
        if (this.resource.done) {
          return "archive"
        }
        return this.resource.outgoing ? "paper-plane" : "envelope"
      } else if (this.resource.reason_id) {
        return "thumbs-down"
      } else if (this.resource.conversation_type === "sms") {
        return "comment"
      } else if (this.resource.conversation_type === "call") {
        return "phone"
      } else if (this.resource.conversation_type === "policy") {
        return "info-circle"
      } else if (this.resource.conversation_type === "decision") {
        return "project-diagram"
      } else if (this.resource.conversation_type === "publishing") {
        return "file-export"
      } else {
        if (this.resource.conversation_type === "event") {
          if (this.resource.state == "took_place") {
            return "calendar-check"
          } else if (this.resource.state == "cancelled") {
            return "calendar-times"
          } else {
            return "calendar-alt"
          }
        } else if (this.resource.conversation_type === "reminder") {
          return "check"
        } else {
          return "sticky-note"
        }
      }
    },
  },
}
</script>

<style scoped>
.task-check--checked label {
  border: none;
}
.task-check--checked label::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  text-align: center;
}
</style>
