<template>
  <section class="flex">
    <div
      v-for="(item, idx) in bubbles"
      :key="idx"
      class="flex items-center"
      :class="
        item.active
          ? 'text-green-500'
          : !bubbles[idx - 1] || bubbles[idx - 1].active
          ? 'text-blue-600'
          : 'text-gray-400'
      "
      :style="`width: ${bubbles.length !== idx + 1 ? 'inherit' : 'auto'}`"
    >
      <div
        class="step-bubble border rounded-full relative"
        :class="
          item.active
            ? 'border-green-500'
            : !bubbles[idx - 1] || bubbles[idx - 1].active
            ? 'border-blue-600'
            : 'border-gray-400'
        "
      >
        <fa-icon v-if="item.active" name="check" />
        <span v-else>{{ idx + 1 }}</span>
        <span class="bubble-label absolute">{{ item.label }}</span>
      </div>

      <div
        v-if="bubbles.length !== idx + 1"
        class="border border-gray-400"
        :class="{ 'border-green-500': item.active }"
        style="width: 100%; height: 0; border-width: 0.5px"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      bubbles: this.items,
    }
  },
}
</script>

<style scoped>
.step-bubble {
  min-width: 20px;
  height: 20px;
  padding: 0px !important;
  text-align: center;
  font-size: 12px;
}

.bubble-label {
  top: 24px;
  left: -64px;
  width: 150px;
}
</style>
