import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import { useMembershipsQuery } from "./queries/use-memberships-query"
import { useI18n } from "vue-i18n"

// use in combination with useIntegrationAPI
export const useMembership = (membershipId: MaybeRef<string>) => {
  const { t } = useI18n()

  const { data, isPending } = useMembershipsQuery()

  // HINT: this ensures type safety, but not necessarily the api response. we should improve that.
  const membership = computed(() => (get(data) ?? {})[get(membershipId)])

  const badgeColor = computed(() => {
    const _membership = get(membership)
    switch (_membership) {
      case "GOLD":
        return "#FCF4D6"
      case "SILVER":
        return "#F1F1F1"
      case "BRONZE":
        return "#F5E7DD"
    }
  })

  const translatedMembershipName = computed(() => {
    const _membership = get(membership)
    if (!_membership || _membership === "UNKNOWN") return
    return t(`integrations.is24-memberships.status.${_membership}`)
  })

  return {
    membership,
    isPending,
    badgeColor,
    translatedMembershipName,
  }
}
