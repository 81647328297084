import { useMutation, useQueryClient } from "@tanstack/vue-query"
import update from "immutability-helper"

import { QueryKeys } from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { Integration, IntegrationDynamicConfiguration, IntegrationId } from "../types/integration-service"
import { QueryApiError } from "./query-api-error"

async function updateIntegrationConfiguration(
  integrationId: IntegrationId,
  configuration: IntegrationDynamicConfiguration
) {
  const response = await ServiceProvider.integrations.updateConfiguration(integrationId, configuration)
  if (response.isSuccessful2xx) {
    return response.data as IntegrationDynamicConfiguration
  } else {
    throw new QueryApiError(response)
  }
}

export const useIntegrationUpdateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, configuration }: { id: IntegrationId; configuration: IntegrationDynamicConfiguration }) =>
      updateIntegrationConfiguration(id, configuration),
    // onMutate: ({ id, configuration }) => {
    //   const integrations = (queryClient.getQueryData(QueryKeys.integrations.all()) ?? {}) as Record<string, Integration>

    //   const integration = integrations[id]

    //   if (!integration) {
    //     throw new Error(`integration ${id} not found`)
    //   }

    //   const updatedIntegration: Integration = { ...integration, configuration }
    //   const newIntegrations = update(integrations, { $merge: { [id]: updatedIntegration } })

    //   queryClient.setQueryData(QueryKeys.integrations.all(), newIntegrations)
    //   return { updatedIntegration, oldIntegration: integration }
    // },
    // onError: (_, { id }, context) => {
    //   if (context?.oldIntegration) {
    //     const integrations = queryClient.getQueryData(QueryKeys.integrations.all()) as Record<string, Integration>
    //     queryClient.setQueryData(
    //       QueryKeys.integrations.all(),
    //       update(integrations, { $merge: { [id]: context.oldIntegration } })
    //     )
    //   } else {
    //     queryClient.invalidateQueries({ queryKey: QueryKeys.integrations.all() })
    //   }
    // },
    onSuccess: (_, { id, configuration }) => {
      const integrations = queryClient.getQueryData(QueryKeys.integrations.all()) as Record<IntegrationId, Integration>
      const integration = integrations[id]

      if (!integration) {
        queryClient.invalidateQueries({ queryKey: QueryKeys.integrations.all() })
        return
      }

      const updatedIntegration: Integration = { ...integration, configuration }
      const newIntegrations = update(integrations, { $merge: { [id]: updatedIntegration } })

      queryClient.setQueryData(QueryKeys.integrations.all(), newIntegrations)
    },
  })
}
