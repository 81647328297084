// Source: https://jsfiddle.net/scheja/r5nvmgye/

function outgoing(flow, node) {
  return flow.edges.filter(e => e.source_id === node.id)
}

// helper to get successors of a given node
function successors(flow, node) {
  return outgoing(flow, node).map(e => flow.nodes.find(n => n.id === e.destination_id))
}

// breadth first search, starting at a given node
function bfs(flow, node) {
  const visited = []
  const queue = []
  visited.push(node)
  queue.push(node)
  while (queue.length !== 0) {
    const visiting = queue.shift()
    successors(flow, visiting).forEach(successor => {
      visited.push(successor)
      queue.push(successor)
    })
  }
  return visited
}

// traverse the nodes with bfs, set an ordinal number for each visited node and order by those ordinals
function orderNodes(flow) {
  const startNode = flow.nodes.find(node => node.type === "ParticipationFlow::StartNode")

  bfs(flow, startNode).forEach((node, index) => {
    node.order = index
  })
  flow.nodes.sort((a, b) => a.order - b.order)
}

export default flow => {
  orderNodes(flow)

  // use the ordered partcipants
  const participants = flow.nodes
    .filter(n => n.type === "ParticipationFlow::ParticipantNode")
    .map(n => ({
      order: n.order,
      slotName: n.participant.slot_name,
      name: n.participant.name,
      email: n.participant.email,
      role: n.participant.role,
      color: n.participant.color,
    }))

  return participants
}
