<template>
  <div style="width: 210px" class="d-flex align-items-center">
    <nice-progress
      :percentage="(completedTasks / allTasks) * 100"
      :show-text="false"
      style="flex: 1"
      :status="allCompleted ? 'success' : null"
    />
    <div class="ml-3 fs-13">
      <span>{{ completedTasks }}</span>
      <span>/</span>
      <span>{{ allTasks }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subTasks: {
      type: Array,
      required: true,
    },
    resourceId: {
      required: true,
    },
  },
  computed: {
    completedTasks() {
      return this.subTasks.filter(t => t.done_at || t.done).length
    },
    allTasks() {
      return this.subTasks.length
    },
    allCompleted() {
      return this.completedTasks === this.allTasks
    },
  },

  mounted() {
    this.$pusher.on(`todo:${this.resourceId}:updated`, todo => {
      this.$emit("update:subTasks", this.subTasks.filter(t => t.title != todo.title).concat([todo]))
    })
  },
}
</script>