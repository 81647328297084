<template>
  <div class="messageItem-actions">
    <template v-if="!message.isDraft && !message.deletedAt && !message.send_at">
      <button
        v-if="$db.broker.availableInboxes.map(i => i.id).includes(message.broker_id)"
        class="messageItem-button"
        v-show="!message.archived"
        :title="$t('inbox.actions.archive')"
        @click.stop.prevent="clicked('archive')"
      >
        <fa-icon name="archive" />
      </button>
      <button
        v-if="$db.broker.availableInboxes.map(i => i.id).includes(message.broker_id)"
        class="messageItem-button"
        v-show="message.archived"
        :title="$t('inbox.actions.unarchive')"
        @click.stop.prevent="clicked('unarchive')"
      >
        <fa-icon name="inbox" />
      </button>
      <button
        class="messageItem-button"
        v-show="!message.read"
        :title="$t('inbox.actions.markAsRead')"
        @click.stop.prevent="clicked('mark_as_read')"
      >
        <fa-icon name="envelope-open" />
      </button>
      <button
        class="messageItem-button"
        v-show="message.read"
        :title="$t('inbox.actions.markAsUnread')"
        @click.stop.prevent="clicked('mark_as_unread')"
      >
        <fa-icon name="envelope" />
      </button>
      <button
        class="messageItem-button"
        v-show="!message.starred"
        :title="$t('inbox.actions.favorite')"
        @click.stop.prevent="clicked('star')"
      >
        <fa-icon name="star" />
      </button>
      <button
        class="messageItem-button"
        v-show="message.starred"
        :title="$t('inbox.actions.unfavorite')"
        @click.stop.prevent="clicked('unstar')"
      >
        <fa-icon name="star" style="color: gold" />
      </button>
      <!-- <button class="messageItem-button" v-show="message.spammed" title="Als kein Spam markieren" @click.stop="clicked('unspam')">
        <fa-icon name="exclamation-triangle" />
      </button>-->
    </template>
    <button
      v-if="$db.broker.availableInboxes.map(i => i.id).includes(message.broker_id)"
      class="messageItem-button"
      :title="$t('inbox.actions.delete')"
      @click.stop.prevent="clicked('delete')"
      :class="{
        disabled: message.client_ids && message.client_ids.length && !$db.broker.canDeleteLinkedMessages,
      }"
    >
      <fa-icon name="trash-alt" />
    </button>
    <button
      v-if="message.deletedAt && $db.broker.availableInboxes.map(i => i.id).includes(message.broker_id)"
      class="messageItem-button"
      :title="$t('inbox.actions.restore')"
      @click.stop.prevent="clicked('restore')"
    >
      <fa-icon name="undo" />
    </button>
    <button class="messageItem-button" :title="$t('inbox.actions.openInNewTab')" @click.stop.prevent="openInNewTab">
      <fa-icon name="external-link" />
    </button>
  </div>
</template>

<script>
export default {
  props: ["message"],

  methods: {
    openInNewTab() {
      window.open(`/app/message/${this.message.id}`, "_blank")
    },
    clicked(action) {
      this.$emit("trigger-action", action)
      // this.$store.dispatch(`inbox/updateMessages`, {
      //   action,
      //   messages: [this.message.id]
      // });
    },
  },
}
</script>

<style scoped>
.messageItem-actions {
  position: absolute;
  right: 0;
  top: -4px;
  display: flex;
  background: #f3f3f3;
}

.messageItem-button {
  background: none;
  padding: 0;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: 1px solid #ccc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  margin-left: 3px;
}

.messageItem-button:hover {
  background: #e9e9e9;
}
</style>
