<template>
  <div class="historyItem">
    <aside class="historyItem-status">
      <fa-icon v-if="event.error" name="times" class="text-danger" />
      <fa-icon v-else name="check" class="text-success" />
    </aside>
    <div style="flex: 1">
      <p class="historyItem-title">
        <span>
          {{ $customFilters.date(event.createdAt) }}
          ({{ event.id }})
        </span>
        <span>
          {{ $customFilters.time(event.createdAt) }}
          <tooltip :content="$t('automations.retrigger')">
            <a class="text-gray-500" style="font-size: 12px" @click="retriggerFailedEvent">
              <fa-icon name="redo" />
            </a>
          </tooltip>
        </span>
      </p>
      <div style="flex: 1">
        <a v-if="event.triggerResourceId && event.triggerResourceType" @click.prevent="open('trigger')">
          Trigger: {{ event.triggerResourceType }} ({{ event.triggerResourceId }})
        </a>
        <a v-if="event.actionResourceId && event.actionResourceType" @click.prevent="open('action')">
          Action: {{ event.actionResourceType }} ({{ event.actionResourceId }})
        </a>
      </div>
      <p v-if="event.error" class="text-danger mt-1">{{ event.error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    retriggerFailedEvent() {
      this.$api
        .mutation("retriggerRecipe", { recipeEventId: this.event.id })
        .then(() => {
          App.flashy(this.$t("automations.retriggerSuccess"))
          this.$emit("refresh")
        })
        .catch(this.$axios.handleError)
    },
    open(target) {
      const type = target === "trigger" ? this.event.triggerResourceType : this.event.actionResourceType
      const id = target === "trigger" ? this.event.triggerResourceId : this.event.actionResourceId

      if (!type || !id) return

      const normalizedType = type.toLowerCase()
      const newRouteState = {
        client: `/contacts/clients/${id}`,
        property: `/portfolio/properties/${id}`,
        clientproperty: `/opportunities/${id}`,
        message: { query: { ...this.$route.query, message: id } },
        note: { query: { ...this.$route.query, note: id } },
        todo: { query: { ...this.$route.query, todo: id } },
        event: { query: { ...this.$route.query, event: id } },
        decision: { query: { ...this.$route.query, decision: id } },
        scoutinquiry: { query: { ...this.$route.query, note: id } },
      }[normalizedType]

      if (!newRouteState) return

      this.$emit("close")
      this.$router.push(newRouteState)
    },
  },
}
</script>

<style>
.historyItem {
  padding: 0.65rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}

.historyItem-status {
  width: 30px;
  margin-right: 0.5rem;
  text-align: center;
}

.historyItem-title {
  display: flex;
  justify-content: space-between;
  font-family: "Courier New", Courier, monospace;
}
</style>
