<script setup lang="ts">
import useCore from "@/plugins/use-core"
import { ref, computed } from "vue"

const { db: { shopData, broker} } = useCore()

const src = computed(() => {
  const team = shopData.teams.find((team) => team.id === broker.teamId)
  return team?.logoUrl ?? shopData.logoUrl
})
const alt = ref("company Logo")
</script>

<template>
  <div class="w-full aspect-video p-6" v-if="src">
    <img :src="src" :alt="alt" class="h-full w-full object-contain object-center" />
  </div>
</template>
